import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Main from "./components/Main";
import Signup from "./components/Singup";
import Login from "./components/Login";
import Footer from "./components/Crud/Footer";
import Header from "./components/Crud/Header";
import Menu from "./components/Crud/Menu";
import CreditosRechazados from "./components/Crud/CreditosRechazados";
import CreditosFinalizados from "./components/Crud/CreditosFinalizados";
import VistaGeneral from "./components/Crud/VistaGeneral";
import CambioEstado from "./components/Crud/CambioEstado";
import EstadoRechazado from "./components/Crud/EstadoRechazado";
import PendienteDesembolso from "./components/Crud/PendienteDesembolso";
import CreateUserComponent from "./components/Crud/CreateUserComponent";
import Liquidacion from "./components/Crud/Liquidacion";
import Tarifas from "./components/Crud/Tarifas";
import PagosEmpresas from "./components/Crud/PagosEmpresas";
import ControlPagos from "./components/Crud/ControlPagos";
import ViewUserComponent from "./components/Crud/ViewUserComponent";
import UpdateUserComponent from "./components/Crud/UpdateUserComponent";
import UpdateUserComponent2 from "./components/Crud/UpdateUserComponent2";
import Indicadores from "./components/Crud/Indicadores";

export default class App extends Component {
  render() {
    const user = localStorage.getItem("token");
    return (
      <div>
        <Router>
          <Routes>
            {/* Ruta para el componente VistaGeneral */}
            <Route path="/" element={user ? <VistaGeneral /> : <Navigate replace to="/login" />} />

            {/* Rutas para los demás componentes */}
            {user && (
              <>
                <Route path="/CreditosDesembolsados" element={<ControlPagos />} />
                <Route path="/VistaGeneral" element={<VistaGeneral />} />
                <Route path="/CreditosRechazados" element={<CreditosRechazados />} />
                <Route path="/CreditosFinalizados" element={<CreditosFinalizados />} />
                <Route path="/PendienteDesembolso" element={<PendienteDesembolso />} />
                <Route path="/Liquidacion" element={<Liquidacion />} />
                <Route path="/Tarifas" element={<Tarifas />} />
                <Route path="/PagosEmpresas" element={<PagosEmpresas />} />
                <Route path="/Indicadores" element={<Indicadores />} />
                <Route path="/add-user/:numero_documento" element={<CreateUserComponent />} />
                <Route path="/view-user/:numero_documento" element={<ViewUserComponent />} />
                <Route path="/update-user/:numero_documento" element={<UpdateUserComponent />} />
                <Route path="/update-user1/:numero_documento" element={<UpdateUserComponent2 />} />
              </>
            )}

            {/* Rutas para Signup y Login */}
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/CambioEstado" element={<CambioEstado />} />
            <Route path="/EstadoRechazado" element={<EstadoRechazado />} />
          </Routes>
        </Router>
      </div>
    );
  }
}
