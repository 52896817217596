import React, { useState, useEffect } from "react";
import Header from "./Header";
import UserService from "../../services/UserService";
import Menu from "./Menu";
import "../../css/Tarifas.css";

const Tarifas = () => {
  const [data, setData] = useState([]);
  const [tasas, setTasas] = useState({ tasaEM: "", tasaED: "" });
  const [reload, setReload] = useState(0);
  const [tarifas, setTarifa] = useState([]);

  const [inputValues, setInputValues] = useState({
    input1: "",
    input2: "",
    input3: "",
    input4: "",
    input5: "",
    input6: "",
    input7: "",
  });

  useEffect(() => {
    UserService.getTarifas().then((res) => {
      setData(res.data[res.data.length - 1]);
      setTarifa(res.data);
    });
  }, [reload]);

  const crearDatosParaExcel = () => {
    let datosParaExcel = [
      [
        "Fecha Cambio",
        "Tarifa Efectiva Anual",
        "Tarifa Efectiva Mensual",
        "Tarifa Efectiva Diaria",
        "tarifa Administrativa cuota 1",
        "tarifa Administrativa cuota 2",
        "tarifa Administrativa cuota 3",
        "tarifa Consulta Perfil",
      ],
    ];
    for (let i = 0; i < tarifas.length; i++) {
      let fila = [
        tarifas[i].fecha_creacion,
        tarifas[i].tarifa_efectiva_anual,
        tarifas[i].tarifa_efectiva_mensual,
        tarifas[i].tarifa_efectiva_diaria,
        tarifas[i].tarifa_administrativa1,
        tarifas[i].tarifa_administrativa2,
        tarifas[i].tarifa_administrativa3,
        tarifas[i].validacion_perfil,
      ];
      datosParaExcel.push(fila);
    }
    return datosParaExcel;
  };

  const reportExcel = () => {
    let XLSX = require("xlsx");

    let datosParaExcel = crearDatosParaExcel();
    let hojaDeCalculo = XLSX.utils.aoa_to_sheet(datosParaExcel);
    let libroDeExcel = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libroDeExcel, hojaDeCalculo, "Datos");
    XLSX.writeFile(libroDeExcel, "historialTasasTarifas.xlsx");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9]/g, ""); // Filtra solo los números
    const formattedValue = formatNumber(filteredValue);
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]:
        formattedValue.length === 0 ? formattedValue : "$" + formattedValue,
    }));
  };

  const handleTasaChange = (event) => {
    const { name, value } = event.target;
    const filteredValue = value.replace(/[^0-9]/g, "");
    let tasaEA = filteredValue / 100;
    let tasaEM = String((Math.pow(1 + tasaEA, 1 / 12) - 1) * 100).substring(
      0,
      4
    );
    let tasaED = String((Math.pow(1 + tasaEA, 1 / 360) - 1) * 100).substring(
      0,
      5
    );
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: filteredValue.length === 0 ? filteredValue : filteredValue,
    }));
    if (tasaED !== "0" && tasaEM !== "0") {
      setTasas({ tasaEM: tasaEM + "%", tasaED: tasaED + "%" });
    } else {
      setTasas({
        tasaEM: data.tarifa_efectiva_mensual + "%",
        tasaED: data.tarifa_efectiva_diaria + "%",
      });
    }
  };

  const formatNumber = (value) => {
    const numberParts = value.replace(/[^0-9]/g, "").split("");
    const formattedParts = [];

    while (numberParts.length > 3) {
      formattedParts.unshift(numberParts.splice(-3).join(""));
    }

    if (numberParts.length) {
      formattedParts.unshift(numberParts.join(""));
    }

    return formattedParts.join(",");
  };

  const updateTarifas = (e) => {
    e.preventDefault();
    let date = new Date();
    let fecha_creacion =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    let tasa_a = !document.getElementById("tarifa_efectiva_anual").value
      ? data.tarifa_efectiva_anual
      : document.getElementById("tarifa_efectiva_anual").value;
    let tasa_m = !document.getElementById("tarifa_efectiva_mensual").value
      ? data.tarifa_efectiva_mensual
      : document.getElementById("tarifa_efectiva_mensual").value;
    let tasa_d = !document.getElementById("tarifa_efectiva_diaria").value
      ? data.tarifa_efectiva_diaria
      : document.getElementById("tarifa_efectiva_diaria").value;
    let tarifa_a1 = !document.getElementById("tarifa_administrativa1").value
      ? data.tarifa_administrativa1
      : document.getElementById("tarifa_administrativa1").value;
    let tarifa_a2 = !document.getElementById("tarifa_administrativa2").value
      ? data.tarifa_administrativa2
      : document.getElementById("tarifa_administrativa2").value;
    let tarifa_a3 = !document.getElementById("tarifa_administrativa3").value
      ? data.tarifa_administrativa3
      : document.getElementById("tarifa_administrativa3").value;
    let tarifa_cp = !document.getElementById("validacion_perfil").value
      ? data.validacion_perfil
      : document.getElementById("validacion_perfil").value;

    tarifa_a1 =
      typeof tarifa_a1 !== "number"
        ? parseInt(tarifa_a1.replace("$", "").split(",").join(""))
        : tarifa_a1;
    tarifa_a2 =
      typeof tarifa_a2 !== "number"
        ? parseInt(tarifa_a2.replace("$", "").split(",").join(""))
        : tarifa_a2;
    tarifa_a3 =
      typeof tarifa_a3 !== "number"
        ? parseInt(tarifa_a3.replace("$", "").split(",").join(""))
        : tarifa_a3;
    tarifa_cp =
      typeof tarifa_cp !== "number"
        ? parseInt(tarifa_cp.replace("$", "").split(",").join(""))
        : tarifa_cp;

    tasa_m =
      typeof tasa_m !== "number"
        ? parseFloat(tasa_m.replace("%", "").split(",").join(""))
        : tasa_m;

    tasa_d =
      typeof tasa_d !== "number"
        ? parseFloat(tasa_d.replace("%", "").split(",").join(""))
        : tasa_d;

    tasa_a =
      typeof tasa_a !== "number"
        ? parseFloat(tasa_a.replace("%", "").split(",").join(""))
        : tasa_a;

    let info = {
      tarifa_efectiva_anual: tasa_a,
      tarifa_efectiva_mensual: tasa_m,
      tarifa_efectiva_diaria: tasa_d,
      tarifa_administrativa1: tarifa_a1,
      tarifa_administrativa2: tarifa_a2,
      tarifa_administrativa3: tarifa_a3,
      validacion_perfil: tarifa_cp,
      fecha_creacion: fecha_creacion,
    };

    UserService.addTarifas(info).then((res) => {
      setReload(reload + 1);
      setInputValues({
        input1: "",
        input2: "",
        input3: "",
        input4: "",
        input5: "",
        input6: "",
        input7: "",
      });
      setTasas({ tasaEM: "", tasaED: "" });
    });
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <br />
        <div className="row titulogeneral">
          <h2 className="text-center3">Tasas y Tarifas</h2>
          <br />
          <br />
        </div>

        <div className="row d-flex justify-content-between ml-5 mr-5">
          <div className="cardTarifa">
            <div>
              <h6>Tasas de interes</h6>
              <p>Tasa efectiva anual: {data.tarifa_efectiva_anual}%</p>
              <p>Tasa efectiva mensual: {data.tarifa_efectiva_mensual}%</p>
              <p>Tasa efectiva diaria: {data.tarifa_efectiva_diaria}%</p>
            </div>
          </div>
          <div className="cardTarifa">
            <div>
              <h6>Tarifa Administrativa</h6>
              <p>
                1 Cuota:{" "}
                {`$` +
                  new Intl.NumberFormat("es-MX").format(
                    data.tarifa_administrativa1
                  )}
              </p>
              <p>
                2 Cuotas:{" "}
                {`$` +
                  new Intl.NumberFormat("es-MX").format(
                    data.tarifa_administrativa2
                  )}
              </p>
              <p>
                3 Cuotas:{" "}
                {`$` +
                  new Intl.NumberFormat("es-MX").format(
                    data.tarifa_administrativa3
                  )}
              </p>
            </div>
          </div>
          <div className="cardTarifa">
            <div>
              <h6>Consulta de perfil</h6>
              <p>
                {" "}
                {`$` +
                  new Intl.NumberFormat("es-MX").format(data.validacion_perfil)}
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <button
            className="btn btn-success esp"
            data-toggle="modal"
            data-target="#editarTarifas"
          >
            {" "}
            Editar{" "}
          </button>
          <button
            className="btn btn-danger"
            onClick={(e) => reportExcel(e)}
          >
            Historial
          </button>
        </div>
      </div>

      <div
        className="modal fade"
        id="editarTarifas"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title1" id="exampleModalLabel">
                Editar Tasas y Tarifas
              </h5>
            </div>
            <div className="modal-body">
              {
                <form
                  className="d-inline-block mr-3 form-width"
                  id="formTasas1"
                >
                  <div className="form-group">
                    <label>Tasa efectiva anual</label>
                    <input
                      id="tarifa_efectiva_anual"
                      className="form-control"
                      type="text"
                      name="input5"
                      value={inputValues.input5}
                      placeholder={data.tarifa_efectiva_anual + "%"}
                      onChange={handleTasaChange}
                    />
                    <label>Tasa efectiva mensual</label>
                    <input
                      id="tarifa_efectiva_mensual"
                      className="form-control"
                      type="text"
                      value={tasas.tasaEM}
                      disabled
                      placeholder={data.tarifa_efectiva_mensual + "%"}
                    />
                    <label>Tasa efectiva diaria</label>
                    <input
                      id="tarifa_efectiva_diaria"
                      className="form-control"
                      type="text"
                      value={tasas.tasaED}
                      disabled
                      placeholder={data.tarifa_efectiva_diaria + "%"}
                    />
                  </div>
                </form>
              }

              {
                <form className="d-inline-block form-width" id="formTasas2">
                  <div className="form-group">
                    <label>Tarifa Administrativa 1 cuota </label>
                    <input
                      id="tarifa_administrativa1"
                      className="form-control"
                      type="text"
                      name="input1"
                      value={inputValues.input1}
                      onChange={handleInputChange}
                      placeholder={
                        `$` +
                        new Intl.NumberFormat("es-MX").format(
                          data.tarifa_administrativa1
                        )
                      }
                    />
                    <label>Tarifa Administrativa 2 cuotas</label>
                    <input
                      id="tarifa_administrativa2"
                      className="form-control"
                      type="text"
                      name="input2"
                      value={inputValues.input2}
                      onChange={handleInputChange}
                      placeholder={
                        `$` +
                        new Intl.NumberFormat("es-MX").format(
                          data.tarifa_administrativa2
                        )
                      }
                    />
                    <label>Tarifa Administrativa 3 cuotas</label>
                    <input
                      id="tarifa_administrativa3"
                      className="form-control"
                      type="text"
                      name="input3"
                      value={inputValues.input3}
                      onChange={handleInputChange}
                      placeholder={
                        `$` +
                        new Intl.NumberFormat("es-MX").format(
                          data.tarifa_administrativa3
                        )
                      }
                    />
                  </div>
                </form>
              }

              {
                <form
                  className="d-flex row justify-content-center"
                  id="formTasas3"
                >
                  <div className="form-group d-flex row justify-content-center">
                    <label>Consulta de perfil</label>
                    <input
                      id="validacion_perfil"
                      className="form-control"
                      type="text"
                      name="input4"
                      value={inputValues.input4}
                      onChange={handleInputChange}
                      placeholder={
                        `$` +
                        new Intl.NumberFormat("es-MX").format(
                          data.validacion_perfil
                        )
                      }
                    />
                  </div>
                </form>
              }
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#confirmacionTarifas"
              >
                Guardar
              </button>
              <button
                className="btn btn-danger"
                data-dismiss="modal"
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="confirmacionTarifas"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title1" id="exampleModalLabel">
                Confirmar cambios
              </h5>
            </div>
            <div className="modal-body" style={{ marginLeft: "50px" }}>
              ¿Estas seguro de confirmar cambios realizados?
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-success"
                onClick={(e) => {
                  updateTarifas(e);
                }}
                data-dismiss="modal"
              >
                Sí, guardar
              </button>
              <button
                className="btn btn-danger"
                data-dismiss="modal"
                data-toggle="modal"
                data-target="#editadesembolso"
                style={{ marginLeft: "10px" }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tarifas;
