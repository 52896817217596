//CREDITOS RECHAZADOS
import React, { Component } from 'react'
import UserService from '../../services/UserService';
import { BsPencilSquare } from "react-icons/bs";
import Header from './Header'
import Footer from './Footer'
import {BsFillCaretLeftFill} from "react-icons/bs";
import {BsFillCaretRightFill} from "react-icons/bs";
import {BsFillSkipBackwardFill} from "react-icons/bs";
import {BsFillSkipForwardFill} from "react-icons/bs";
import Menu from './Menu'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import '../../css/ListUserComponent.css'


class CreditosRechazados extends Component {
    constructor(props) {
      super(props)

      this.state = {
        clientesTabla: [],
        Clientes: [],
        paginacion: -1,
        pageNow:1,
        filtroDocumento: '',
        filtroEmpresa:'',
              
      }
      this.addUser = this.addUser.bind(this);
      this.editUser = this.editUser.bind(this);
      this.deleteUser = this.deleteUser.bind(this);
      
    }

    deleteUser(numero_documento){
      UserService.deleteUser(numero_documento).then((res) => {
        this.setState(res.data);
      });

    
    }
    viewUser(numero_documento){
      this.props.history.push(`/view-user/${numero_documento}`);
    }
    editUser(numero_documento){
      this.props.history.push(`/update-user/${numero_documento}`);
    }

    
    componentDidMount(){
      UserService.getUsers().then((res) => {
        this.state.clientesTabla = res.data.Clientes;
        this.setState(res.data);
      });
    }

    addUser(){
      this.props.history.push('/add-user/_add');
    }
    
    despues = () => {
      if(this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length >= this.state.paginacion+4 && this.state.paginacion+4 < this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length-1)
      {this.setState({...this.state, paginacion:this.state.paginacion +4})}      
    }
  
    antes = () => {
      if(this.state.paginacion -4 >= -1)
      {this.setState({...this.state, paginacion:this.state.paginacion -4})}
      if(this.state.paginacion -4 <=2)
      {this.setState({...this.state, paginacion:this.state.paginacion = -1})}
    }
    
  
    ultima = () => {    
      if(this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length >= this.state.paginacion && this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length > 8)
      {this.setState({...this.state, paginacion:this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length -4})}
    }
  
    primera = () => {
      if(this.state.paginacion -4 >= -1)
      {this.setState({...this.state, paginacion:this.state.paginacion = -1})}
    }

    
  empezar = () => {
    if(this.state.paginacion > -1)
    {this.setState({...this.state, paginacion:this.state.paginacion = -1})}
  }

    filtrarEmpre = () => {
      let clientesTemp = [];
      this.state.Clientes.forEach(e =>{
        // let tempName = e.nombres.toUpperCase() + '';
        let tempEmp = e.empresa+ '';
        let tempDoc = e.numero_documento + '';
        if(tempEmp.search(this.state.filtroEmpresa.toUpperCase()) > -1 && (tempDoc.search(this.state.filtroDocumento.toUpperCase()) > -1)){
          clientesTemp.push(e); 
        }
      })
      this.setState({
        clientesTabla: clientesTemp,
      })
      setTimeout(() => {
        this.empezar()
    }, 1000);
    }
    cancela = () =>{
      this.setState([
        this.state.clientesTabla = this.state.Clientes
      ])
    }

    render() {
        return (
            <div>
            <Header/>
                <Menu/>
                <div className="content-wrapper">
                <br></br>
                <div className='row'>
                  <div className='col-6'>
                    <h2 className="text-center">Créditos Rechazados   </h2>
                    <br></br>
                    
                  </div>
                  <div className='col-6 text-center'>                   
          
                 
                 </div>
                </div>
                <div className='row'>
                  <div className='col-md-3 offset-md-6'>
                    <form className="form-inline ml-3">          
                      <input className="form-control casi1" type="text" placeholder="Filtrar numero documento" onChange={e => {this.state.filtroDocumento = e.target.value;}} onKeyUp={e => this.filtrarEmpre()}/>
                    </form>
                  </div>
                  <select id='fempre' className=" col-2 imput-select form-control casi" required onChange={e => {this.state.filtroEmpresa = e.target.value;}} onClick={e => this.filtrarEmpre()}>
                    <option value='' >Filtrar por empresa</option>
                    <option value="INTEVO">INTEVO</option>
                    <option value="BONGO ANALYTICS">BONGO ANALYTICS</option>
                    <option value="UNION TEMPORAL">UNIÓN TEMPORAL</option>
                    <option value="SMARTEC">SMARTEC</option>
                    <option value="MEGAFAST">MEGAFAST</option>
                    <option value="PEAR SOLUTIONS">PEAR SOLUTION</option>
                    <option value="TERUNGAMES">TERUNGAMES</option>
                  </select>         
                </div>
                <br></br>  
                 <div className = "row" >
                   
                   
                        <table className = "table table-striped table-bordered" id="tablaexcel">

                            <thead>
                                <tr className='titulos-tabla'>
                                    
                                    <th> ID</th>
                                    <th> Nombres</th>
                                    <th> Apellidos</th>
                                    <th> Número de Documento</th>
                                    <th> Empresa</th>
                                    <th> Ingresos</th>
                                    <th> Correo</th>
                                    <th> Celular</th>
                                    <th> Estado Desembolso</th>
                                    <th> Estado Prestamo</th>
                                    <th> Fecha Rechazado</th>
                                    <th> Valor Prestamo</th>
                                    <th> Valor Cuota</th>
                                    <th> Valor Deuda</th>
                                    <th>  # de Cuotas</th>
                                    <th> Cuota 1</th>
                                    <th> Cuota 2</th>
                                    <th> Cuota 3</th>
                                    <th> Fecha de Solicitud</th>
                                    <th> Observaciones</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).map(
                                        (cliente, index) => 
                                        {
                                          return (index > this.state.paginacion && index <= this.state.paginacion +4) &&
                                        (
                                        <tr key = {cliente.numero_documento}>
                                             
                                            {cliente.estado_desembolso=="Rechazado"?<td>#{cliente._id}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.nombres}</td>:<td style={{display :`none`}}></td>} 
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.apellidos}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.numero_documento}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.empresa}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.ingresos} SMMLV</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.correo}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.celular}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.estado_desembolso}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.estado_prestamo}</td>:<td style={{display :`none`}}></td>}   
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.fecha_desembolso}</td>:<td style={{display :`none`}}></td>}                                         
                                            {cliente.estado_desembolso=="Rechazado"?<td> ${new Intl.NumberFormat().format(cliente.monto_prestamo)}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td> ${new Intl.NumberFormat().format(cliente.monto_cuotas)}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td> ${new Intl.NumberFormat().format(cliente.valor_deuda)}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td> {cliente.cuotas}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td> {cliente.cuota1}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.cuota2}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.cuota3}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.fecha_solicitud}</td>:<td style={{display :`none`}}></td>}
                                            {cliente.estado_desembolso=="Rechazado"?<td>{cliente.observaciones_rechazado}</td>:<td style={{display :`none`}}></td>}
    
                                            
                                      
                                                
                                                 {/* <button style={{marginLeft: "10px"}} onClick={ () => this.deleteUser(cliente.numero_documento)} className="btn btn-danger">Delete </button> */}
                                                 {/* <button style={{marginLeft: "10px"}} onClick={ () => this.viewUser(cliente.numero_documento)} className="btn btn-info">View </button> */}
                                             
                                        </tr>
                                        )}
                                    )
                                }
                            </tbody>
                        </table>                      

                 </div>

            </div>
            
            <div className='row col-12 '>
          <div className='col-1 offset-md-4'>
            <button  className='Bback' onClick={this.primera}><BsFillSkipBackwardFill/></button>
          </div>
          <div className='col-1 '>
            <button  className='Bback' onClick={this.antes}><BsFillCaretLeftFill/></button>
          </div>
          <div className="col-1">
            <span>{Math.ceil((((this.state.paginacion)/4)+1))}</span> de <span> {Math.ceil((((this.state.clientesTabla.filter((cliente)=>cliente.estado_desembolso=="Rechazado" ).length)/4)))}</span>
          </div>
          <div className='col-1'>
            <button  className='Bnext' onClick={this.despues}><BsFillCaretRightFill/></button>              
          </div>
          <div className='col-1'>
            <button  className='Bnext' onClick={this.ultima}><BsFillSkipForwardFill/></button>
          </div>
        </div>
            </div>
        )
    }
}

export default CreditosRechazados
