import React, { useState, useEffect } from "react";
import Header from "./Header";
import UserService from "../../services/UserService";
import Menu from "./Menu";
import TablaDeudas from '../PagosEmpresas/TablaGeneral'
import "../../css/PagosEmpresas.css";

const PagosEmpresas = () =>{
  const [clientes, setClientes] = useState([]);
  const [empresa, setempresa] = useState([]);
  const [mesesDeudaActivo, setMesesDeudaActivo] = useState([]);
  const [mesesDeudaLiquidado, setMesesDeudaLiquidado] = useState([]);
  const [totalDeudaActivo, setTotalDeudaActivo] = useState([]);
  const [totalDeudaLiquidado, setTotalDeudaLiquidado] = useState([]);
  const [totalPagado, setTotalPagado] = useState([]);
  const [totalLiquidado, setTotalLiquidado] = useState([]);
  const [totalPrestamosActivos, setTotalPrestamosActivos] = useState([]);
  const [totalPrestamosLiquidados, setTotalPrestamosLiquidados] = useState([]);
  const [idsPrestamosActivos, setIdsPrestamosActivos] = useState([]);
  const [idsPrestamosLiquidados, setIdsPrestamosLiquidados] = useState([]);
  const [resultadoFiltrado, setResultadoFiltrado] = useState([]);

  useEffect(() => {
    UserService.getUsers().then((clientes) => {
      setClientes(clientes.data.Clientes);
    });
  }, []);

  const Filtros = () => {
    const valorFiltrar = document.getElementById("empresa").value;
  const fechaInicio = document.getElementById("fecha_inicio").value + "-1";
  const fechaFin = document.getElementById("fecha_fin").value + "-1";
  const mesesUnicos = new Set();

  const resultadoFiltrado = clientes.filter(
    (item) => item.empresa === valorFiltrar
  );

  resultadoFiltrado.forEach((item) => {
    let fechaCuota = new Date(item.fecha_cuota.split("/").reverse().join("-"));
    let fechaEstimadaCuota2 = new Date(item.fechaestimada_cuota2.substring(0,10).split("/").reverse().join("-"));
    let fechaEstimadaCuota3 = new Date(item.fechaestimada_cuota3.substring(0,10).split("/").reverse().join("-"));

    mesesUnicos.add(fechaCuota.toLocaleString('default', { month: 'long' }));
    mesesUnicos.add(fechaEstimadaCuota2.toLocaleString('default', { month: 'long' }));
    mesesUnicos.add(fechaEstimadaCuota3.toLocaleString('default', { month: 'long' }));
  });

  const mesesListado = Array.from(mesesUnicos).sort();
  const numeroDeElementos = mesesListado.length;

  const totalDeudaActivo = Array(numeroDeElementos).fill(0);
  const totalDeudaLiquidado = Array(numeroDeElementos).fill(0);
  const totalPagado = Array(numeroDeElementos).fill(0);
  const totalLiquidado = Array(numeroDeElementos).fill(0);
  const mesesDeudaActivo = Array.from({ length: numeroDeElementos }, () => []);
  const mesesDeudaLiquidado = Array.from({ length: numeroDeElementos }, () => []);
  const idsPrestamosActivos = Array.from({ length: numeroDeElementos }, () => []);
  const idsPrestamosLiquidados = Array.from({ length: numeroDeElementos }, () => []);
  const totalPrestamosActivos = Array(numeroDeElementos).fill(0);
  const totalPrestamosLiquidados = Array(numeroDeElementos).fill(0);

  const fechaInicioObj = new Date(fechaInicio);
  const fechaFinObj = new Date(fechaFin);

  resultadoFiltrado.forEach((e) => {
    setempresa(e.empresa);
    let liquidado = false;
    let fechaLiq = null;
    const fechaCuotas = [
      e.fecha_cuota,
      e.fechaestimada_cuota2,
      e.fechaestimada_cuota3,
    ];

    const estadoCuotas = [
      e.cuota1,
      e.cuota2,
      e.cuota3,
    ];

    const estadoPrerstamo = e.estado_prestamo;

    let diaLiquidacion =  e.fecha_liquidacion ?  parseInt(e.fecha_liquidacion.substring(0,2)) : null;

    if(diaLiquidacion <= 29){
      fechaLiq = e.fecha_liquidacion
      ? "1/" + e.fecha_liquidacion.substring(3, 10)
      : "";
    }else{
      const mesLiq = e.fecha_liquidacion
        ? e.fecha_liquidacion.substring(3, 10)
        : "";

      // Obtener el mes y el año por separado
      let partes = mesLiq.split("/");
      let mes = parseInt(partes[0], 10);
      let anio = parseInt(partes[1], 10);

      // Aumentar en uno el mes y ajustar el año si es diciembre
      mes++;
      if (mes > 12) {
        mes = 1;
        anio++;
      }

      // Asegurarse de que el mes tenga dos dígitos
      let mesFormateado = mes.toString().padStart(2, "0");

      // Actualizar la fecha con el nuevo mes y año
      fechaLiq =  `1/${mesFormateado}/${anio}`;
    }

    const fechaLiqObj = fechaLiq
      ? new Date(fechaLiq.split("/").reverse().join("-"))
      : null;

    fechaCuotas.forEach((fechaCuota, index) => {
      const fecha = "1/" + fechaCuota.substring(3, 10);
      const fechaObj = new Date(fecha.split("/").reverse().join("-"));

      if (
        fechaObj >= fechaInicioObj &&
        fechaObj <= fechaFinObj &&
        e.empresa === valorFiltrar &&
        !liquidado && e.estado_prestamo !== 'Rechazado' && 
        e.estado_prestamo !== 'Preaprobado'
      ) {
        const mesActual = fechaObj.toLocaleString("default", { month: "long" });
        if(e._id === 252){
          console.log(fechaLiqObj)
          console.log(fechaLiq)
          console.log(fecha)
        }
        if (fechaLiqObj && fechaLiq <= fecha) {
          totalDeudaLiquidado[mesesListado.indexOf(mesActual)] += e.valor_normalizado;
          totalPrestamosLiquidados[mesesListado.indexOf(mesActual)] += 1;
          mesesDeudaLiquidado[mesesListado.indexOf(mesActual)].push(mesActual);
          idsPrestamosLiquidados[mesesListado.indexOf(mesActual)].push(e._id);
          liquidado = true;
          if (estadoPrerstamo === 'Finalizado Liquidado') {
            totalLiquidado[mesesListado.indexOf(mesActual)] += e.valor_normalizado;
            totalPrestamosLiquidados[mesesListado.indexOf(mesActual)] += 1;
          }
        } else {
          totalDeudaActivo[mesesListado.indexOf(mesActual)] += e.monto_cuotas;
          totalPrestamosActivos[mesesListado.indexOf(mesActual)] += 1;
          mesesDeudaActivo[mesesListado.indexOf(mesActual)].push(mesActual);
          idsPrestamosActivos[mesesListado.indexOf(mesActual)].push(e._id);
        }

        if (estadoCuotas[index] === "Pagada") {
          totalPagado[mesesListado.indexOf(mesActual)] += e.monto_cuotas;
        }

      }
    });
  });

    setMesesDeudaActivo(mesesDeudaActivo);
    setMesesDeudaLiquidado(mesesDeudaLiquidado);
    setTotalDeudaActivo(totalDeudaActivo);
    setTotalDeudaLiquidado(totalDeudaLiquidado);
    setTotalPagado(totalPagado)
    setTotalLiquidado(totalLiquidado)
    setTotalPrestamosActivos(totalPrestamosActivos)
    setTotalPrestamosLiquidados(totalPrestamosLiquidados)
    setIdsPrestamosActivos(idsPrestamosActivos)
    setIdsPrestamosLiquidados(idsPrestamosLiquidados)
    setResultadoFiltrado(resultadoFiltrado)

  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <br />
        <div className="row titulogeneral">
          <h2 className="text-center3">Pagos de Empresas</h2>
          <br />
          <br />
        </div>
        <div className="row titulosecundario">
          <div>
            <h2 className="text-center3">Filtros</h2>
            <br></br>
          </div>
        </div>
        <div className="row justify-content-center mb-4">
          <div className="filter_row_Pagos">
            <select
              id="empresa"
              className=" col-4 imput-select form-control mr-2"
              required
            >
              <option value="">Empresa</option>
              <option value="INTEVO">INTEVO</option>
              <option value="BONGO ANALYTICS">BONGO ANALYTICS</option>
              <option value="UNION TEMPORAL">UNIÓN TEMPORAL</option>
              <option value="SMARTEC">SMARTEC</option>
              <option value="MEGAFAST">MEGAFAST</option>
              <option value="PEAR SOLUTIONS ">PEAR SOLUTION</option>
              <option value="TERUNGAMES">TERUNGAMES</option>
            </select>

            <div className="filter_row_fechas_pagos">
              <input
                className="form-control "
                type="month"
                placeholder="Fecha inicio"
                id="fecha_inicio"
              />
              <input
                className="form-control ml-3 "
                type="month"
                placeholder="Filtrar fin"
                id="fecha_fin"
              />
              <button onClick={Filtros} className="btn btn-success ml-3">
                Filtrar
              </button>
            </div>
          </div>
        </div>
        {
          //Tipo de deuda /* *Si es activo: estado prestamo desembolsado o finalizado
          //*Si es Liquidación: estado prestamo en liquidacion o finalizado liquidado
        }
        <TablaDeudas
          resultadoFiltrado={resultadoFiltrado}
          empresa={empresa}
          mesesDeudaActivo={mesesDeudaActivo}
          mesesDeudaLiquidado={mesesDeudaLiquidado}
          totalDeudaActivo={totalDeudaActivo}
          totalDeudaLiquidado={totalDeudaLiquidado}
          totalPagado={totalPagado}
          totalLiquidado={totalLiquidado}
          totalPrestamosActivos={totalPrestamosActivos}
          totalPrestamosLiquidados={totalPrestamosLiquidados}
          idsPrestamosActivos={idsPrestamosActivos}
          idsPrestamosLiquidados={idsPrestamosLiquidados}
        />
      </div>
    </div>
  );
}

export default PagosEmpresas;