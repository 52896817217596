import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
export default function CargaExcelPagos() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [fecha, setFecha] = useState("");
  const [empleados, setEmpleados] = useState([]);

  const meses = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];

  const getMesAnioFormato = (fecha) => {
    const [, mes, anio] = fecha.split("/");
    const nombreMes = meses[parseInt(mes, 10) - 1];
    return `${nombreMes}-${anio.slice(-2)}`;
  };

  const validacionFecha = (e) => {
    let fecha = e.target.value;
    if (fecha !== "") {
      const inputFecha = e.target.value;

      const year = inputFecha.slice(2, 4);
      const month = inputFecha.slice(5, 7);
      const meses = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];
      const monthName = meses[parseInt(month, 10) - 1];

      const fechaFormateada = `${monthName}-${year}`;
      console.log(fechaFormateada);
      setFecha(fechaFormateada);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    UserService.getUsers().then((res) => {
      setEmpleados(res.data.Clientes);
    });
  }, []);

  const calcularSaldo = (idEmpleado) => {
    let filtro = empleados.filter((item) => item._id === idEmpleado);
    let saldo = filtro[0].valor_deuda;
    let valorMesBuscado = null;
    let activo = false;

    filtro.forEach((itemFiltro) => {
      const cuotas = [
        "fecha_cuota",
        "fechaestimada_cuota2",
        "fechaestimada_cuota3",
      ];
      const estadoCuota = ["cuota1", "cuota2", "cuota3"];

      cuotas.forEach((nombreCuota, index) => {
        // debugger;
        const fechaCuota =
          itemFiltro[nombreCuota] !== "No Aplica" &&
          itemFiltro[nombreCuota] !== "null"
            ? getMesAnioFormato(itemFiltro[nombreCuota].slice(0, 10))
            : "No Aplica";
        if (fechaCuota === fecha) {
          activo = true;
          itemFiltro[estadoCuota[index]] !== "Pagada"
            ? (valorMesBuscado = itemFiltro.monto_cuotas)
            : (valorMesBuscado = 0);

          for (let i = 0; i < parseInt(itemFiltro.cuotas); i++) {
            if (itemFiltro[estadoCuota[i]] === "Pagada") {
              saldo = saldo - itemFiltro.monto_cuotas;
            }
          }
        }
      });
    });
    return { saldo: saldo, valorMesBuscado: valorMesBuscado, activo: activo };
  };

  const crearDatosParaExcel = () => {
    let datosParaExcel = [
      [
        "# CREDITO",
        "EMPRESA",
        "CC",
        "NOMBRES Y APELLIDOS",
        "FECHA SOLICITUD",
        "MONTO SOLICITADO",
        "# CUOTAS",
        "INTERESES",
        "TARIFA",
        "CONS. PERFIL",
        "VALOR TOTAL",
        "VALOR CUOTA",
        "SALDO",
        fecha,
      ],
    ];
    for (let i = 0; i < empleados.length; i++) {
      console.log(empleados[i].estado_prestamo);
      console.log(empleados[i].estado_desembolso);
      if (
        empleados[i].estado_prestamo === "Aprobado" &&
        empleados[i].estado_desembolso === "Desembolsado"
      ) {
        // debugger
        let calculos = calcularSaldo(empleados[i]._id);
        if (calculos.activo) {
          let fila = [
            empleados[i]._id,
            empleados[i].empresa,
            empleados[i].numero_documento,
            empleados[i].nombres + " " + empleados[i].apellidos,
            empleados[i].fecha_solicitud,
            empleados[i].monto_prestamo,
            empleados[i].cuotas,
            empleados[i].valor_interes,
            empleados[i].tarifa_administrativa,
            empleados[i].consulta_perfil,
            empleados[i].valor_deuda,
            empleados[i].monto_cuotas,
            calculos.saldo,
            calculos.valorMesBuscado,
          ];
          datosParaExcel.push(fila);
        }
      }
    }
    return datosParaExcel;
  };

  const reportExcel = () => {
    let XLSX = require("xlsx");

    let datosParaExcel = crearDatosParaExcel();
    let hojaDeCalculo = XLSX.utils.aoa_to_sheet(datosParaExcel);
    let libroDeExcel = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libroDeExcel, hojaDeCalculo, "Datos");
    XLSX.writeFile(libroDeExcel, "reporteNomina.xlsx");
  };

  let buttonClass = "custom-file-button-pagos-disabled";
  !isDisabled
    ? (buttonClass = "custom-file-button-pagos ml-3")
    : (buttonClass = "custom-file-button-pagos-disabled ml-3");
  return (
    <div>
      <div className="row titulosecundario">
        <div>
          <h2 className="text-center3">Reporte Nómina</h2>
          <br></br>
        </div>
      </div>

      <div className="reportes">
        <div className="filter_row_fechas_pagos">
          <label>Selecciona la fecha</label>
          <input
            className="form-control w-50"
            type="month"
            placeholder="Fecha inicio"
            id="fecha_reporte"
            onChange={(e) => {
              validacionFecha(e);
            }}
          />
        </div>

        <label
          for="myFileInputPagos"
          className={buttonClass}
          onClick={reportExcel}
        >
          Descargar
        </label>
      </div>
    </div>
  );
}
