import React, { Component } from 'react'
import '../../css/footer.css'

export default class Footer extends Component {
    render() {
        return (
            <div>
              <footer class="main-footer">
    <div class="float-right d-none d-sm-block">
      <b>Version</b> 1.0.0
    </div>
    <strong className='desa'>Desarrollado por <a href="/">Bongoanalytics</a></strong> All rights
    reserved.
  </footer>

            </div>
        )
    }
}
