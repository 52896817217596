import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import { BsJournalArrowDown } from "react-icons/bs";
import { BsCalendarCheckFill } from "react-icons/bs";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { FaHandHoldingUsd, FaMoneyBill, FaRegListAlt, FaRegChartBar } from "react-icons/fa";
import { CgLogOut } from "react-icons/cg";
import { GiCancel } from "react-icons/gi";
import axios from 'axios'
import '../../css/Menu.css'


export default class Menu extends Component {

  constructor(props) {
    super(props);
    this.state = {
      id_usuario: 0
    };
  }

  componentDidMount= async () =>{

    // Condicional para cambio de color interfaz super admin
    this.dataLocal = JSON.parse(localStorage.getItem('email'))
    let res2= await axios.get(
      //PRE PRODUCCION
      // `http://190.109.16.229:10260/api3/searchcliente/${this.dataLocal}`
      //PRODUCCION
      `https://apiusers.tubongo.com/api3/searchcliente/${this.dataLocal}`
      )
    let id_usuario=res2["data"]["Usuario"]["0"]["id_tipo_usuario"];
    console.log("DATO SUPER ADMIN")
    console.log(id_usuario)
    this.setState({
      id_usuario: id_usuario,
    });
    if (id_usuario === 3) {
      const div = document.querySelector(".main-sidebar");
      div.setAttribute("style", "background-color: #3E3B8C;");
    } else if(id_usuario === 4 || id_usuario === 5) {
      const div = document.querySelector(".main-sidebar");
      div.setAttribute("style", "background-color: #3E3B8C;");
    } else {
      const div = document.querySelector(".main-sidebar");
      div.setAttribute("style", "background-color: #0d90d4;");
    }

  }
  

  handleLogout = () => {
    localStorage.removeItem("token");
    window.location="/login"
  };
    render() {
        return (
          <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                  <div className="image">
                    <img
                      src="/dist/img/sampluser.png"
                      className="img-circle elevation-2"
                      alt="User Image"
                    />
                  </div>
                  {this.state.id_usuario !== 4 ?
                  <div className="info">
                    <a href="/" className="d-block">
                      Admin tu bongo
                    </a>
                  </div> : <div className="info">
                    <a href="/" className="d-block">
                      Usuario tu bongo
                    </a>
                  </div> }
                </div>
                {/* Brand Logo */}
                <a href="/" className="brand-link">
                  <p className="brand-text font-weight-light">
                    PÓRTAL ADMINISTRADOR
                  </p>
                </a>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                  >
                    {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                    
                    <li className="nav-item">
                      <a href="/VistaGeneral" className="nav-link">
                        <p>
                          <BsEyeFill /> Vista General
                        </p>
                      </a>
                    </li>
                    {this.state.id_usuario !== 5 &&
                    <li className="nav-item">
                      <Link to="/PendienteDesembolso" className="nav-link">
                        <p>
                          <BsJournalArrowDown /> Pendientes Desembolso{" "}
                        </p>
                      </Link>
                    </li>}
                    {this.state.id_usuario !== 4 && this.state.id_usuario !== 5 &&
                    <li className="nav-item">
                      <a href="/CreditosDesembolsados" className="nav-link">
                        <p>
                          <BsFileEarmarkArrowUp /> Control de Pagos
                        </p>
                      </a>
                    </li> }
                    {this.state.id_usuario !== 4 && this.state.id_usuario !== 5 && 
                    <li className="nav-item">
                      <a href="/CreditosRechazados" className="nav-link">
                        <p>
                          <GiCancel /> Créditos Rechazados
                        </p>
                      </a>
                    </li>}

                    {this.state.id_usuario !== 4 && this.state.id_usuario !== 5 &&
                    <li className="nav-item">
                      <a href="/CreditosFinalizados" className="nav-link">
                        <p>
                          <BsCalendarCheckFill /> Créditos Finalizados
                        </p>
                      </a>
                    </li>}
                    {this.state.id_usuario !== 4 && this.state.id_usuario !== 5 &&
                    <li className="nav-item">
                      <a href="/Liquidacion" className="nav-link">
                        <p>
                          <FaHandHoldingUsd /> Créditos en Liquidación
                        </p>
                      </a>
                    </li>}
                    {this.state.id_usuario !== 4 && this.state.id_usuario !== 5 &&
                    <li className="nav-item">
                      <a href="/Tarifas" className="nav-link">
                        <p>
                          <FaMoneyBill />  Tasas y Tarifas
                        </p>
                      </a>
                    </li> }

                    {this.state.id_usuario !== 4 &&
                    <li className="nav-item">
                      <a href="/PagosEmpresas" className="nav-link">
                        <p>
                          <FaRegListAlt />  Pagos Empresas
                        </p>
                      </a>
                    </li> }

                    {this.state.id_usuario !== 4 &&
                    <li className="nav-item">
                      <a href="/Indicadores" className="nav-link">
                        <p>
                          <FaRegChartBar />  Indicadores
                        </p>
                      </a>
                    </li> }

                    <br></br>
                    <li className="nav-item">
                      <a
                        href="#"
                        className="nav-link"
                        onClick={this.handleLogout}
                      >
                        <p>
                          <CgLogOut /> Cerrar Sesión
                        </p>
                      </a>
                    </li>
                  </ul>
                </nav>

                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
          </div>
        );
    }
}
