export default function CalculoLiquidacion(
    fechaLiquidacion,
    fechaDesembolso,
    saldofecha1,
    valorTarifaADM,
    cuotas,
    fechapago1,
    fechapago2,
    fechapago3,
    saldofecha2,
    saldofecha3,
    diaLiquidacion,
    montoPrestamo,
    tasaInteres,
    ) {

    let result = 0;
    let result1 = 0;
    
    
    //FORMULA PARA EL SALDO A LA FECHA DE LIQUIDACIÓN
    if (fechaLiquidacion === fechaDesembolso) {
      console.log(result = saldofecha1);
    } else if (fechaLiquidacion < fechapago1) {
      var diferenciaMs = new Date(fechaLiquidacion) - new Date(fechaDesembolso);
      var diferenciaDias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));
      console.log(result = montoPrestamo * Math.pow(1 + tasaInteres, diferenciaDias));
    } else if (fechaLiquidacion === fechapago1) {
      console.log(result = saldofecha2 * 0.9994);
    } else if (fechaLiquidacion > fechapago1 && fechaLiquidacion < fechapago2) {
      console.log(result = saldofecha2 * Math.pow(1 + tasaInteres, diaLiquidacion - 1));
    } else if (fechaLiquidacion === fechapago2) {
      console.log(result = saldofecha3 * 0.9994);
    } else if (fechaLiquidacion > fechapago2 && fechaLiquidacion < fechapago3) {
      console.log(result = saldofecha3 * Math.pow(1 + tasaInteres,  (diaLiquidacion - 1)));
    } else if (fechaLiquidacion === fechapago3) {
      console.log(result = 0);
    }
    //FORMULA Y GASTOS ADM POR COBRAR
    if (fechaLiquidacion < fechapago1) {
      console.log(result1 = valorTarifaADM * cuotas);
    } else if (fechaLiquidacion === fechapago1) {
      console.log(result1 = valorTarifaADM * (cuotas - 1));
    } else if (fechaLiquidacion > fechapago1 && fechaLiquidacion < fechapago2) {
      console.log(result1 = valorTarifaADM * (cuotas - 1));
    } else if (fechaLiquidacion === fechapago2) {
      console.log(result1 = valorTarifaADM * (cuotas - 2));
    } else if (fechaLiquidacion > fechapago2 && fechaLiquidacion < fechapago3) {
      console.log(result1 = valorTarifaADM * (cuotas - 2));
    } else if (fechaLiquidacion === fechapago3) {
      console.log(result1 = 0);
    }
    
    
    return {
        result: result,
        result1: result1,
        resultTotal: result + result1,
      };
}