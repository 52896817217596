import React, { Component } from 'react'
import { BsSearch } from "react-icons/bs";
import { BsFilterLeft } from "react-icons/bs";
import '../../css/Header.css'
import axios from 'axios'
export default class Header extends Component {
  componentDidMount = async () => {
    // Condicional para cambio de color interfaz super admin
    this.dataLocal = JSON.parse(localStorage.getItem("email"));
    let res2 = await axios.get(
      //PRE PRODUCCION
      //  `http://190.109.16.229:10260/api3/searchcliente/${this.dataLocal}`
      //PRODUCCION
     `https://apiusers.tubongo.com/api3/searchcliente/${this.dataLocal}`
    );
    let id_usuario = res2["data"]["Usuario"]["0"]["id_tipo_usuario"];
    console.log("DATO SUPER ADMIN");
    console.log(id_usuario);
    if (id_usuario === 3) {
      const div = document.querySelector(".main-header");
      div.setAttribute("style", "background-color: #3E3B8C;");
    }
    else if (id_usuario === 4 ||id_usuario === 5 ) {
      const div = document.querySelector(".main-header");
      div.setAttribute("style", "background-color: #3E3B8C;");
    } else {
      const div = document.querySelector(".main-header");
      div.setAttribute("style", "background-color: #0d90d4;");
    }
  };

  render() {
    return (
      <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          {/* Left navbar links */}
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link iconm"
                data-widget="pushmenu"
                href="#"
                role="button"
              >
                <i className="fas fa-bars" /> <BsFilterLeft />
              </a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="/VistaGeneral" className="nav-link letra-header">
                Inicio
              </a>
            </li>
            <li className="nav-item d-none d-sm-inline-block">
              <a href="#" className="nav-link letra-header">
                Soporte
              </a>
            </li>
          </ul>
          <a href="#" className="brand-link muevelogo">
            <img
              src="/dist/img/Grupo 2016.png"
              alt="AdminLTE Logo"
              className="brand-image  elevation-3"
              style={{ opacity: ".8" }}
            />
          </a>
        </nav>
      </div>
    );
  }
}
