import axios from 'axios';


//URL DE PRE PREPRODUCCION 
// let Url="http://190.109.16.229:10259"

//URL DE PRODUCCION 
let Url="https://apineopage.tubongo.com"



let UrlEmail="http://localhost:9900"


class UserService {

    

    getUsers(){
        return axios.get(Url+'/api/clientes');
    }

    createUser(user){
        return axios.post(Url+'/api/add', user);
    }
    createUser2(){
        return Url+"/api/add";
    }
    
    getEmployeById(userId){
        return axios.get(Url+'/api/searchempleado/'+ userId);
    }

    getUserById(userId){
        return axios.get(Url+'/api/searchcliente/'+ userId);
    }

    updateUser(user, userId){
        return axios.put(Url+'/api/update/'+ userId, user);
    }

    deleteUser(userId){
        return axios.delete(Url+'/api/delete/'+ userId);
    }
    getUserByi(userId){
        return axios.get(Url+'/api/searchclienteid/'+ userId);
    }
    updateUserId(user, userId){
        return axios.put(Url+'/api/updateid/'+ userId, user);
    }

    sendEmail(info){
        return axios.post(UrlEmail+'/send-pdf', info);
    }
    
    getTarifas(){
        return axios.get(Url +'/api/tarifas');
    }

    addTarifas(info ){
        return axios.post(Url +'/api/add-tarifas/',info);
    }

}

export default new UserService()