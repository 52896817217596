import Header from "./Header";
import Menu from "./Menu";
import "../../css/Tarifas.css";
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";

const Indicadores = () => {
  const [data, setData] = useState([]);
  const [indicadores, setIndicadores] = useState([]);
  const [indicadoresFiltro, setIndicadoresFiltro] = useState([]);
  useEffect(() => {
    UserService.getUsers().then((res) => {
      setData(res.data.Clientes);

      const estadoPrestamoBuscado = "Preaprobado";
      const estadoDesembolsoBuscado = "Pendiente";
      const estadoDesembolsoDesembolsado = "Desembolsado";
      const estadoPrestamoAprobado = "Aprobado";

      const totalPreaprobados = res.data.Clientes.filter(
        (cliente) => cliente.estado_prestamo === estadoPrestamoBuscado
      ).length;
      const totalPendientes = res.data.Clientes.filter(
        (cliente) =>
          cliente.estado_desembolso === estadoDesembolsoBuscado &&
          cliente.estado_prestamo === estadoPrestamoAprobado
      ).length;
      const totalDesembolsadoAprobado = res.data.Clientes.filter(
        (cliente) =>
          cliente.estado_desembolso === estadoDesembolsoDesembolsado &&
          cliente.estado_prestamo === estadoPrestamoAprobado
      ).length;

      let indicadoresData = {
        totalPreaprobados: totalPreaprobados,
        totalPendientes: totalPendientes,
        totalDesembolsadoAprobado: totalDesembolsadoAprobado,
      };

      setIndicadores(indicadoresData);
    });
  }, []);

  const calculos = () => {
    let fecha_inicio = document.getElementById("fecha_inicio").value;
    let fecha_fin = document.getElementById("fecha_fin").value;
    let fechaInicioObj = new Date(fecha_inicio.split("/").reverse().join("-"));
    let fechaFinObj = new Date(fecha_fin.split("/").reverse().join("-"));

    const filteredSolicutud = data.filter((item) => {
      let fecha_solicitud = item.fecha_solicitud.substring(0, 10);
      let fechaObj = new Date(fecha_solicitud.split("/").reverse().join("-"));
      return fechaObj >= fechaInicioObj && fechaObj <= fechaFinObj;
    });

    const filteredDesembolso = data.filter((item) => {
      let fecha_solicitud = item.fecha_desembolso.substring(0, 10);
      let fechaObj = new Date(fecha_solicitud.split("/").reverse().join("-"));
      return fechaObj >= fechaInicioObj && fechaObj <= fechaFinObj;
    });


    let sumaMontoPrestamo = 0;
    let valorDeuda = 0;
    let aprobados = 0;
    let rechazados = 0;
    let pendientes = 0;
    let totalDesembolsado = 0;
    const desembolsados = filteredDesembolso.length

    filteredSolicutud.forEach((item) => {
      sumaMontoPrestamo += item.monto_prestamo;
      if(item.estado_prestamo === 'Rechazado'){
        rechazados += 1
      }if(item.estado_prestamo === 'Aprobado' || item.estado_prestamo === 'Finalizado' || item.estado_prestamo ==='En Liquidacion' || item.estado_prestamo === 'Finalizado Liquidado' ){
        aprobados += 1
      }if (item.estado_desembolso === 'Pendiente' && item.estado_prestamo === "Aprobado") {
        pendientes += 1
      }
    });

    filteredDesembolso.forEach((item) => {
      totalDesembolsado += item.monto_prestamo;
      valorDeuda += item.valor_deuda;
    });

    const totalPretamos = filteredSolicutud.length;

    let indicadoresFiltroData = {
      totalPrestamos: totalPretamos,
      totalSolicitado: sumaMontoPrestamo,
      totalAprobados: aprobados,
      totalRechazados: rechazados,
      totalPendientes: pendientes,
      totalDesembolsados: desembolsados,
      valorDeuda: valorDeuda,
      totalDesembolsado: totalDesembolsado,
    };

    setIndicadoresFiltro(indicadoresFiltroData);
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <br />
        <div className="row titulogeneral">
          <h2 className="text-center3">Indicadores</h2>
          <br />
          <br />
        </div>

        <div className="row d-flex justify-content-between ml-5 mr-5 mb-5">
          <div className="cardIndicadores">
            <div>
              <h6>Préstamos Pre-Aprobados</h6>
              <p>{indicadores.totalPreaprobados}</p>
            </div>
          </div>
          <div className="cardIndicadores">
            <div>
              <h6>Préstamos Pendientes de desembolso</h6>
              <p>{indicadores.totalPendientes}</p>
            </div>
          </div>

          <div className="cardIndicadores">
            <div>
              <h6>Préstamos desembolsados</h6>
              <p>{indicadores.totalDesembolsadoAprobado}</p>
            </div>
          </div>
        </div>

        <div className="row titulosecundario">
          <div>
            <h2 className="text-center3">Filtros de búsqueda</h2>
            <br></br>
          </div>
        </div>

        <div className="row justify-content-center mb-4">
          <div className="d-flex w-50 p-1">
            <div className="d-flex align-items-center">
              <label htmlFor="fecha_inicio" className="mr-2">
                Desde:{" "}
              </label>
              <input
                className="form-control "
                type="date"
                placeholder="Fecha inicio"
                id="fecha_inicio"
              />
            </div>
            <div className="d-flex align-items-center">
              <label htmlFor="fecha_fin" className="ml-3 mr-2">
                Hasta:{" "}
              </label>
              <input
                className="form-control "
                type="date"
                placeholder="Fecha inicio"
                id="fecha_fin"
              />
            </div>
            <button onClick={calculos} className="btn btn-success ml-3">
              Filtrar
            </button>
          </div>
        </div>

        <div className="row titulosecundario mt-3">
          <div>
            <h2 className="text-center3">Resultados por fecha de solicitud</h2>
            <br></br>
          </div>
        </div>

        <div className="row d-flex justify-content-between ml-5 mr-5 mb-2">
          <div className="cardIndicadoresFiltroSolicitud">
            <div>
              <h6>Total Préstamos Solicitados</h6>
              <p>
                {indicadoresFiltro.totalPrestamos
                  ? new Intl.NumberFormat("es-MX").format(
                      indicadoresFiltro.totalPrestamos
                    )
                  : "--"}
              </p>
            </div>
          </div>
          <div className="cardIndicadoresFiltroSolicitud">
            <div>
              <h6>Total Dinero Solicitado</h6>
              <p>
                {indicadoresFiltro.totalSolicitado
                  ? `$ ${new Intl.NumberFormat("es-MX").format(
                    indicadoresFiltro.totalSolicitado
                  )}`
                  : "--"}
              </p>
            </div>
          </div>

          <div className="cardIndicadoresFiltroSolicitud">
            <div>
              <h6>Total Aprobados</h6>
              <p>
                {indicadoresFiltro.totalAprobados ? indicadoresFiltro.totalAprobados 
                  : "--"}
              </p>
            </div>
          </div>

          <div className="cardIndicadoresFiltroSolicitud">
            <div>
              <h6>Total Rechazados</h6>
              <p>
                {indicadoresFiltro.totalRechazados
                  ? indicadoresFiltro.totalRechazados
                  : "--"}
              </p>
            </div>
          </div>

          <div className="cardIndicadoresFiltroSolicitud">
            <div>
              <h6>Total Pendientes Desembolso</h6>
              <p>
                {indicadoresFiltro.totalPendientes >= 0
                  ? indicadoresFiltro.totalPendientes
                  : "--"}
              </p>
            </div>
          </div>
        </div>


        
        <div className="row titulosecundario mt-3">
          <div>
            <h2 className="text-center3">Resultados por fecha de desembolso</h2>
            <br></br>
          </div>
        </div>

        <div className="row d-flex justify-content-between ml-5 mr-5 mb-5">
          <div className="cardIndicadoresFiltro">
            <div>
              <h6>Total Dinero desembolsado</h6>
              <p>
                {indicadoresFiltro.totalDesembolsado
                  ? `$ ${new Intl.NumberFormat("es-MX").format(
                    indicadoresFiltro.totalDesembolsado
                  )}`
                  : "--"}
              </p>
            </div>
          </div>

          <div className="cardIndicadoresFiltro">
            <div>
              <h6>Total Deuda</h6>
              <p>
                {indicadoresFiltro.valorDeuda
                  ? `$ ${new Intl.NumberFormat("es-MX").format(
                    indicadoresFiltro.valorDeuda
                  )}`
                  : "--"}
              </p>
            </div>
          </div>

          <div className="cardIndicadoresFiltro">
            <div>
              <h6>Total Desembolsados</h6>
              <p>
                {indicadoresFiltro.totalDesembolsados
                  ? indicadoresFiltro.totalDesembolsados
                  : "--"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Indicadores;
