import React  from 'react'
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import logoNeo from '../images/Tu_bongo.png'
import {FaUserAlt} from "react-icons/fa";
import "../../css/Login.css"
import {RiLockPasswordFill} from "react-icons/ri"
const Login = () => {
	const [data, setData] = useState({ email: "", password: "" });
	const [error, setError] = useState("");

	const handleChange = ({ currentTarget: input }) => {
		setData({ ...data, [input.name]: input.value });
		let t = localStorage.setItem('email', JSON.stringify(data.email))
			console.log(t);
	};
	

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			  //URL PRE PRODUCCION
				// const url = "http://190.109.16.229:10261/api2/auth";
			//URL  PRODCCION
			const url = "https://apiauth.tubongo.com/api2/auth";
			const { data: res } = await axios.post(url, data);
			console.log("DATA")
			console.log(res.data);
			localStorage.setItem("token", res.data);
			
			window.location = "/";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
			}
		}
	};

	
	return (
		<div className={styles.login_container}>
			<div className={styles.login_form_container}>
				<div className={styles.left}> 
          <nav className="main-header navbar" style={{margin:' 0% 0% 3% 0%'}}>          
          <img src={logoNeo} alt="AdminLTE Logo" className="brand-image imglogo"  />
          </nav>
					<form className={styles.form_container} onSubmit={handleSubmit}>
						<h1>Iniciar Sesión</h1>
						
						<br></br>
						<FaUserAlt/>
						<input
							type="email"
							placeholder="Email"
							name="email"
							onChange={handleChange}
							value={data.email}
							required
							className={styles.input}
						/>
						<br></br>
						<RiLockPasswordFill/>
						<input
							type="password"
							placeholder="Contraseña"
							name="password"
							onChange={handleChange}
							value={data.password}
							required
							className={styles.input}
						/>
						{error && <div className={styles.error_msg}>{error}</div>}
						<br></br>
						<button type="submit" className={styles.green_btn}>
						Ingresar
						</button>
					</form>
				</div>
				{/* <div className={styles.right}>
					<h1>Registrarse</h1>
					<br></br>
					<Link to="/signup">
						<button type="button" className={styles.white_btn}>
							Registrar
						</button>
					</Link>
				</div> */}
			</div>
		</div>
	);
};

export default Login;
