import React, { Component } from 'react'
import UserService from '../../services/UserService';
import { Link } from "react-router-dom";
import Header from './Header'
import Footer from './Footer'
import Menu from './Menu'
import { c } from 'fonts/defaultFont';


class UpdateUserComponent2 extends Component {
    constructor(props) {
        super(props)
        this.dataLocal = JSON.parse(localStorage.getItem('documento1'))
        this.state = {
            id: this.dataLocal,
            estado_desembolso:'',
            cuota1:'',
            cuota2:'',
            cuota3:'',
            Cliente:[]            

        }

       
        
        this.updateUser = this.updateUser.bind(this);
    }

    componentDidMount(){
        UserService.getUserByi(this.state.id).then( (res) =>{
            this.setState(res.data);
            
            //Validacion bloqueo de campos cuotas
            let valor = document.getElementById("cuota1").value
            let valor1 = document.getElementById("cuota2").value
            let valor2 = document.getElementById("cuota3").value

            if(valor1=="Pagada") {
                document.getElementById("cuota2").disabled= true
            }
            if(valor=="Pagada"){
                document.getElementById("cuota1").disabled= true
            }
            if(valor2=="Pagada"){
                document.getElementById("cuota3").disabled= true
            }

            //Validacion bloque estado

            let valorcuota=document.getElementById("valorc").value
            
            console.log(valorcuota);
            console.log(valor1)
            console.log(valor2)
            console.log(valor)

            switch (valorcuota) {
                case "1":
                  if (valor=="Pendiente"&&valor1=="No Aplica"&&valor2=="No Aplica") {
                    document.getElementById("estado_desembolso").disabled=true;
                    
                  }
                  else{
                    document.getElementById("estado_desembolso").disabled=false;
                  }
                  break;
                case "2":
                    if (valor=="Pendiente"&&valor1=="Pendiente"&&valor2=="No Aplica") {
                        document.getElementById("estado_desembolso").disabled=true;
                        document.getElementById("cuota2").disabled=true;
                      }
                      else if(valor=="Pagada"&&valor1=="Pendiente"&&valor2=="No Aplica"){
                        document.getElementById("estado_desembolso").disabled=true;
                      }
                      else{
                        document.getElementById("estado_desembolso").disabled=false;
                        document.getElementById("cuota2").disabled=false;
                      }
                  break;
                case "3":
                    if (valor=="Pendiente"&&valor1=="Pendiente"&&valor2=="Pendiente") {
                        document.getElementById("estado_desembolso").disabled=true;
                        document.getElementById("cuota2").disabled=true;
                        document.getElementById("cuota3").disabled=true;
                      }
                      else if(valor=="Pagada"&&valor1=="Pendiente"&&valor2=="Pendiente"){
                        document.getElementById("estado_desembolso").disabled=true;
                        document.getElementById("cuota3").disabled=true;
                      }
                      else if(valor=="Pagada"&&valor1=="Pagada"&&valor2=="Pendiente"){
                        document.getElementById("estado_desembolso").disabled=true;
                        
                      }
                      else{
                        document.getElementById("estado_desembolso").disabled=false;
                      }
                  break;
                default:
                  console.log("No tengo mascota");
                  break;
              }

        });
    }

    updateUser = (e) => {
e.preventDefault();
        
        let concat =document.getElementById("estado_desembolso").value;
        let concat1 =document.getElementById("cuota1").value;
        let concat2 =document.getElementById("cuota2").value;
        let concat3 =document.getElementById("cuota3").value;
        //Validacion bloqueo de campos cuotas

        let valorcuota=document.getElementById("valorc").value
        

        switch (valorcuota) {
            case "1":
              if (concat1=="Pendiente"&&concat2=="No Aplica"&&concat3=="No Aplica") {
                document.getElementById("estado_desembolso").disabled=true;
              }
              else{
               concat="Finalizado"
              }
              break;
            case "2":
                if (concat1=="Pendiente"&&concat2=="Pendiente"&&concat3=="No Aplica") {
                    document.getElementById("estado_desembolso").disabled=true;
                  }
                  else if(concat1=="Pagada"&&concat2=="Pendiente"&&concat3=="No Aplica"){
                    document.getElementById("estado_desembolso").disabled=true;
                  }
                  else{
                   concat="Finalizado"
                  }
              break;
            case "3":
                if (concat1=="Pendiente"&&concat2=="Pendiente"&&concat3=="Pendiente") {
                    document.getElementById("estado_desembolso").disabled=true;
                    
                  }
                  else if(concat1=="Pagada"&&concat2=="Pendiente"&&concat3=="Pendiente"){
                    document.getElementById("estado_desembolso").disabled=true;
                  }
                  else if(concat1=="Pagada"&&concat2=="Pagada"&&concat3=="Pendiente"){
                    document.getElementById("estado_desembolso").disabled=true;
                  }
                  else{
                   concat="Finalizado"
                  }
              break;
            default:
              console.log("No tengo mascota");
              break;
          }
          

          

         

        console.log(concat)
        let user = {estado_desembolso:concat,cuota1:concat1,cuota2:concat2,cuota3:concat3};
        console.log('user => ' + JSON.stringify(user));
        console.log('_id => ' + JSON.stringify(this.state.id));
        UserService.updateUserId(user, this.state.id).then( res => {
            window.location='/CreditosDesembolsados';

             
        });
    }
    
  

    

    render() {
        return (
            <div>
            <Header/>
                <Menu/>
                <br></br>
                
                <div className="content-wrapper">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                <h3 className="text-center1"> Actualizar control de pagos</h3>
                                <div className = "card-body">
                                {
                                        this.state.Cliente.map(
                                        cliente =>
                                    <form key = {cliente.numero_documento}>
                                    <div className = "form-group" >
                                    <label>ID</label>
                                    <input className='form-control' disabled value={cliente._id}/>
                                    <label>Nombres</label>
                                    <input className='form-control' disabled value={cliente.nombres}/>
                                    <label>Apellidos</label>
                                    <input className='form-control' disabled value={cliente.apellidos}/>
                                    <label>Número de Documento</label>
                                    <input className='form-control' disabled value={cliente.numero_documento}/>
                                    <label>Empresa</label>
                                    <input className='form-control' disabled value={cliente.empresa}/>
                                    <label>Valor Prestamo</label>
                                    <input className='form-control' disabled value={"$"+new Intl.NumberFormat().format(cliente.monto_prestamo)}/>
                                    <label>Número de cuotas</label>
                                    <input id ="valorc" className='form-control' disabled value={cliente.cuotas}/>
                                    <label>Valor Cuota</label>
                                    <input  className='form-control' disabled value={"$"+new Intl.NumberFormat().format(cliente.monto_cuotas)}/>
                                    <label>Estado Prestamo</label>
                                        <select id="estado_desembolso" className="form-control"
                                        placeholder={cliente.estado_desembolso} >
                                            <option value="Aprobado">Aprobado</option>
                                            <option value="Finalizado">Finalizado</option>
                                        </select>
                                        <label >Cuota 1</label>
                                        
                                        <select id="cuota1"  className="form-control" >
                                        {cliente.cuota1=="Pendiente"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                            {cliente.cuota1=="Pagada"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                        </select>

                                        {cliente.cuotas==1?<label style={{display :`none`}}>Cuota 2</label>:<label>Cuota 2</label>}

                                        {cliente.cuotas==1?<select id="cuota2"  className="form-control"
                                        placeholder={cliente.cuota2} style={{display :`none`}} >        
                                        <option value="No Aplica">No Aplica</option>                                   
                                        </select>:<select id="cuota2"  className="form-control"
                                        placeholder={cliente.cuota2} >                                           
                                            {cliente.cuota2=="Pendiente"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                            {cliente.cuota2=="Pagada"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                        </select>}
                                        
                                        {cliente.cuotas!=3 ?<label style={{display :`none`}}>Cuota 3</label>:<label>Cuota 3</label>}
                                        
                                        {cliente.cuotas!=3 ?<select id="cuota3"  className="form-control"
                                        placeholder={cliente.cuota3} style={{display :`none`}}>                                           
                                            <option value="No Aplica">No Aplica</option>
                                        </select>:<select id="cuota3"  className="form-control"
                                        placeholder={cliente.cuota3} >                                           
                                            {cliente.cuota3=="Pendiente"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                            {cliente.cuota3=="Pagada"?<option value="Pendiente">Pendiente</option>:<option value="Pagada">Pagada</option>}
                                        </select>}
                                        </div>
                                        
                                        <button className="btn btn-success" onClick={(e)=>{this.updateUser(e);this.validacionS(e)}}>Guardar</button>
                                        <button className="btn btn-danger" data-dismiss="modal" style={{marginLeft: "10px"}}>Cancelar</button>
                                      
                                    </form>
                                    
                                    )
                                    }
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default UpdateUserComponent2