import { FaReadme} from "react-icons/fa";
import React, { useState, useEffect } from "react";
import TablaActivos from "./TablaActivos"
import TablaLiquidados from "./TablaLiquidados";


const TablaDeudas = ({
  resultadoFiltrado,
  empresa,
  mesesDeudaActivo,
  mesesDeudaLiquidado,
  totalDeudaActivo,
  totalDeudaLiquidado,
  totalPagado,
  totalLiquidado,
  totalPrestamosActivos,
  totalPrestamosLiquidados,
  idsPrestamosActivos,
  idsPrestamosLiquidados
}) => {
  const [data, setData] = useState([]);
  const [dataActivos, setDataActivos] = useState([]);
  const [dataLiquidados, setDataLiquidados] = useState([]);

  useEffect(() => {
      if (resultadoFiltrado) {
        setData(resultadoFiltrado);
      }
      setDataActivos([])
      
  }, [resultadoFiltrado]);

  const handleClickActivos = (event) => {
    setDataLiquidados([])
    const ids = event.currentTarget.dataset.ids;
    if (ids) {
      const idsArray = ids.split(",").map((id) => parseInt(id));
      const filteredData = data.filter((item) => idsArray.includes(item._id));
      setDataActivos(filteredData);
    }
    

  };
  
  const handleClickLiquidados = (event) => {
    setDataActivos([])
    const ids = event.currentTarget.dataset.ids;
    if (ids) {
      const idsArray = ids.split(",").map((id) => parseInt(id));
      const filteredData = data.filter((item) => idsArray.includes(item._id))
      setDataLiquidados(filteredData)
    }
  };

  // Obtener todos los meses únicos y ordenarlos de forma ascendente
  const mesesUnicos = [
    ...new Set([...mesesDeudaActivo.flat(), ...mesesDeudaLiquidado.flat()]),
  ];
  // const mesesOrdenados = mesesUnicos.sort((a, b) => new Date(a) - new Date(b));

  const mesesOrdenados = mesesUnicos.sort((a, b) => {
    const meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre"
    ];
    const indexA = meses.indexOf(a.toLowerCase());
    const indexB = meses.indexOf(b.toLowerCase());
    return indexA - indexB;
  });


  // Generar filas únicas de la tabla
  const filas = mesesOrdenados.map((mes) => {
    const indicesActivo = mesesDeudaActivo.flatMap((meses, index) =>
      meses.includes(mes) ? index : []
    );
    const indicesLiquidado = mesesDeudaLiquidado.flatMap((meses, index) =>
      meses.includes(mes) ? index : []
    );

    const filasActivo = indicesActivo.map((indice) => (
      <tr key={`deuda-activo-${mes}-${indice}`}>
        <td>{empresa}</td>
        <td>{mes}</td>
        <td>Activo</td>
        <td>${new Intl.NumberFormat().format(totalDeudaActivo[indice])}</td>
        <td>${new Intl.NumberFormat().format(totalPagado[indice])}</td>
        <td>{idsPrestamosActivos[indice].length}</td>
        <td>
          <button
            style={{ marginLeft: "10px" }}
            className="btn btn-success"
            data-ids={idsPrestamosActivos[indice].join(",")}
            onClick={handleClickActivos}
          >
            <FaReadme />
          </button>
        </td>
      </tr>
    ));

    const filasLiquidado = indicesLiquidado.map((indice) => (
      <tr key={`deuda-liquidado-${mes}-${indice}`}>
        <td>{empresa}</td>
        <td>{mes}</td>
        <td>Liquidado</td>
        <td>${new Intl.NumberFormat().format(totalDeudaLiquidado[indice])}</td>
        <td>${new Intl.NumberFormat().format(totalLiquidado[indice])}</td>
        <td>{idsPrestamosLiquidados[indice].length}</td>
        <td>
          <button
            style={{ marginLeft: "10px" }}
            className="btn btn-success"
            data-ids={idsPrestamosLiquidados[indice].join(",")}
            onClick={handleClickLiquidados}
          >
            <FaReadme />
          </button>
        </td>
      </tr>
    ));

    return [filasActivo, filasLiquidado];
  });

  const rExcel = () => {
    let XLSX = require("xlsx");
    // Obtener los datos de la tabla HTML
    const table = document.getElementById("tablaGeneral");
    // Convertir la tabla HTML a una matriz de datos, omitiendo la última columna
    const data = Array.from(table.rows).map((row) =>
      Array.from(row.cells).slice(0, -1).map((cell) => cell.innerText)
    );
    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Añadir la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Descargar el archivo de Excel
    XLSX.writeFile(wb, "tablaGeneralPagosEmpresas.xlsx");
  };

  

  // Renderizar la tabla
  return (
    <div>
       {mesesOrdenados.length === 0 ? (
        <p></p>
      ) : (
        <div className="reportes">
            <button
              className="btn btn-success"
              onClick={rExcel}
            >
              Descargar
            </button>
          </div>
      )}


      {mesesOrdenados.length === 0 ? (
        <p >No se encontraron datos</p>
      ) : (
        <table className="table table-striped table-bordered mb-5" id="tablaGeneral">
          <thead>
            <tr className="titulos-tabla4">
              <th>Empresa</th>
              <th>Mes</th>
              <th>Tipo de Deuda</th>
              <th>Total de Deuda</th>
              <th>Pago Realizado</th>
              <th>Número de préstamos</th>
              <th>Deatlles de Préstamos</th>
            </tr>
          </thead>
          <tbody>{filas.flat().map((fila) => fila)}</tbody>
        </table>
      )}
      {dataActivos.length > 0 && (
        <div>
         <TablaActivos dataActivos={dataActivos} />
        </div>
      )}

      {dataLiquidados.length > 0 && (
       <div>
       <TablaLiquidados dataLiquidados={dataLiquidados} />
      </div>
      )}
    </div>
  );
};
  
  export default TablaDeudas;