//VISTA GENERAL
import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import UserService from "../../services/UserService";
import ReporteNomina from "./ReporteNomina"
import { MdDeleteForever } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { BsFillSkipBackwardFill } from "react-icons/bs";
import { BsFillSkipForwardFill } from "react-icons/bs";
import { BsFillCaretRightFill } from "react-icons/bs";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import "../../css/ListUserComponent4.css";
import writeXlsxFile from "write-excel-file";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx/xlsx.mjs";

class VistaGeneral extends Component {
  constructor(props) {
    let date = new Date();
    super(props);
    this.dataLocal = JSON.parse(localStorage.getItem("email"));
    this.state = {
      interes: 0.0221,
      tarifa_administrativa: 10000,
      consulta_perfil: 3000,
      Clientes: [],
      clientesTabla: [],
      paginacion: -1,
      pageNow: 1,
      filtroNombre: "",
      filtroDocumento: "",
      filtroEmpresa: "",
      tipo: "",
      numdoc: "",
      nom: "",
      ape: "",
      fecha: "",
      celu: "",
      empre: "",
      nit: "",
      naci: "",
      numbille: "",
      contra: "",
      easy: "",
      habeas: "",
      nomi: "",
      cuotas: "",
      valpre: "",
      valcou: "",
      valtotal: "",
      correo: "",
      ingre: "",
      bille: "",
      Cliente: [],
      numero_documento: this.dataLocal,
      Cliente_edit: false,
      id: "",
      // modalEditar
      fecha_nacimiento: "",
      correo: "",
      celular: "",
      numero_billetera: "",
      metodo_desembolso: "",
      valor_deuda: "",
      monto_prestamo: "",
      monto_cuotas: "",
      ingresos: "",
      id_usuario: "",
      fecha_solicitud: "",
      estado_desembolso: "",
      estado_prestamo: "",
      cuota1: "",
      cuota2: "",
      cuota3: "",
      fecha_desembolso: "",
      fecha_cuota1: "",
      fecha_cuota2: "",
      fecha_cuota3: "",
      fecha_finalizado: "",
      fecha_actual: date,
    };
    this.saldo = 0;
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  // console.log("excel")
  //   let dataexcel= JSON.stringify(this.state.Clientes)

  //   var FileSaver = require('file-saver');
  //   var blob = new Blob(this.state.Clientes, {type: ".xlsx"});
  //   FileSaver.saveAs(blob, "hello world.xlsx");

  crearDatosParaExcel(datos) {
    //Nueva funcion para recopilar los datos del excel general
    console.log(datos);
    let datosParaExcel = [
      [
        "ID",
        "Nombres",
        "Apellidos",
        "Tipo de documento",
        "Número de documento",
        "Fecha de nacimiento",
        "Correo electrónico",
        "Celular",
        "Empresa",
        "NIT",
        "Perfil: Género",
        "Perfil: Estado civil",
        "Perfil: Tipo de vivienda",
        "Perfil: Nivel de escolaridad",
        "Perfil: Estrato",
        "Perfil: Número de personas a cargo",
        "Perfil: Antigüedad en la empresa",
        "Ingresos",
        "Uso del dinero",
        "Fecha de solicitud",
        "¿Habeas data?",
        "Firma de contrato",
        "Descuento de nómina",
        "EasyBio",
        "Número de billetera",
        "Método de desembolso",
        "Estado del préstamo",
        "Estado de desembolso",
        "Fecha de desembolso",
        "Conteo de días",
        "Monto de préstamo",
        "Interés E.D.",
        "Tarifa administrativa",
        "Consulta de perfil",
        "Valor Intereses",
        "Número de cuotas",
        "Valor de la cuota",
        "Valor de deuda",
        "Fecha estimada de cuota 1",
        "Fecha estimada de cuota 2",
        "Fecha estimada de cuota 3",
        "Cuota 1",
        "Cuota 2",
        "Cuota 3",
        "Fecha de cuota 1",
        "Fecha de cuota 2",
        "Fecha de cuota 3",
        "Fecha de finalizado",
        "Fecha de liquidación",
        "Días a liquidar",
        "Interés E.A.",
        "Interés E.M.",
        "Interés Causado",
        "Valor causado",
        "Valor normalizado",
        "Difrencia Pendiente a Aprobado",
        "Diferencia Aprobado a Desembolsado?",
        "Fecha Aprobado"
      ],
    ];
    for (let i = 0; i < datos.length; i++) {
      let fila = [
        datos[i]._id,
        datos[i].nombres,
        datos[i].apellidos,
        "Cédula de Ciudadanía",
        datos[i].numero_documento,
        new Date(datos[i].fecha_nacimiento).toLocaleDateString("es-ES", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
        datos[i].correo,
        datos[i].celular,
        datos[i].empresa,
        datos[i].nit,
        datos[i].pr_genero,
        datos[i].pr_estado_civil,
        datos[i].pr_tipo_vivienda,
        datos[i].pr_nivel_escolaridad,
        datos[i].pr_estrato,
        datos[i].pr_personas_cargo,
        datos[i].pr_antiguedad_empresa,
        datos[i].ingresos,
        datos[i].uso_dinero,
        datos[i].fecha_solicitud,
        datos[i].habeas_data ? "Sí" : "No", // ???
        datos[i].firma_contrato,
        datos[i].descuento_nomina ? "Sí" : "No", // ???
        datos[i].easybio ? "Sí" : "No", // ???
        datos[i].numero_billetera ? datos[i].numero_billetera :datos[i].celular,
        datos[i].metodo_desembolso,
        datos[i].estado_prestamo,
        datos[i].estado_desembolso,
        datos[i].fecha_desembolso,
        datos[i].conteo_dias,
        datos[i].monto_prestamo,
        datos[i].tasa_interes,
        datos[i].tarifa_administrativa,
        datos[i].consulta_perfil,
        datos[i].valor_interes,
        datos[i].cuotas,
        datos[i].monto_cuotas,
        datos[i].valor_deuda,
        datos[i].fecha_cuota,
        datos[i].fechaestimada_cuota2,
        datos[i].fechaestimada_cuota3,
        datos[i].cuota1,
        datos[i].cuota2,
        datos[i].cuota3,
        datos[i].fecha_cuota1,
        datos[i].fecha_cuota2,
        datos[i].fecha_cuota3,
        datos[i].fecha_finalizado,
        datos[i].fecha_liquidacion,
        datos[i].dias_liquidar,
        datos[i].tasa_interes_ea,
        datos[i].tasa_interes_em,
        datos[i].interes_causado,
        datos[i].valor_causado,
        datos[i].valor_normalizado,
        datos[i].diferencia_horas_pa ? datos[i].diferencia_horas_pa: null,
        datos[i].diferencia_horas_ad ? datos[i].diferencia_horas_ad: null,
        datos[i].fecha_aprobado ? datos[i].fecha_aprobado: null,
      ];
      datosParaExcel.push(fila);
    }
    return datosParaExcel;
  }


  importexcel = () => {
    let XLSX = require("xlsx");

    let datosParaExcel = this.crearDatosParaExcel(this.state.Clientes);
    let hojaDeCalculo = XLSX.utils.aoa_to_sheet(datosParaExcel);
    let libroDeExcel = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(libroDeExcel, hojaDeCalculo, "Datos");
    XLSX.writeFile(libroDeExcel, "datos.xlsx");
  };

  rTesoretiaExcel = () => {
    let XLSX = require("xlsx");
    // Obtener los datos de la tabla HTML
    const table = document.getElementById("tablaexceltesoreria");
    // Convertir la tabla HTML a una matriz de datos
    const data = Array.from(table.rows).map((row) =>
      Array.from(row.cells).map((cell) => cell.innerText)
    );
    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Obtener las celdas de la columna F
    const columnI = Object.keys(ws).filter((cell) => cell.startsWith("I"));
    columnI.forEach((cell) => {
      if (ws[cell]) {
        ws[cell].numFmt = "d-mm-yyyy h:mm:ss";
        ws[cell].numFmtId = 166;
      }
    });
    // Añadir la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Descargar el archivo de Excel
    XLSX.writeFile(wb, "ReporteTesoreria.xlsx");
  };

  importLibranza = () => {
    const XLSX = require("xlsx");

    // Obtener los datos de la tabla HTML
    const table = document.getElementById("reportelibranza");

    // Convertir la tabla HTML a una matriz de datos
    const data = Array.from(table.rows).map((row) =>
      Array.from(row.cells).map((cell) => cell.innerText)
    );

    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Obtener las celdas de la columna F
    const columnF = Object.keys(ws).filter((cell) => cell.startsWith("F"));

    columnF.forEach((cell) => {
      if (ws[cell]) {
        ws[cell].numFmt = "d-mm-yyyy h:mm:ss";
        ws[cell].numFmtId = 166;
      }
    });

    // Añadir la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Descargar el archivo de Excel
    XLSX.writeFile(wb, "ReporteLibranza.xlsx");
  };

  readreporteTesoreria = (event) => {
    try {
      const archivo = document.getElementById("myFileInput").files[0];
      const lector = new FileReader();
      const novedad = "NOVEDAD CARGADO AL SISTEMA DE NOMINA";
      let errorEncontrado = false;

      lector.onload = function (event) {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheetData = XLSX.utils.sheet_to_json(
            workbook.Sheets[sheetName]
          );
          const json = JSON.stringify(sheetData);
          const data1 = JSON.parse(json);
          const users_id = data1.map((obj) => obj.CREDITO.replace("#", ""));
          const nuevosObjetos = data1.map((obj) => {
            const observacion = obj["NOVEDAD CARGADO AL SISTEMA DE NOMINA"];
            if (
              (observacion && observacion.toUpperCase() === "SI") ||
              observacion.toUpperCase() === "NO"
            ) {
              return {
                novedad:
                  obj["NOVEDAD CARGADO AL SISTEMA DE NOMINA"].toUpperCase(),
                observacion: obj.OBSERVACIONES,
              };
            } else {
              document.getElementById("errorCargaExcel").style.display =
                "block";
                errorEncontrado = true;
            }
          });
          console.log(nuevosObjetos.length);
          console.log(users_id.length);
          if (errorEncontrado) {
            document.getElementById("errorCargaExcel").style.display = "block";
            return;
          }

          for (let index = 0; index < nuevosObjetos.length; index++) {
            const user_id = users_id[index];
            const element = nuevosObjetos[index];
            UserService.updateUserId(element, user_id).then((res) => {});
          }
          document.getElementById("successCargaExcel").style.display = "block";
        } catch (error) {
          document.getElementById("errorCargaExcel").style.display = "block";
        }
      };
      lector.readAsArrayBuffer(archivo);
    } catch (error) {
      document.getElementById("errorCargaExcel").style.display = "block";
    }
  };

  

  reload = () => {
    window.location = "/";
  };

  valuestate = async (target) => {
    if (target.id === "numdoc") {
      this.state.numdoc = target.value;
    }
    if (target.id === "naci") {
      this.state.naci = target.value;
    }
    if (target.id === "celu") {
      this.state.celu = target.value;
    }
    if (target.id === "ingre") {
      this.state.ingre = target.value;
    }
    if (target.id === "bille") {
      this.state.bille = target.value;
    }
    if (target.id === "correo") {
      this.state.correo = target.value;
    }
    if (target.id === "valpre") {
      this.state.valpre = target.value;
    }
    if (target.id === "cuotas") {
      this.state.cuotas = target.value;
    }
    if (target.id === "valcuo") {
      this.state.valcuo = target.value;
    }
    if (target.id === "valtotal") {
      this.state.valtotal = target.value;
    }
    if (target.id === "numbille") {
      this.state.numbille = target.value;
    }
    if (target.id === "fecha") {
      this.state.fecha = target.value;
    }
    if (target.id === "contra") {
      this.state.contra = target.value;
    }
    if (target.id === "habeas") {
      this.state.habeas = target.value;
    }
    if (target.id === "easy") {
      this.state.easy = target.value;
    }
    if (target.id === "nomi") {
      this.state.nomi = target.value;
    }

    this.enviarDataImput();

    let inputcedula = document.getElementById("numdoc");
    inputcedula.addEventListener("input", function () {
      if (this.value.length > 11) this.value = this.value.slice(0, 11);
    });

    let inputcelular = document.getElementById("celu");
    inputcelular.addEventListener("input", function () {
      if (this.value.length > 10) this.value = this.value.slice(0, 10);
    });
  };

  updateUser = (e) => {
    e.preventDefault();

    let correo1 = document.getElementById("correo-1").value;
    let celular1 = document.getElementById("celular-1").value;
    let numero_billetera1 = document.getElementById("numero_billetera").value;
    let metodo_desembolso1 = document.getElementById("metodo_desembolso").value;
    let valor_deuda1 = document.getElementById("valor_deuda").value;
    let monto_cuotas1 = document.getElementById("monto_cuotas").value;
    let monto_prestamo1 = parseInt(
      document.getElementById("monto_prestamo").value
    );
    let cuotas_1 = document.getElementById("cuotas-1").value;
    let ingresos1 = document.getElementById("ingresos-1").value;
    let fecha_solicitud1 = document.getElementById("fecha_solicitud").value;
    let estado_desembolso1 = document.getElementById("estado_desembolso").value;
    let estado_prestamo1 = document.getElementById("estado_prestamo").value;
    let cuotauno = document.getElementById("cuota1").value;
    let cuotados = document.getElementById("cuota2").value;
    let cuotatres = document.getElementById("cuota3").value;

    estado_prestamo1 = "Rechazado"
      ? (estado_desembolso1 = "Rechazado")
      : estado_desembolso1;

    let user = {
      celular:
        celular1 === "" ? this.state.Cliente_edit.celular : parseInt(celular1),
      correo: correo1 === "" ? this.state.Cliente_edit.correo : correo1,
      numero_billetera:
        numero_billetera1 === ""
          ? this.state.Cliente_edit.numero_billetera
          : parseInt(numero_billetera1),
      metodo_desembolso:
        metodo_desembolso1 === ""
          ? this.state.Cliente_edit.metodo_desembolso
          : metodo_desembolso1,
      valor_deuda:
        valor_deuda1 === ""
          ? this.state.Cliente_edit.valor_deuda
          : parseInt(valor_deuda1),
      monto_cuotas:
        monto_cuotas1 === ""
          ? this.state.Cliente_edit.monto_cuotas
          : parseInt(monto_cuotas1),
      monto_prestamo:
        monto_prestamo1 === ""
          ? this.state.Cliente_edit.monto_prestamo
          : parseInt(monto_prestamo1),
      cuotas:
        cuotas_1 === "" ? this.state.Cliente_edit.cuotas : parseInt(cuotas_1),
      ingresos: ingresos1 === "" ? this.state.Cliente_edit.ingresos : ingresos1,
      fecha_solicitud:
        fecha_solicitud1 === ""
          ? this.state.Cliente_edit.fecha_solicitud
          : fecha_solicitud1,
      estado_desembolso:
        estado_desembolso1 === ""
          ? this.state.Cliente_edit.estado_desembolso
          : estado_desembolso1,
      estado_prestamo:
        estado_prestamo1 === ""
          ? this.state.Cliente_edit.estado_prestamo
          : estado_prestamo1,
      cuota1: cuotauno === "" ? this.state.Cliente_edit.cuota1 : cuotauno,
      cuota2: cuotados === "" ? this.state.Cliente_edit.cuota2 : cuotados,
      cuota3: cuotatres === "" ? this.state.Cliente_edit.cuota3 : cuotatres,
    };

    console.log("user => " + JSON.stringify(user));
    console.log("_id => " + JSON.stringify(this.state.id));
    UserService.updateUserId(user, this.state.id).then((res) => {
      window.location = "/";
    });
  };

  update_id(nid) {
    this.setState({ id: nid });
  }

  validacionFecha = (event) => {
    let date = new Date();

    const anoActual = parseInt(date.getFullYear());
    const mesActual = parseInt(date.getMonth() + 1);
    const diaActual = parseInt(date.getDate());

    const anoNacimiento = parseInt(String(this.state.naci).substring(0, 4));
    const mesNacimiento = parseInt(String(this.state.naci).substring(5, 7));
    const diaNacimiento = parseInt(String(this.state.naci).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
      edad--;
    } else if (mesActual === mesNacimiento) {
      if (diaActual < diaNacimiento) {
        edad--;
      }
    }

    if (edad < 18) {
      document.getElementById("envio").style.display = "none";
      document.getElementById("fechalert").style.display = "block";
    } else {
      document.getElementById("envio").style.display = "";
    }
  };

  validacionBD = async () => {
    let elem = (document.getElementById("numdoc") || {}).value || "";
    let res2 = await UserService.getUserById(elem);

    console.log(res2);

    try {
      let validacion = res2["data"]["Cliente"]["0"]["estado_prestamo"];
      if (validacion !== "Finalizado") {
        document.getElementById("creditos").style.display = "block";
      } else {
        document.getElementById("creditos").style.display = "";
      }
    } catch (error) {
      console.error(error);
    }
  };

  camposDB = async () => {
    let elem = (document.getElementById("numdoc") || {}).value || "";

    let res = await UserService.getEmployeById(elem);
    console.log(res);

    let valpre = parseInt(document.getElementById("valpre").value);
    let cuotas = parseInt(document.getElementById("cuotas").value);
    let interes = (valpre * 2.21 * cuotas) / 100;
    let valortotal = valpre + interes + 13000;
    let valorcuota = valortotal / cuotas;

    document.getElementById("valcuo").value = parseInt(valorcuota);
    document.getElementById("valtotal").value = parseInt(valortotal);

    this.state.valcuo = document.getElementById("valcuo").value =
      parseInt(valorcuota);
    this.state.valtotal = document.getElementById("valtotal").value =
      parseInt(valortotal);

    console.log(valortotal);

    document.getElementById("valtotal").value = valortotal;

    if (res["data"].length > 0) {
      this.state.nom = document.getElementById("nom").value =
        res["data"][0]["nombres"];
      this.state.tipo = document.getElementById("tipo").value =
        res["data"][0]["tipo_documento"];
      this.state.ape = document.getElementById("ape").value =
        res["data"][0]["apellidos"];
      this.state.empre = document.getElementById("empre").value =
        res["data"][0]["empresa"];
      this.state.nit = document.getElementById("nit").value =
        res["data"][0]["nit"];

      document.getElementById("envio").style.display = "";
    } else {
      document.getElementById("envio").style.display = "none";
      document.getElementById("alerta").style.display = "block";

      this.state.numdoc = document.getElementById("numdoc").value = "";
      this.state.nom = document.getElementById("nom").value = "";
      this.state.ape = document.getElementById("ape").value = "";
      this.state.empre = document.getElementById("empre").value = "";
      this.state.tipo = document.getElementById("tipo").value = "";
      this.state.bille = document.getElementById("bille").value = "";
      this.state.ingre = document.getElementById("ingre").value = "";
      this.state.celu = document.getElementById("celu").value = "";
      this.state.fecna = document.getElementById("fecna").value = "";
      this.state.correo = document.getElementById("correo").value = "";
      this.state.numbille = document.getElementById("numbille").value = "";
      this.state.contra = document.getElementById("contra").value = "";
      this.state.habeas = document.getElementById("habeas").value = "";
      this.state.easy = document.getElementById("easy").value = "";
      this.state.nomi = document.getElementById("nomi").value = "";
      this.state.valcuo = document.getElementById("valcuo").value = "";
      this.state.valpre = document.getElementById("valpre").value = "";
      this.state.cuotas = document.getElementById("cuotas").value = "";
      this.state.valtotal = document.getElementById("valtotal").value = "";
      this.state.naci = document.getElementById("naci").value = "";
      this.state.ingre = document.getElementById("ingre").value = "";
    }
  };

  validacioncampos = async () => {
    let tipo = document.getElementById("tipo");
    let numdoc = document.getElementById("numdoc");
    let naci = document.getElementById("naci");
    let ingre = document.getElementById("ingre");
    let bille = document.getElementById("bille");
    let celular = document.getElementById("celu");
    let correo = document.getElementById("correo");
    let habeas = document.getElementById("habeas");
    let date = new Date();
    let fecna = document.getElementById("fecna");
    let numbille = document.getElementById("numbille");
    let contra = document.getElementById("contra");
    let easy = document.getElementById("easy");
    let nomi = document.getElementById("nomi");
    let cuotas = document.getElementById("cuotas");

    let elem = document.getElementById("numdoc").value;
    let respuesta = await UserService.getEmployeById(elem);
    let capacidad = respuesta["data"][0]["capacidad_endeudamiento"];
    let valpre = this.state["valpre"];
    console.log("CAPACIDAD");
    console.log(capacidad);
    console.log(valpre);

    const anoActual = parseInt(date.getFullYear());
    const mesActual = parseInt(date.getMonth() + 1);
    const diaActual = parseInt(date.getDate());

    const anoNacimiento = parseInt(String(this.state.naci).substring(0, 4));
    const mesNacimiento = parseInt(String(this.state.naci).substring(5, 7));
    const diaNacimiento = parseInt(String(this.state.naci).substring(8, 10));

    let edad = anoActual - anoNacimiento;
    if (mesActual < mesNacimiento) {
      edad--;
    } else if (mesActual === mesNacimiento) {
      if (diaActual < diaNacimiento) {
        edad--;
      }
    }

    if (numdoc.value === "" || numdoc.value == null) {
      alert("*Campo número de docúmento obligatorio*");
      document.getElementById("numdoc").style.borderColor = "red";
    } else if (tipo.value === "" || tipo.value == null) {
      alert("*Campo tipo de docúmento obligatorio*");
      document.getElementById("tipo").style.borderColor = "red";
    } else if (valpre > capacidad) {
      document.getElementById("capacidad").style.display = "block";
    } else if (naci.value === "" || naci.value == null) {
      alert("*Campo fecha de nacimiento obligatorio*");
      document.getElementById("naci").style.borderColor = "red";
    } else if (edad < 18) {
      document.getElementById("envio").style.display = "none";
      document.getElementById("fechalert").style.display = "block";
      document.getElementById("naci").style.borderColor = "red";
    } else if (ingre.value === "" || ingre.value == null) {
      alert("*Campo de ingresos obligatorio*");
      document.getElementById("ingre").style.borderColor = "red";
    } else if (correo.value === "" || correo.value == null) {
      alert("*Campo correo electrónico obligatorio*");
    } else if (bille.value === "" || bille.value == null) {
      alert("*Campo billetera obligatorio*");
      document.getElementById("bille").style.borderColor = "red";
    } else if (celular.value === "" || celular.value == null) {
      alert("*Campo número de telefono obligatorio*");
      document.getElementById("celu").style.borderColor = "red";
    } else if (fecna.value === "" || fecna.value == null) {
      alert("*Campo fecha de prestamo obligatorio*");
      document.getElementById("fecha").style.borderColor = "red";
    } else if (numbille.value === "" || numbille.value == null) {
      alert("*Campo numero de billetera obligatorio*");
      document.getElementById("numbille").style.borderColor = "red";
    } else if (cuotas.value === "" || cuotas.value == null) {
      alert("*Campo numero de cuotas obligatorio*");
      document.getElementById("cuotas").style.borderColor = "red";
    } else if (habeas === false) {
      alert("*Campo habeasData debe ser SI obligatoriamente*");
      document.getElementById("habeas").style.borderColor = "red";
    } else if (contra === false) {
      alert("*Campo contrato debe ser SI obligatoriamente*");
      document.getElementById("contra").style.borderColor = "red";
    } else if (easy === false) {
      alert("*Campo EasyByo debe ser SI obligatoriamente*");
      document.getElementById("easy").style.borderColor = "red";
    } else if (nomi === false) {
      alert("*Campo descuento por nomina debe ser SI obligatoriamente*");
      document.getElementById("nomi").style.borderColor = "red";
    } else {
      window.location = "/VistaGeneral";
    }
  };

  deleteUser() {
    UserService.deleteUser(this.state.id).then((res) => {
      this.setState(res.data);
      window.location = "/VistaGeneral";
    });
  }

  viewUser(numero_documento) {
    this.props.history.push(`/view-user/${numero_documento}`);
  }

  editUser(objectCliente) {
    this.setState({
      Cliente_edit: objectCliente,
    });
  }

  componentDidMount = async () => {
    UserService.getUsers().then((res) => {
      this.state.clientesTabla = res.data.Clientes;
      this.setState(res.data);
      console.log("Hola", this.state.clientesTabla);
    });

    //Validacion donde se oculta componentes de SuperAdmin

    let res2 = await axios.get(
      //PRE PRODUCCION
    //  `http://190.109.16.229:10260/api3/searchcliente/${this.dataLocal}`
      //PRODUCCION
      `https://apiusers.tubongo.com/api3/searchcliente/${this.dataLocal}`
    );
    let id_usuario = res2["data"]["Usuario"]["0"]["id_tipo_usuario"];
    this.setState({ id_usuario: id_usuario });
    if (id_usuario === 3) {
      document.getElementById("crear-user").style.display = "none";
      document.getElementById("delete-user").style.display = "none";
      document.getElementById("delete-user1").style.display = "none";
    } else if(id_usuario === 4 || id_usuario === 5) {
        document.getElementById("crear-user").style.display = "none";
        document.getElementById("delete-user").style.display = "none";
        document.getElementById("delete-user1").style.display = "none";
    }
  };

  addUser() {
    this.props.history.push("/add-user/_add");
  }

  despues = () => {
    if (
      this.state.clientesTabla.length >= this.state.paginacion + 4 &&
      this.state.paginacion + 4 < this.state.clientesTabla.length - 1
    ) {
      this.setState({ ...this.state, paginacion: this.state.paginacion + 4 });
    }
    // if(this.state.clientesTabla.length >= this.state.paginacion+4 && this.state.clientesTabla.length > 4)
    // {this.setState({...this.state, paginacion:this.state.paginacion +4})}
  };

  antes = () => {
    if (this.state.paginacion - 4 >= -1) {
      this.setState({ ...this.state, paginacion: this.state.paginacion - 4 });
    }
    if (this.state.paginacion - 4 <= 2) {
      this.setState({ ...this.state, paginacion: (this.state.paginacion = -1) });
    }
  };

  ultima = () => {
    if (
      this.state.clientesTabla.length >= this.state.paginacion &&
      this.state.clientesTabla.length > 8
    ) {
      this.setState({
        ...this.state,
        paginacion: this.state.clientesTabla.length - 4,
      });
    }
  };

  primera = () => {
    if (this.state.paginacion - 4 >= 0) {
      this.setState({ ...this.state, paginacion: (this.state.paginacion = -1) });
    }
  };

  empezar = () => {
    if (this.state.paginacion > -1) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };


  filtros = () => {
    
    let fecha_inicio = document.getElementById("fecha_inicio").value;
    let fecha_fin = document.getElementById("fecha_fin").value;
    let estado_desembolso = document.getElementById("filtro_estado_desembolso").value;
    let filtro_prestamo = document.getElementById("filtro_estado_prestamo").value;
    let fempre = document.getElementById("fempre").value;
    let cedula_filtro = document.getElementById("cedula_filtro").value;
    let numero_id_filtro = document.getElementById("numero_id_filtro").value;
    let fechaInicioObj = new Date(fecha_inicio.split("/").reverse().join("-"));
    let fechaFinObj = new Date(fecha_fin.split("/").reverse().join("-"));
    let clientesTemp = [];

    this.state.Clientes.forEach((e) => {
      let tempEmp = e.empresa + "";
      let tempDoc = e.numero_documento + "";
      let tempId = e._id + "";
      let temp_estado_prestamo = e.estado_prestamo;
      let temp_estado_desembolso = e.estado_desembolso;
      let fecha_solicitud = e.fecha_solicitud.substring(0, 10);
      let fechaObj = new Date(fecha_solicitud.split("/").reverse().join("-"));
      if(filtro_prestamo !== "Finalizado"){
        if(fecha_inicio !== "" && fecha_fin !==""){
          if (
            tempEmp.search(fempre.toUpperCase()) > -1 &&
            tempDoc.search(cedula_filtro.toUpperCase()) > -1 &&
            tempId.search(numero_id_filtro.toUpperCase()) > -1 &&
            temp_estado_prestamo.search(filtro_prestamo) > -1 &&
            temp_estado_desembolso.search(estado_desembolso) > -1 &&
            fechaObj >= fechaInicioObj && fechaObj <= fechaFinObj 
          ) {
            clientesTemp.push(e);
          }
        }
        else{
          if (
            tempEmp.search(fempre.toUpperCase()) > -1 &&
            tempDoc.search(cedula_filtro.toUpperCase()) > -1 &&
            tempId.search(numero_id_filtro.toUpperCase()) > -1 &&
            temp_estado_prestamo.search(filtro_prestamo) > -1 &&
            temp_estado_desembolso.search(estado_desembolso) > -1
          ) {
            clientesTemp.push(e);
          }
        }
      }
      else if( filtro_prestamo === "Finalizado"){
        if(fecha_inicio !== "" && fecha_fin !==""){
          if (
            tempEmp.search(fempre.toUpperCase()) > -1 &&
            tempDoc.search(cedula_filtro.toUpperCase()) > -1 &&
            tempId.search(numero_id_filtro.toUpperCase()) > -1 &&
            temp_estado_prestamo === filtro_prestamo &&
            temp_estado_desembolso.search(estado_desembolso) > -1 &&
            fechaObj >= fechaInicioObj && fechaObj <= fechaFinObj
          ) {
            clientesTemp.push(e);
          }
        }else{
          if (
            tempEmp.search(fempre.toUpperCase()) > -1 &&
            tempDoc.search(cedula_filtro.toUpperCase()) > -1 &&
            tempId.search(numero_id_filtro.toUpperCase()) > -1 &&
            temp_estado_prestamo === filtro_prestamo &&
            temp_estado_desembolso.search(estado_desembolso) > -1
          ) {
            clientesTemp.push(e);
          }

        }
        
      }
      
    });
    this.setState({
      clientesTabla: clientesTemp,
    });  
  }

  filtrarEmpre = () => {
    document.getElementById("fecha_inicio").value = "";
    document.getElementById("fecha_fin").value = "";
    document.getElementById("filtro_estado_desembolso").value = "";
    let clientesTemp = [];
    this.state.Clientes.forEach((e) => {
      // let tempName = e.nombres.toUpperCase() + '';
      let tempEmp = e.empresa + "";
      let tempDoc = e.numero_documento + "";
      if (
        tempEmp.search(this.state.filtroEmpresa.toUpperCase()) > -1 &&
        tempDoc.search(this.state.filtroDocumento.toUpperCase()) > -1
      ) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    setTimeout(() => {
      this.empezar();
    }, 1000);
  };

  filtroEstadoPrestamo = (estado) => {
    //Funcion para filtrar los datos de la tabla segun el estado del prestamo
    document.getElementById("fecha_inicio").value = "";
    document.getElementById("fecha_fin").value = "";
    document.getElementById("filtro_estado_desembolso").value = "";
    document.getElementById("fempre").value = "";
    document.getElementById("cedula_filtro").value = " ";
    let clientesTemp = [];
    this.state.Clientes.forEach((e) => {
      let estado_prestamo = e.estado_prestamo;
      if (estado_prestamo.search(estado) > -1) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    setTimeout(() => {
      this.empezar();
    }, 1000);
  };

  filtroEstadoDesembolso = (estado) => {
    //Funcion para filtrar los datos de la tabla segun el estado de desembolso
    document.getElementById("fecha_inicio").value = "";
    document.getElementById("fecha_fin").value = "";
    document.getElementById("filtro_estado_prestamo").value = "";
    document.getElementById("fempre").value = "";
    document.getElementById("cedula_filtro").value = " ";
    let clientesTemp = [];
    this.state.Clientes.forEach((e) => {
      let estado_desembolso = e.estado_desembolso;
      if (estado_desembolso.search(estado) > -1) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    setTimeout(() => {
      this.empezar();
    }, 1000);
  };

  filtroDia = () => {
    //Funcion para filtrar los datos de la tabla segun el dia sleccionado
    document.getElementById("filtro_estado_desembolso").value = "";
    document.getElementById("filtro_estado_prestamo").value = "";
    document.getElementById("fempre").value = "";
    document.getElementById("cedula_filtro").value = " ";
    let clientesTemp = [];

    let fechaInicio = document.getElementById("fecha_inicio").value;
    let fechaFin = document.getElementById("fecha_fin").value;
    // Convierte las fechas a objetos Date
    let fechaInicioObj = new Date(fechaInicio.split("/").reverse().join("-"));
    let fechaFinObj = new Date(fechaFin.split("/").reverse().join("-"));
    console.log(fechaFinObj);
    // Filtra los clientes cuya fecha de solicitud está entre las fechas seleccionadas
    this.state.Clientes.forEach((e) => {
      let fecha = e.fecha_solicitud.substring(0, 10);
      console.log(fecha);
      let fechaObj = new Date(fecha.split("/").reverse().join("-"));
      console.log(fechaObj);
      if (fechaObj >= fechaInicioObj && fechaObj <= fechaFinObj) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    setTimeout(() => {
      this.empezar();
    }, 1000);
  };

  cancela = () => {
    this.setState([(this.state.clientesTabla = this.state.Clientes)]);
  };

  datosApi = async (e) => {
    console.log("entre");

    let date = new Date();
    let fecha_cuota =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 2).padStart(2, "0") +
      "/" +
      date.getFullYear();
    // let valtotal=this.state.valpre+(this.state.valpre+1.5/100)*10000+3000
    let interes = 2.21;
    axios({
      method: "post",
      url: UserService.createUser2,
      data: {
        nombres: this.state.nom,
        apellidos: this.state.ape,
        fecha_nacimiento: this.state.naci,
        correo: this.state.correo,
        numero_documento: parseInt(this.state.numdoc),
        celular: parseInt(this.state.celu),
        numero_billetera: parseInt(this.state.numbille),
        fecha_cuota: fecha_cuota,
        habeas_data: this.state.habeas,
        metodo_desembolso: this.state.bille,
        valor_deuda: parseInt(this.state.valtotal),
        monto_cuotas: parseInt(this.state.valcuo),
        monto_prestamo: parseInt(this.state.valpre),
        empresa: this.state.empre,
        nit: parseInt(this.state.nit),
        ingresos: this.state.ingre,
        cuotas: parseInt(this.state.cuotas),
        easybio: this.state.easy,
        estado_desembolso: "Pendiente",
        estado_prestamo: "Aprobado",
        cuota1: "Pendiente",
        cuota2: "Pendiente",
        cuota3: "Pendiente",
        fecha_solicitud: this.state.fecha,
        firma_contrato: this.state.contra,
        descuento_nomina: this.state.nomi,
        fecha_desembolso: "null",
        fecha_cuota1: "null",
        fecha_cuota2: "null",
        fecha_cuota3: "null",
        fecha_finalizado: "null",
        tasa_interes: parseInt(interes),
      },
    });

    // window.location = '/VistaGeneral';
  };


  valoredit() {
    let monto_prestamo = parseInt(
      document.getElementById("monto_prestamo").value
    );
    let cuotas1 = parseInt(document.getElementById("cuotas-1").value);
    let interes = (monto_prestamo * 1.5 * cuotas1) / 100;
    let monto_total = monto_prestamo + interes + 13000;
    let monto_cuota = monto_total / cuotas1;

    document.getElementById("monto_cuotas").value = parseInt(monto_cuota);
    document.getElementById("valor_deuda").value = parseInt(monto_total);

    this.state.monto_cuotas = document.getElementById("valcuo").value =
      parseInt(monto_cuota);
    this.state.valor_deuda = document.getElementById("valtotal").value =
      parseInt(monto_total);
  }

  cancelar() {
    window.location = "/";
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <br></br>
          <div className="row titulogeneral">
            <div>
              <h2 className="text-center4">Vista General</h2>
              <br></br>
            </div>
          </div>
          <div className="row titulosecundario">
            <div>
              <h2 className="text-center3">Reportes</h2>
              <br></br>
            </div>
          </div>
          <div className="reportes">
            {/* <button
              className="btn btn-danger esp"
              onClick={(e) => this.importLibranza(e)}
            >
              Reporte Libranza
            </button> */}
            <button
              className="btn btn-success esp"
              onClick={(e) => this.importexcel(e)}
            >
              Reporte Excel
            </button>
            <button
              className="btn btn-success esp"
              onClick={(e) => this.rTesoretiaExcel(e)}
            >
              Reporte Tesorería
            </button>
          </div>

          {/* <div className="row titulosecundario">
            <div>
              <h2 className="text-center3">Subir Reporte Libranza</h2>
              <br></br>
            </div>
          </div>

          <div className="reportes">
            <input type="file" id="myFileInput" onChange={(e) => this.readreporteTesoreria(e)}  accept=".xlsx"></input>
            <label for="myFileInput" class="custom-file-button">
              Seleccionar archivo
            </label>

          </div> */}

<ReporteNomina />

          <div className="row titulosecundario">
            <div>
              <h2 className="text-center3">Filtros</h2>
              <br></br>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="filter_row">
              <select
                id="filtro_estado_prestamo"
                className=" col-3 imput-select form-control"
                required
                // onChange={(e) => this.filtroEstadoPrestamo(e.target.value)}
              >
                <option value="">Estado del prestamo</option>
                <option value="Finalizado">Finalizado</option>
                <option value="Aprobado">Aprobado</option>
                <option value="Preaprobado">Pre-aprobado</option>
                <option value="Rechazado">Rechazado</option>
                <option value="Finalizado Liquidado">Finalizado Liquidado</option>
              </select>

              <select
                id="filtro_estado_desembolso"
                className=" col-3 imput-select form-control"
                required
                // onChange={(e) => this.filtroEstadoDesembolso(e.target.value)}
              >
                <option value="">Estado del desembolso</option>
                <option value="Pendiente">Pendiente</option>
                <option value="Desembolsado">Desembolsado</option>
                <option value="Rechazado">Rechazado</option>
              </select>

              <form className="form-inline">
                <input
                  id="cedula_filtro"
                  className="form-control"
                  type="text"
                  placeholder="Número documento"
                  // onChange={(e) => {
                  //   this.state.filtroDocumento = e.target.value;
                  // }}
                  // onKeyUp={(e) => this.filtrarEmpre()}
                />
              </form>

              <select
                id="fempre"
                className=" col-2 imput-select form-control"
                required
                // onChange={(e) => {
                //   this.state.filtroEmpresa = e.target.value;
                // }}
                // onClick={(e) => this.filtrarEmpre()}
              >
                <option value="">Empresa</option>
                <option value="INTEVO">INTEVO</option>
                <option value="BONGO ANALYTICS">BONGO ANALYTICS</option>
                <option value="UNION TEMPORAL">UNIÓN TEMPORAL</option>
                <option value="SMARTEC">SMARTEC</option>
                <option value="MEGAFAST">MEGAFAST</option>
                <option value="PEAR SOLUTIONS">PEAR SOLUTION</option>
                <option value="TERUNGAMES">TERUNGAMES</option>
              </select>
            </div>
          </div>

          <div className="row justify-content-center">
          <form className="form-inline">
                <input
                  id="numero_id_filtro"
                  className="form-control"
                  type="text"
                  placeholder="ID"
                  // onChange={(e) => {
                  //   this.state.filtroDocumento = e.target.value;
                  // }}
                  // onKeyUp={(e) => this.filtrarEmpre()}
                />
              </form>
            <div className="filter_row_fechas">
              <input
                className="form-control "
                type="date"
                placeholder="Fecha inicio"
                id="fecha_inicio"
              />
              <input
                className="form-control ml-3 "
                type="date"
                placeholder="Filtrar fin"
                id="fecha_fin"
              />
              <button 
              onClick={this.filtros} 
              className="btn btn-success ml-3">
                Filtrar
              </button>
            </div>
          </div>

          <div className="col-2 offset-md-10">
            <button
              id="crear-user"
              type="button"
              className="botonusu"
              data-toggle="modal"
              data-target="#creausuario"
            >
              Crear usuario <FaUserEdit />
            </button>
          </div>
          <br></br>
          <div className="row">
            {/* Reporte de libranza */}
            <table
              className="table table-striped table-bordered"
              id="reportelibranza"
              style={{ display: `none` }}
            >
              <thead>
                <tr className="titulos-tabla4">
                  <th>CREDITO</th>
                  <th>CEDULA</th>
                  <th>APELLIDOS Y NOMBRES</th>
                  <th>EMPRESA</th>
                  <th>TIPO CREDITO</th>
                  <th>FECHA DE SOLICITUD</th>
                  <th>FECHA DE DESEMBOLSO</th>
                  <th>MONTO SOLICITADO</th>
                  <th>CUOTAS</th>
                  <th>TED</th>
                  <th>INTERES</th>
                  <th>TARIFA ADMINISTRATIVA</th>
                  <th>CONSULTA PERFIL </th>
                  <th>VALOR TOTAL A PAGAR</th>
                  <th>VALOR CUOTA</th>
                  <th>FECHA CUOTA 1</th>
                  <th>FECHA CUOTA 2</th>
                  <th>FECHA CUOTA 3</th>
                  <th>NOVEDAD CARGADO AL SISTEMA DE NOMINA</th>
                  <th>OBSERVACIONES</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Clientes.filter(
                  (cliente) =>
                    cliente.estado_desembolso === "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" &&
                    cliente.novedad === "NO"
                ).map((cliente) => (
                  <tr key={cliente.numero_documento}>
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td>#{cliente._id}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.numero_documento}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.nombres + " " + cliente.apellidos} </td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.empresa}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> LIBRANZA</td>
                    ) : (
                      <td></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.fecha_solicitud}</td>
                    ) : (
                      <td></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.fecha_desembolso}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.monto_prestamo}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.cuotas}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.tasa_interes}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td>
                        {" "}
                        ${cliente.valor_interes.toFixed()}
                      </td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> ${cliente.tarifa_administrativa}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> ${cliente.consulta_perfil}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.valor_deuda}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> ${cliente.monto_cuotas}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.fecha_cuota}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.fechaestimada_cuota2}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td> {cliente.fechaestimada_cuota3}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td>{cliente.novedad}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                    {cliente.estado_desembolso == "Desembolsado" &&
                    cliente.estado_prestamo != "Finalizado" ? (
                      <td>{cliente.observacion}</td>
                    ) : (
                      <td style={{ display: `none` }}></td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>

            <table
              className="table table-striped table-bordered"
              id="tablaexcel"
            >
              <thead>
                <tr className="titulos-tabla4">
                  <th id="delete-user"> Eliminar</th>
                  <th id="delete-user1"> Editar</th>
                  <th> ID</th>
                  <th> Nombres</th>
                  <th> Apellidos</th>
                  <th> Número de Documento</th>
                  <th> Empresa</th>
                  <th> Celular</th>
                  <th> Estado Desembolso</th>
                  <th> Estado Prestamo</th>
                  <th> Valor Prestamo</th>
                  <th> # de Cuotas</th>
                  <th> Cuota 1</th>
                  <th> Cuota 2</th>
                  <th> Cuota 3</th>
                  <th> Fecha de Solicitud</th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientesTabla.map((cliente, index) => {
                  return (
                    index > this.state.paginacion &&
                    index <= this.state.paginacion + 4 && (
                      <tr key={cliente.numero_documento}>
                        {this.state.id_usuario === 3 || this.state.id_usuario === 4 || this.state.id_usuario === 5 ? (
                          <td style={{ display: `none` }}></td>
                        ) : (
                          <td id="delete-user1">
                            <button
                              style={{ marginLeft: "10px" }}
                              onClick={() => this.update_id(cliente._id)}
                              className="btn btn-danger"
                              data-toggle="modal"
                              data-target="#confirmaeliminar"
                            >
                              {" "}
                              <MdDeleteForever />{" "}
                            </button>
                          </td>
                        )}
                        {this.state.id_usuario === 3 || this.state.id_usuario === 4 || this.state.id_usuario === 5 ? (
                          <td style={{ display: `none` }}></td>
                        ) : (
                          <td id="delete-user1">
                            <button
                              onClick={() => {
                                this.editUser(cliente);
                                this.update_id(cliente._id);
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#editapagos"
                            >
                              {" "}
                              <FaEdit />{" "}
                            </button>
                          </td>
                        )}

                        <td> #{cliente._id}</td>
                        <td> {cliente.nombres} </td>
                        <td> {cliente.apellidos}</td>
                        <td> {cliente.numero_documento}</td>
                        <td> {cliente.empresa}</td>
                        <td> {cliente.celular}</td>
                        <td> {cliente.estado_desembolso}</td>
                        <td> {cliente.estado_prestamo}</td>
                        <td>
                          {" "}
                          $
                          {new Intl.NumberFormat().format(
                            cliente.monto_prestamo
                          )}
                        </td>
                        <td> {cliente.cuotas}</td>
                        <td> {cliente.cuota1}</td>
                        <td> {cliente.cuota2}</td>
                        <td> {cliente.cuota3}</td>
                        <td> {cliente.fecha_solicitud}</td>
                      </tr>
                    )
                  );
                })}
              </tbody>
            </table>
            {/* Reporte Excel data neoprestamos */}
            <table
              className="table table-striped table-bordered"
              id="tablaexcel1"
              style={{ display: `none` }}
            >
              <thead>
                <tr className="titulos-tabla4">
                  <th> ID</th>
                  <th> Nombres</th>
                  <th> Apellidos</th>
                  <th> Número de Documento</th>
                  <th> Empresa</th>
                  <th> Ingresos</th>
                  <th> Correo</th>
                  <th> Celular</th>
                  <th> Estado Desembolso</th>
                  <th> Estado Prestamo</th>
                  <th> Fecha Desembolso</th>
                  <th> Valor Prestamo</th>
                  <th> Valor Cuota</th>
                  <th> Valor Deuda</th>
                  <th> # de Cuotas</th>
                  <th> Cuota 1</th>
                  <th> Fecha 1</th>
                  <th> Cuota 2</th>
                  <th> Fecha 2</th>
                  <th> Cuota 3</th>
                  <th> Fecha 3</th>
                  <th> Fecha de Solicitud</th>
                  <th> fecha_solicitud</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Clientes.map((cliente) => (
                  <tr key={cliente.numero_documento}>
                    <td> #{cliente._id}</td>
                    <td> {cliente.nombres} </td>
                    <td> {cliente.apellidos}</td>
                    <td> {cliente.numero_documento}</td>
                    <td> {cliente.empresa}</td>
                    <td> {cliente.ingresos} SMMLV</td>
                    <td> {cliente.correo}</td>
                    <td> {cliente.celular}</td>
                    <td> {cliente.estado_desembolso}</td>
                    <td> {cliente.estado_prestamo}</td>
                    <td> {cliente.fecha_desembolso}</td>
                    <td> {cliente.monto_prestamo}</td>
                    <td> {cliente.monto_cuotas}</td>
                    <td> {cliente.valor_deuda}</td>
                    <td> {cliente.cuotas}</td>
                    <td> {cliente.cuota1}</td>
                    <td> {cliente.fecha_cuota1}</td>
                    <td> {cliente.cuota2}</td>
                    <td> {cliente.fecha_cuota2}</td>
                    <td> {cliente.cuota3}</td>
                    <td> {cliente.fecha_cuota3}</td>
                    <td> {cliente.fecha_solicitud}</td>
                    <td> {cliente.fecha_finalizado}</td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Reporte Excel data neoprestamos */}
            <table
              className="table table-striped table-bordered"
              id="tablaexceltesoreria"
              style={{ display: `none` }}
            >
              <thead>
                <tr className="titulos-tabla4">
                  <th> FECHA DESCARGA</th>
                  <th> CREDITO</th>
                  <th> APELLIDOS Y NOMBRES</th>
                  <th> CÉDULA</th>
                  <th> EMPRESA</th>
                  <th> ESTADO DEL PRESTAMO</th>
                  <th> ESTADO DE DESEMBOLSO</th>
                  <th> BILLETERA DIGITAL</th>
                  <th> NÚMERO BILLETERA DIGITAL</th>
                  <th> CORREO ELECTRÓNICO</th>
                  <th> FECHA SOLICITUD</th>
                  <th> FECHA DE DESEMBOLSO</th>
                  <th> MONTO SOLICITADO</th>
                  <th> CUOTAS</th>
                  <th> DIAS TOTAL DE PRESTAMO</th>
                  <th> TED</th>
                  <th> INTERES</th>
                  <th> TARIFA ADMINISTRATIVA</th>
                  <th> CONSULTA PERFIL</th>
                  <th> VALOR TOTAL A PAGAR</th>
                  <th> VALOR CUOTA</th>
                  <th> FECHA CUOTA 1</th>
                  <th> FECHA CUOTA 2</th>
                  <th> FECHA CUOTA 3</th>
                </tr>
              </thead>
              <tbody>
                {this.state.Clientes.map((cliente) => (
                  <tr key={cliente.numero_documento}>
                    <td>
                      {String(this.state.fecha_actual.getDate()).padStart(
                        2,
                        "0"
                      ) +
                        "/" +
                        String(this.state.fecha_actual.getMonth() + 1).padStart(
                          2,
                          "0"
                        ) +
                        "/" +
                        this.state.fecha_actual.getFullYear()}
                    </td>
                    <td> #{cliente._id}</td>
                    <td> {cliente.nombres + " " + cliente.apellidos}</td>
                    <td> {cliente.numero_documento}</td>
                    <td> {cliente.empresa}</td>
                    <td> {cliente.estado_prestamo}</td>
                    <td> {cliente.estado_desembolso}</td>
                    <td> {cliente.metodo_desembolso}</td>
                    <td> {cliente.numero_billetera} </td>
                    <td> {cliente.correo}</td>
                    <td> {cliente.fecha_solicitud}</td>
                    <td> {cliente.fecha_desembolso}</td>
                    <td> {cliente.monto_prestamo}</td>
                    <td> {cliente.cuotas}</td>
                    <td> {cliente.conteo_dias}</td>
                    <td> {cliente.tasa_interes}</td>
                    <td>
                      {" "}
                      {cliente.valor_interes.toFixed()}
                    </td>
                    <td> {cliente.tarifa_administrativa}</td>
                    <td> {cliente.consulta_perfil}</td>
                    <td>
                      {" "}
                      {cliente.valor_deuda.toFixed()}
                    </td>
                    <td>
                      {" "}
                      {cliente.monto_cuotas.toFixed()}
                    </td>
                    <td> {cliente.fecha_cuota}</td>
                    <td> {cliente.fechaestimada_cuota2}</td>
                    <td> {cliente.fechaestimada_cuota3}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* PAGINACION */}
        <div className="row col-12 ">
          <div className="col-1 offset-md-4">
            <button className="Bback" onClick={this.primera}>
              <BsFillSkipBackwardFill />
            </button>
          </div>
          <div className="col-1 ">
            <button className="Bback" onClick={this.antes}>
              <BsFillCaretLeftFill />
            </button>
          </div>
          <div className="col-1">
            <span>{Math.ceil(this.state.paginacion / 4 + 1)}</span> de{" "}
            <span> {Math.ceil(this.state.clientesTabla.length / 4)}</span>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.despues}>
              <BsFillCaretRightFill />
            </button>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.ultima}>
              <BsFillSkipForwardFill />
            </button>
          </div>
        </div>
        {/* MODALFILTRO */}
        {/* <div class="modal fade" id="filtro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content modalf">
              <div class="modal-header">
                <h5 class="modal-titlef" id="exampleModalLabel">Filtrar</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <h5 class="modal-titlet">Buscar:</h5>  
                <div className="input-group mb-3">
                  <input id='fempre' type="text" 
                    className="form-control imput-select1" 
                    placeholder='Empresa' required
                    onKeyUp={e => {this.state.filtroEmpresa = e.target.value;}}
                    />
                </div>          
                <div className="input-group mb-3">
                  <input id='doc' type="number" 
                    className="form-control imput-select1" 
                    placeholder='Numero de documento' required
                    onKeyUp={e => {this.state.filtroDocumento = e.target.value;}}
                    />
                </div> 
                <div className="input-group mb-3">
                  <input id='nom' type="text" 
                    className="form-control imput-select1" 
                    placeholder='Nombre' required
                    onKeyUp={e => {this.state.filtroNombre = e.target.value;}}
                    />
                </div> 
              </div>
              <div class="modal-footer">
                <button className="btn btn-success" data-dismiss="modal" onClick={e => this.filtrar()}>Buscar</button>
                <button className="btn btn-danger" data-dismiss="modal" style={{marginLeft: "10px"}} onClick={e => this.cancela()}>Cancelar</button>
              </div>
            </div>
          </div>
        </div> */}
        <div
          class="modal fade modalf"
          id="creausuario"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Crear nuevo usuario
                </h5>
              </div>
              <div class="modal-body">
                <div className="form-group">
                  <label>Número de Documento</label>
                  <input
                    className="form-control"
                    id="numdoc"
                    onChange={(event) => {
                      this.valuestate(event.target);
                      this.validacionBD();
                    }}
                    onClick={() => {
                      this.camposDB();
                    }}
                    type="number"
                  />
                  <label>Fecha de nacimiento</label>
                  <input
                    id="naci"
                    onChange={(event) => {
                      this.valuestate(event.target);
                      this.validacionFecha(event);
                    }}
                    onClick={() => {
                      this.camposDB();
                    }}
                    type="date"
                    className="form-control imput-select"
                    aria-label="Text input with dropdown button"
                    placeholder="Fecha de nacimiento"
                    max={"2004-12-31"}
                  />
                  <label>Tipo de documento</label>
                  <input
                    className="form-control"
                    disabled
                    id="tipo"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={this.camposDB()}
                    type="text"
                  />
                  <label>Nombres</label>
                  <input
                    className="form-control"
                    disabled
                    id="nom"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={this.camposDB()}
                    type="text"
                  />
                  <label>Apellidos</label>
                  <input
                    className="form-control"
                    disabled
                    id="ape"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={this.camposDB()}
                    type="text"
                  />
                  <label>Empresa</label>
                  <input
                    className="form-control"
                    disabled
                    id="empre"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={this.camposDB()}
                    type="text"
                  />
                  <label>NIT</label>
                  <input
                    className="form-control"
                    disabled
                    id="nit"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={this.camposDB()}
                    type="text"
                  />
                  <label>Numero de celular</label>
                  <input
                    className="form-control"
                    id="celu"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                  />
                  <label>Correo</label>
                  <input
                    className="form-control"
                    id="correo"
                    type={"email"}
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                  />
                  <label>Promedio de salario mensual</label>
                  <select
                    id="ingre"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="0 a 2">0 a 2 SMLV</option>
                    <option value="3 a 7">3 a 7 SMLV</option>
                    <option value="8 o +">8 SMLV o mas</option>
                  </select>
                  <label>Valor Préstamo</label>
                  <select
                    id="valpre"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="100000">$100.000</option>
                    <option value="200000">$200.000</option>
                    <option value="300000">$300.000</option>
                    <option value="400000">$400.000</option>
                    <option value="500000">$500.000</option>
                  </select>
                  <label>Número de cuotas</label>
                  <select
                    id="cuotas"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <label>Valor por Cuota</label>
                  <input className="form-control" disabled id="valcuo" />
                  <label>Valor total</label>
                  <input className="form-control" disabled id="valtotal" />
                  <label>Fecha solicitud prestamo</label>
                  <input
                    id="fecha"
                    onChange={(event) => {
                      this.valuestate(event.target);
                      this.validacionFecha();
                    }}
                    onClick={() => {
                      this.camposDB();
                    }}
                    type="date"
                    className="form-control imput-select"
                    aria-label="Text input with dropdown button"
                    placeholder="Fecha de prestamo"
                  />
                  <label>Tipo de billetera digital</label>
                  <select
                    id="bille"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="Nequi">Nequi</option>
                    <option value="Daviplata">Daviplata</option>
                  </select>
                  <label>Número de billetera digital</label>
                  <input
                    className="form-control"
                    id="numbille"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                  />
                  <label>EasyBio</label>
                  <select
                    id="easy"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                  <label>HabeasData</label>
                  <select
                    id="habeas"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                  <label>Contrato</label>
                  <select
                    id="contra"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                      this.validacioncampos();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                  <label>Descuento por nómina</label>
                  <select
                    id="nomi"
                    onChange={(event) => this.valuestate(event.target)}
                    onClick={() => {
                      this.camposDB();
                      this.validacioncampos();
                    }}
                    className="imput-select form-control"
                    required
                  >
                    <option selected></option>
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="modal-footer">
                {/* <button className="btn btn-success" onClick={(e)=>{this.updateUser(e);this.validacionS(e)}}>Guardar</button> */}
                <button
                  id="envio"
                  className="btn btn-success"
                  data-toggle="modal"
                  data-dismiss="modal"
                  onClick={() => this.validacioncampos()}
                  data-target="#confirmacrear"
                >
                  Guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    this.cancelar();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="confirmacrear"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-titlec" id="exampleModalLabel">
                  Confirmar crear usuario
                </h5>
              </div>
              <div class="modal-body" style={{ marginLeft: "50px" }}>
                ¿Estas seguro de confirmar y crear un nuevo usuario?
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    this.datosApi(e);
                  }}
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#exito"
                >
                  Sí, guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#creausuario"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="exito"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div
                class="modal-body modal-titlef"
                style={{ marginLeft: "50px" }}
              >
                El usuario se creo exitosamente
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={() => {
                    this.cancelar();
                  }}
                  data-dismiss="modal"
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
        {this.state.clientesTabla.map((cliente) => (
          <div
            class="modal fade"
            id="confirmaeliminar"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title1" id="exampleModalLabel">
                    Confirma eliminar usuario
                  </h5>
                </div>
                <div class="modal-body" style={{ marginLeft: "50px" }}>
                  ¿Estas seguro de eliminar este usuario?
                </div>
                <div class="modal-footer">
                  <button
                    className="btn btn-success"
                    onClick={() => this.deleteUser()}
                  >
                    Sí, eliminar
                  </button>
                  <button
                    className="btn btn-danger"
                    data-dismiss="modal"
                    data-toggle="modal"
                    style={{ marginLeft: "10px" }}
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div
          className="back-modal"
          id="alerta"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h5 className="h5S">
                  Recuerda que para solicitar un Neopréstamo, la persona debe
                  ser parte del equipo de trabajo de las empresas aliadas.
                </h5>
                <br></br>
                <h5 className="h5S">
                  Asegúrate que el número de documento este bien escrito
                </h5>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("alerta").style.display = "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="back-modal"
          id="fechalert"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  Verifica la fecha de nacimiento. Recuerda que debé ser mayor
                  de edad para solicitar un Neopréstamo.
                </h4>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("fechalert").style.display =
                      "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  Aceptar{" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="capacidad"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  la capacidad de endeudamiento es menor al valor del prestamo
                  que esta solicitando. Por favor intenta nuevamente y solicita
                  un nuevo valor
                </h4>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("capacidad").style.display =
                      "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="errorCargaExcel"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  <b>
                    No se ha podido cargar el documento, por favor verifique la
                    información e intente nuevamente
                  </b>
                </h4>
                <button
                  type="button"
                  onClick={
                    this.reload
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  data-toggle="modal"
                >
                  {" "}
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="successCargaExcel"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  <b>Se ha registrado la información exitosamente</b>
                </h4>
                <button
                  type="button"
                  onClick={
                    this.reload
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  data-toggle="modal"
                >
                  {" "}
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="back-modal"
          id="creditos"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content curva">
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
              <div className="modal-body">
                <h4 className="h5S">
                  <b>
                    Esta persona ya cuenta con un Neopréstamo activo, cuando
                    concluya con los pagos, podra solicitar un nuevo préstamo en
                    la plataforma
                  </b>
                </h4>
                <button
                  type="button"
                  onClick={(e) =>
                    (document.getElementById("creditos").style.display = "none")
                  }
                  className="btn-enunciado-paga9"
                  data-bs-dismiss="modal"
                  data-toggle="modal"
                >
                  {" "}
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* modal edit user */}
        {/* EDICION DE DATOS */}
        <div
          class="modal fade"
          id="editapagos"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg modal-dialog-scrollable"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Editar Registro
                </h5>
              </div>
              <div class="modal-body">
                {
                  <form key={this.state.Cliente_edit.numero_documento}>
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        className="form-control"
                        id="modal_editar_id"
                        disabled
                        placeholder={this.state.Cliente_edit._id}
                      />

                      <label>Nombres</label>
                      <input
                        className="form-control"
                        disabled
                        placeholder={this.state.Cliente_edit.nombres}
                      />

                      <label>Apellidos</label>
                      <input
                        className="form-control"
                        disabled
                        placeholder={this.state.Cliente_edit.apellidos}
                      />

                      <label>Número de Documento</label>
                      <input
                        className="form-control"
                        disabled
                        placeholder={this.state.Cliente_edit.numero_documento}
                      />

                      <label>Empresa</label>
                      <input
                        className="form-control"
                        disabled
                        placeholder={this.state.Cliente_edit.empresa}
                      />

                      <label>Nit</label>
                      <input
                        className="form-control"
                        disabled
                        placeholder={this.state.Cliente_edit.nit}
                      />

                      <label>Correo</label>
                      <input
                        className="form-control"
                        id="correo-1"
                        type="email"
                        placeholder={this.state.Cliente_edit.correo}
                      />

                      <label>Celular</label>
                      <input
                        className="form-control"
                        id="celular-1"
                        type="number"
                        placeholder={this.state.Cliente_edit.celular}
                      />

                      <label>Numero de Billetera</label>
                      <input
                        className="form-control"
                        id="numero_billetera"
                        type="number"
                        placeholder={this.state.Cliente_edit.numero_billetera}
                      />

                      <label>Metodo de Desembolso</label>
                      <select
                        id="metodo_desembolso"
                        className="imput-select form-control"
                        required
                      >
                        <option selected>
                          {this.state.Cliente_edit.metodo_desembolso}
                        </option>
                        <option value="Nequi">Nequi</option>
                        <option value="Daviplata">Daviplata</option>
                      </select>

                      <label>Valor Prestamo</label>
                      <select
                        id="monto_prestamo"
                        className="imput-select form-control"
                        required
                        onClick={() => {
                          this.valoredit();
                        }}
                      >
                        <option value={this.state.Cliente_edit.monto_prestamo}>
                          {"$" +
                            new Intl.NumberFormat().format(
                              this.state.Cliente_edit.monto_prestamo
                            )}
                        </option>
                        <option value="100000">$100.000</option>
                        <option value="200000">$200.000</option>
                        <option value="300000">$300.000</option>
                        <option value="400000">$400.000</option>
                        <option value="500000">$500.000</option>
                      </select>

                      <label>Cuotas</label>
                      <select
                        id="cuotas-1"
                        className="imput-select form-control"
                        required
                        onClick={() => {
                          this.valoredit();
                        }}
                      >
                        <option selected>
                          {this.state.Cliente_edit.cuotas}
                        </option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>

                      <label>Valor Cuota</label>
                      <input
                        className="form-control"
                        disabled
                        id="monto_cuotas"
                        placeholder={
                          "$" +
                          new Intl.NumberFormat().format(
                            this.state.Cliente_edit.monto_cuotas
                          )
                        }
                      />

                      <label>Total Deuda</label>
                      <input
                        className="form-control"
                        disabled
                        id="valor_deuda"
                        placeholder={
                          "$" +
                          new Intl.NumberFormat().format(
                            this.state.Cliente_edit.valor_deuda
                          )
                        }
                      />

                      <label>Ingresos</label>
                      <select
                        id="ingresos-1"
                        className="imput-select form-control"
                        required
                      >
                        <option selected>
                          {this.state.Cliente_edit.ingresos}
                        </option>
                        <option value="0 a 2">0 a 2 SMLV</option>
                        <option value="3 a 7">3 a 7 SMLV</option>
                        <option value="8 o +">8 SMLV o mas</option>
                      </select>

                      <label>Fecha solicitud prestamo</label>
                      <input
                        id="fecha_solicitud"
                        type="date"
                        onfocus={{ type: `date` }}
                        onblur={{ type: `text` }}
                        className="form-control imput-select"
                        aria-label="Text input with dropdown button"
                        v
                        value={this.state.Cliente_edit.fecha_solicitud}
                      />

                      <label>Estado de desembolso</label>
                      <select
                        id="estado_desembolso"
                        className="imput-select form-control"
                        required
                      >
                        <option selected>
                          {this.state.Cliente_edit.estado_prestamo}
                        </option>
                        <option value="Pendiente">Pendiente</option>
                        <option value="Desembolsado">Desembolsado</option>
                        <option value="Rechazado">Rechazado</option>
                      </select>
                      <label>Estado de prestamo</label>
                      <select
                        id="estado_prestamo"
                        className="imput-select form-control"
                        required
                      >
                        <option selected>
                          {this.state.Cliente_edit.estado_desembolso}
                        </option>
                        <option value="Aprobado">Aprobado</option>
                        <option value="Finalizado">Finalizado</option>
                        <option value="Rechazado">Rechazado</option>
                      </select>

                      <label>Cuota 1</label>

                      <select id="cuota1" className="form-control">
                        {this.state.Cliente_edit.cuota1 == "Pendiente" ? (
                          <option value="Pendiente">Pendiente</option>
                        ) : (
                          <option value="Pagada">Pagada</option>
                        )}
                        {this.state.Cliente_edit.cuota1 == "Pagada" ? (
                          <option value="Pendiente">Pendiente</option>
                        ) : (
                          <option value="Pagada">Pagada</option>
                        )}
                      </select>

                      {this.state.Cliente_edit.cuotas == 1 ? (
                        <label style={{ display: `none` }}>Cuota 2</label>
                      ) : (
                        <label>Cuota 2</label>
                      )}

                      {this.state.Cliente_edit.cuotas == 1 ? (
                        <select
                          id="cuota2"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota2}
                          style={{ display: `none` }}
                        >
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      ) : (
                        <select
                          id="cuota2"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota2}
                        >
                          <option value="Pendiente">Pendiente</option>
                          <option value="Pagada">Pagada</option>
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      )}

                      {this.state.Cliente_edit.cuotas != 3 ? (
                        <label style={{ display: `none` }}>Cuota 3</label>
                      ) : (
                        <label>Cuota 3</label>
                      )}

                      {this.state.Cliente_edit.cuotas != 3 ? (
                        <select
                          id="cuota3"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota3}
                          style={{ display: `none` }}
                        >
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      ) : (
                        <select
                          id="cuota3"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota3}
                        >
                          <option value="Pendiente">Pendiente</option>
                          <option value="Pagada">Pagada</option>
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      )}
                    </div>
                  </form>
                }
              </div>
              <div class="modal-footer">
                {/* <button className="btn btn-success" onClick={(e)=>this.updateUser(e)}>Guardar</button> */}
                <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#confirmationedicion"
                >
                  Guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    this.cancelar();
                  }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="confirmationedicion"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Confirmar cambios
                </h5>
              </div>
              <div class="modal-body" style={{ marginLeft: "50px" }}>
                ¿Estas seguro de confirmar cambios realizados?
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={(e) => this.updateUser(e)}
                >
                  Sí, guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#editapagos"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default VistaGeneral;
