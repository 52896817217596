import React, { Component } from 'react';
import UserService from '../../services/UserService';
import logoBongo from '../images/Tu_bongo.png';
import '../../css/EstadoRechazado.css';
import axios from 'axios';

class EstadoRechazado extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            title: '',
            icon: '',
            email: ' ',
            emailUser:' '
        };
    }

    componentDidMount = async () => {
        let queryString = window.location.search;

        // Crea un objeto URLSearchParams con la cadena de consulta
        let params = new URLSearchParams(queryString);

        // Accede a los parámetros individualmente
        let parametro1 = params.get('id');

        console.log(parametro1); // Imprime "valor1"

        this.setState({ id: parametro1 });

        let fecha_actual = new Date();

        let userData = await UserService.getUserByi(parametro1);

         let fecha_solicitud = userData.data.Cliente[0].fecha_solicitud;
         let estadoPrestamo = userData.data.Cliente[0].estado_prestamo;
         //let fecha_solicitud = "04/06/2023 17:00";

       

        let fecha_desembolso = new Date(
            fecha_solicitud.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3')
        );

        let diferenciaEnTiempo =
            fecha_actual.getTime() - fecha_desembolso.getTime();

        let diferenciaEnHoras = diferenciaEnTiempo / (1000 * 60 * 60);
         
          

        if (diferenciaEnHoras > 96) {
          let title = "No se pudo realizar el cambio de estado";
          let description = document.getElementById("descripcion");
          description.innerHTML = `</p><strong>¡Ups!</strong> Hemos detectado que el tiempo establecido para realizar el cambio de estado ha expirado. Lamentablemente, debido a esta demora, es necesario que te comuniques nuevamente con nosotros. Nos disculpamos por cualquier inconveniente que esto pueda causarte.</p> `;
          let icon =
            "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z";
          this.setState({ title: title, icon: icon });
        } else if (estadoPrestamo !== "Preaprobado") {
          let title = "No se pudo realizar el cambio de estado";
          let description = document.getElementById("descripcion");
          description.innerHTML = `</p><strong>¡Ups!</strong> Parece que algo salió mal. Hemos detectado que el estado del préstamo ya ha sido modificado anteriormente, por lo que no podemos realizar la acción que estás intentando realizar en este momento. Lamentablemente, no es posible modificar nuevamente el estado del préstamo en este momento.</p> `;
          let icon =
            "M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z";
          this.setState({ title: title, icon: icon });
        } else {
          let title = "SOLICITUD RECHAZADA ";
          let description = document.getElementById("descripcion");
          description.innerHTML = `</p>El crédito con número de
            referencia N°<strong>${this.state.id}</strong> ha sido rechazado
            con éxito y ya está disponible para su visualización en el
            portal del administrador. Puedes acceder a esta información
            específica en la pestaña de <strong>"Creditos Rechazados"</strong></p> `;
          let icon = "M20 7L9.00004 18L3.99994 13";
          let correoBongo="credenciales@bongoanalytics.com"
       
        //Produccion 
        const datosCredito = await axios.get(
              //PRODUCCION
              `https://apineopage.tubongo.com/api/searchclienteid/${this.state.id}`
              //PRE PRUDCCION
              //  `http://190.109.16.229:10259/api/searchclienteid/${this.state.id}`

          );
            const info = datosCredito.data.Cliente[0];
            console.log(info.correo)
          let correoUser=info.correo
          this.setState({ title: title, icon: icon, email: correoBongo  ,emailUser:correoUser});
          //PRE PRODUCCION
          // let urlDenieduser = "http://190.109.16.229:1092/email-denied-user";
          // let urlDenied = "http://190.109.16.229:1092/email-denied";
          // PRODUCCION
          let urlDenied = "https://apiemail.tubongo.com/email-denied";
          let urlDenieduser = "https://apiemail.tubongo.com/email-denied-user";
          let infoDenied = JSON.stringify({ email: this.state.email, id: this.state.id });   
          let infoDenieduser = JSON.stringify({ email: this.state.emailUser, id: this.state.id });   
            axios
            .post(urlDenied, infoDenied)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

            axios
            .post(urlDenieduser, infoDenieduser)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
          UserService.updateUserId(
            { estado_prestamo: "Rechazado", estado_desembolso: "Rechazado", fecha_rechazado:fecha_actual , diferencia_horas_pa:diferenciaEnHoras},
            parametro1
          );
        }
    };

    render() {
        return (
            <div className="container-2">
                <div className="image-container d-flex align-items-center col-7 justify-content-center ">
                    <img src={logoBongo}></img>
                </div>
                <div className="col-5 parent-container d-flex align-items-center justify-content-center">
                    <div class="card ">
                        <button class="dismiss" type="button">
                            ×
                        </button>
                        <div class="header">
                            <div class="image-alert">
                                <svg
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                    ></g>
                                    <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                        {' '}
                                        <path
                                            d={this.state.icon}
                                            stroke="#3E3B8C"
                                            stroke-width="1.5"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        ></path>{' '}
                                    </g>
                                </svg>
                            </div>
                            <div class="content">
                                <span class="title">{this.state.title}</span>
                                <p class="message" id="descripcion"></p>
                            </div>
                            <div class="actions" id="boton-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EstadoRechazado;
