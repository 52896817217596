import React, { Component } from 'react'
import UserService from '../../services/UserService';

class ViewUserComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            numero_documento: this.props.match.params.numero_documento,
            Cliente: []
        }
    }

    componentDidMount(){
        UserService.getUserById(this.state.numero_documento).then( res => {
            this.setState(res.data);
        })
    }
   

    render() {
        return (
            <div>
                <br></br><div className="content-wrapper">
                <div className = "card col-md-6 offset-md-3">
                    <h3 className = "text-center"> View User Details</h3>
                    <div className = "card-body">
                        <div className = "row">
                            <label> User First Name: </label>
                            <div> {this.state.Cliente.nombres}</div>
                        </div>
                        <div className = "row">
                            <label> User Last Name: </label>
                            <div> {this.state.Cliente.apellidos}</div>
                        </div>
                        <div className = "row">
                            <label> User Email ID: </label>
                            <div> {this.state.Cliente.correo}</div>
                        </div>
                    </div>

                </div>
            </div></div>
        )
    }
}

export default ViewUserComponent
