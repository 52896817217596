import { useState } from "react";
import {
  BsFillCaretLeftFill,
  BsFillSkipBackwardFill,
  BsFillSkipForwardFill,
  BsFillCaretRightFill,
} from "react-icons/bs";

const TablaLiquidados = ({ dataLiquidados }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const totalPages = Math.ceil(dataLiquidados.length / itemsPerPage);

  // Cambiar a la página siguiente
  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  // Cambiar a la página anterior
  const previousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  // Cambiar a la primera página
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  // Cambiar a la última página
  const goToLastPage = () => {
    setCurrentPage(totalPages);
  };

  // Calcular el índice del último y primer elemento de la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Obtener los elementos de la página actual
  const currentItems = dataLiquidados.slice(indexOfFirstItem, indexOfLastItem);

  const rExcel = () => {
    let XLSX = require("xlsx");
    // Obtener los datos de la tabla HTML
    const table = document.getElementById("tablaLiquidadosDescarga");
    // Convertir la tabla HTML a una matriz de datos, omitiendo la última columna
    const data = Array.from(table.rows).map((row) =>
      Array.from(row.cells).map((cell) => cell.innerText)
    );
    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);
    // Añadir la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Descargar el archivo de Excel
    XLSX.writeFile(wb, "tablaliquidados.xlsx");
  };

  return (
    <div className="row">
      <div className="reportes">
        <button className="btn btn-success" onClick={rExcel}>
          Descargar
        </button>
      </div>
      <table className="table table-striped table-bordered" id="tablaActivos">
        <thead>
          <tr className="titulos-tabla4">
            <th>Empresa</th>
            <th># Préstamo</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Cedula</th>
            <th>Fecha Solicitud</th>
            <th>Valor Préstamo</th>
            <th>Fecha Liquidación</th>
            <th>Valor para Normalizar</th>
            <th>Estado Cuota 1</th>
            <th>Estado Cuota 2</th>
            <th>Estado Cuota 3</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((cliente, index) => {
            return (
              <tr key={`deuda-activo-${index}`}>
                <td>{cliente.empresa}</td>
                <td>{cliente._id}</td>
                <td>{cliente.nombres}</td>
                <td>{cliente.apellidos}</td>
                <td>{cliente.numero_documento}</td>
                <td>{cliente.fecha_solicitud}</td>
                <td>{cliente.monto_prestamo}</td>
                <td>{cliente.fecha_liquidacion}</td>
                <td>{cliente.valor_normalizado}</td>
                <td>{cliente.cuota1}</td>
                <td>{cliente.cuota2}</td>
                <td>{cliente.cuota3}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <table className="table table-striped table-bordered d-none" id="tablaLiquidadosDescarga">
        <thead>
          <tr className="titulos-tabla4">
            <th>Empresa</th>
            <th># Préstamo</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Cedula</th>
            <th>Fecha Solicitud</th>
            <th>Valor Préstamo</th>
            <th>Fecha Liquidación</th>
            <th>Valor para Normalizar</th>
            <th>Estado Cuota 1</th>
            <th>Estado Cuota 2</th>
            <th>Estado Cuota 3</th>
          </tr>
        </thead>
        <tbody>
          {dataLiquidados.map((cliente, index) => {
            return (
              <tr key={`deuda-activo-${index}`}>
                <td>{cliente.empresa}</td>
                <td>{cliente._id}</td>
                <td>{cliente.nombres}</td>
                <td>{cliente.apellidos}</td>
                <td>{cliente.numero_documento}</td>
                <td>{cliente.fecha_solicitud}</td>
                <td>{cliente.monto_prestamo}</td>
                <td>{cliente.fecha_liquidacion}</td>
                <td>{cliente.valor_normalizado}</td>
                <td>{cliente.cuota1}</td>
                <td>{cliente.cuota2}</td>
                <td>{cliente.cuota3}</td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Paginación */}
      <div className="row col-12">
        <div className="col-1 offset-md-3">
          <button
            onClick={goToFirstPage}
            disabled={currentPage === 1}
            className="Bback"
          >
            <BsFillSkipBackwardFill />
          </button>
        </div>
        <div className="col-1">
          <button
            onClick={previousPage}
            disabled={currentPage === 1}
            className="Bback"
          >
            <BsFillCaretLeftFill />
          </button>
        </div>

        <div className="col-1">
          <span className="pagination-text">{`${currentPage} de ${totalPages}`}</span>
        </div>

        <div className="col-1">
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className="Bnext"
          >
            <BsFillCaretRightFill />
          </button>
        </div>
        <div className="col-1">
          <button
            onClick={goToLastPage}
            disabled={currentPage === totalPages}
            className="Bnext"
          >
            <BsFillSkipForwardFill />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TablaLiquidados;
