//PENDIENTE POR DESEMBOLSO
import React, { Component } from "react";
import UserService from "../../services/UserService";
import { BsPencilSquare } from "react-icons/bs";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsFillSkipBackwardFill } from "react-icons/bs";
import { BsFillSkipForwardFill } from "react-icons/bs";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import "../../css/ListUserComponent2.css";

class Liquidacion extends Component {
  hoy = null;
  constructor(props) {
    super(props);
    const Fecha = new Date();
    this.hoy = Fecha.toDateString();
    this.dataLocal = JSON.parse(localStorage.getItem("documento"));
    this.state = {
      numero_documento: this.dataLocal,
      estado_prestamo: "",
      Clientes: [],
      paginacion: -1,
      Cliente: [],
      Cliente_edit: false,
      pageNow: 1,
      id: "",
      clientesTabla: [],
      fecha_desembolso: "",
    };
    this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
    this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  updateUser = (e) => {
    e.preventDefault();
    let date = new Date();
    let fecha_opc =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();
    let estado = document.getElementById("estado_desembolso").value;
    console.log(estado);
    if (estado == "Finalizado") {
      var user = {
        estado_prestamo: "Finalizado Liquidado",
        fecha_finalizado: fecha_opc,
      };
      UserService.updateUserId(user, this.state.id).then((res) => {
        window.location = "/CreditosFinalizados";
      });
    } else {
      var user = { estado_prestamo: "En Liquidacion" };
      UserService.updateUserId(user, this.state.id).then((res) => {
        window.location = "/Liquidacion";
      });
    }
  };

  changeFirstNameHandler = (e) => {
    this.setState({ estado_desembolso: e.target.value });
  };

  changeLastNameHandler = (e) => {
    this.setState({ apellidos: e.target.apellidos });
  };

  changeEmailHandler = (e) => {
    this.setState({ empresa: e.target.empresa });
  };

  deleteUser(numero_documento) {
    UserService.deleteUser(numero_documento).then((res) => {
      this.setState(res.data);
    });
  }

  viewUser(numero_documento) {
    window.location = `/view-user/${numero_documento}`;
  }

  editUser(objectCliente) {
    this.setState({
      Cliente_edit: objectCliente,
    });
  }

  componentDidMount() {
    UserService.getUsers().then((res) => {
      this.state.clientesTabla = res.data.Clientes;
      this.setState(res.data);
    });
    UserService.getUserById(this.state.numero_documento).then((res) => {
      this.setState(res.data);
    });
  }

  update_id(nid) {
    this.setState({ id: nid });
  }

  addUser() {
    this.props.history.push("/add-user/_add");
  }

  despues = () => {
    if (
      this.state.clientesTabla.filter(
        (cliente) => cliente.estado_prestamo == "En Liquidacion"
      ).length >=
        this.state.paginacion + 4 &&
      this.state.paginacion + 4 <
        this.state.clientesTabla.filter(
          (cliente) => cliente.estado_prestamo == "En Liquidacion"
        ).length -
          1
    ) {
      this.setState({ ...this.state, paginacion: this.state.paginacion + 4 });
    }
  };

  antes = () => {
    if (this.state.paginacion - 4 >= -1) {
      this.setState({ ...this.state, paginacion: this.state.paginacion - 4 });
    }
    if (this.state.paginacion - 4 <= 2) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };

  ultima = () => {
    if (
      this.state.clientesTabla.filter(
        (cliente) => cliente.estado_prestamo === "En Liquidacion"
      ).length >= this.state.paginacion &&
      this.state.clientesTabla.filter(
        (cliente) => cliente.estado_prestamo === "En Liquidacion"
      ).length > 4
    ) {
      this.setState({
        ...this.state,
        paginacion:
          this.state.clientesTabla.filter(
            (cliente) => cliente.estado_prestamo === "En Liquidacion"
          ).length - 4,
      });
    }
  };

  primera = () => {
    if (this.state.paginacion - 4 >= -1) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };

  reporteLiquidacion = () => {
    const XLSX = require("xlsx");

    // Obtener los datos de la tabla HTML
    const table = document.getElementById("tablaexcel1");

    // Convertir la tabla HTML a una matriz de datos
    const data = Array.from(table.rows).map((row) =>
      Array.from(row.cells).map((cell) => cell.innerText)
    );

    // Crear un nuevo libro de Excel
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Añadir la hoja de cálculo al libro de Excel
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Descargar el archivo de Excel
    XLSX.writeFile(wb, "ReporteLiquidacion.xlsx");
  };

  filtrarEmpre = () => {
    console.log(this.state.filtroEmpresa);
    let clientesTemp = [];
    this.state.Clientes.forEach((e) => {
      let tempEmp = e.empresa + "";
      let tempDoc = e.numero_documento + "";
      if (
        tempEmp.search(this.state.filtroEmpresa) > -1 &&
        tempDoc.search(this.state.filtroDocumento) > -1
      ) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    this.primera();
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <br></br>
          <div className="row titulogeneral">
            <h2 className="text-center3 ">Créditos Liquidación</h2>
            <br></br>
            <br></br>
          </div>

          <div className="titulosecundario">
            <div>
              <h2 className="text-center3">Reportes</h2>
              <br></br>
            </div>
          </div>

          <div className="reportes">
            <button
              className="btn btn-danger esp"
              onClick={this.reporteLiquidacion}
            >
              Reporte Liquidación
            </button>
          </div>

          <div className="row titulosecundario">
            <div>
              <h2 className="text-center3">Filtros</h2>
              <br></br>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-3 offset-md-6">
              <form className="form-inline ml-3">
                <input
                  className="form-control casi1"
                  type="text"
                  placeholder="Filtrar numero documento"
                  onChange={(e) => {
                    this.state.filtroDocumento = e.target.value;
                  }}
                  onKeyUp={(e) => this.filtrarEmpre()}
                />
              </form>
            </div>
            <select
              id="fempre"
              className=" col-2 imput-select form-control casi"
              required
              onChange={(e) => {
                this.state.filtroEmpresa = e.target.value;
              }}
              onClick={(e) => this.filtrarEmpre()}
            >
              <option value="">Filtrar por empresa</option>
              <option value="INTEVO">INTEVO</option>
              <option value="BONGO ANALYTICS">BONGO ANALYTICS</option>
              <option value="UNION TEMPORAL">UNIÓN TEMPORAL</option>
              <option value="SMARTEC">SMARTEC</option>
              <option value="MEGAFAST">MEGAFAST</option>
              <option value="PEAR SOLUTIONS">PEAR SOLUTION</option>
              <option value="TERUNGAMES">TERUNGAMES</option>
            </select>
          </div>
          <div className="col-6 text-center2"></div>
          <div className="row">
            <table
              className="table table-striped table-bordered"
              id="tablaexcel"
            >
              <thead>
                <tr className="titulos-tabla2">
                  <th> Editar</th>
                  <th> Fecha Liquidación</th>
                  <th> Crédito</th>
                  <th> Cédula</th>
                  <th> Nombres Completos</th>
                  <th> Empresa</th>
                  <th> Fecha Solicitud</th>
                  <th> Fecha de Desembolso</th>
                  <th> Monto Solicitado</th>
                  <th> Cuotas</th>
                  <th> TED</th>
                  <th> Valor Cuota</th>
                  <th> Estado Cuota1</th>
                  <th> Estado Cuota2</th>
                  <th> Estado Cuota3</th>
                  <th> Tarifa Administrativa</th>
                  <th> Consulta Perfil</th>
                  <th> Consulta Gastos Administrativos</th>
                  <th> Saldo Fecha Liquidación</th>
                  <th> Valor a Pagar Normalizar</th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientesTabla
                  .filter(
                    (cliente) => cliente.estado_prestamo == "En Liquidacion"
                  )
                  .map((cliente, index) => {
                    return (
                      index > this.state.paginacion &&
                      index <= this.state.paginacion + 4 && (
                        <tr key={cliente.numero_documento}>
                          {cliente.estado_prestamo == "En Liquidacion" ? (
                            // <button onClick={ () => {this.editUser(cliente.numero_documento)}}><BsPencilSquare/></button>
                            <button
                              onClick={() => {
                                this.editUser(cliente);
                                this.update_id(cliente._id);
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#editadesembolso"
                            >
                              <BsPencilSquare />
                            </button>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {/* {cliente.estado_prestamo=="En Liquidacion"?<td>#{cliente._id}</td>:<td style={{display :`none`}}></td>} */}
                          <td>{cliente.fecha_liquidacion}</td>
                          <td>#{cliente._id}</td>
                          <td>{cliente.numero_documento}</td>
                          <td>{cliente.nombres + " " + cliente.apellidos}</td>
                          <td>{cliente.empresa}</td>
                          <td>{cliente.fecha_solicitud}</td>
                          <td>{cliente.fecha_desembolso}</td>
                          <td>{cliente.monto_prestamo}</td>
                          <td>{cliente.cuotas}</td>
                          <td>{(cliente.tasa_interes).toString().substring(0,7)}</td>
                          <td>{cliente.monto_cuotas}</td>
                          <td>{cliente.cuota1}</td>
                          <td>{cliente.cuota2}</td>
                          <td>{cliente.cuota3}</td>
                          <td>{cliente.tarifa_administrativa}</td>
                          <td>{cliente.consulta_perfil}</td>
                          <td>{cliente.consulta_gastos_admin}</td>
                          <td>{cliente.saldo_fecha_liquidacion}</td>
                          <td>{cliente.valor_normalizado}</td>
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
          </div>
          <table
            className="table table-striped table-bordered"
            id="tablaexcel1"
            style={{ display: `none` }}
          >
            <thead>
              <tr className="titulos-tabla2">
                <th> Empresa</th>
                <th> Cedula</th>
                <th> Nombre Empleado</th>
                <th> Descuento</th>
                <th> Valor a Pagar para Normalizar</th>
                <th> Monto Solicitado</th>
                <th> Saldo a la Fecha Liquidación</th>
                <th> Tarifa Administrativa</th>
                <th> Consulta Perfil</th>
              </tr>
            </thead>
            <tbody>
              {this.state.clientesTabla
                .filter(
                  (cliente) => cliente.estado_prestamo === "En Liquidacion"
                )
                .map((cliente) => {
                  return (
                    <tr key={cliente.numero_documento}>
                      <td>{cliente.empresa}</td>
                      <td>{cliente.numero_documento}</td>
                      <td>{cliente.nombres + " " + cliente.apellidos}</td>
                      <td>ACTIVO</td>
                      <td>{cliente.valor_normalizado}</td>
                      <td>{cliente.monto_prestamo}</td>
                      <td>{cliente.saldo_fecha_liquidacion}</td>
                      <td>{cliente.tarifa_administrativa}</td>
                      <td>{cliente.consulta_perfil}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="row col-12 ">
          <div className="col-1 offset-md-4">
            <button className="Bback" onClick={this.primera}>
              <BsFillSkipBackwardFill />
            </button>
          </div>
          <div className="col-1">
            <button className="Bback" onClick={this.antes}>
              <BsFillCaretLeftFill />
            </button>
          </div>
          <div className="col-1">
            <span>{Math.ceil(this.state.paginacion / 4 + 1)}</span> de{" "}
            <span>
              {" "}
              {Math.ceil(
                this.state.clientesTabla.filter(
                  (cliente) => cliente.estado_prestamo == "En Liquidacion"
                ).length / 4
              )}
            </span>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.despues}>
              <BsFillCaretRightFill />
            </button>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.ultima}>
              <BsFillSkipForwardFill />
            </button>
          </div>
        </div>
        <div
          class="modal fade"
          id="editadesembolso"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Editar Pago Liquidación
                </h5>
                {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
              </div>
              <div class="modal-body">
                {
                  <form key={this.state.Cliente_edit.numero_documento}>
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit._id}
                      />
                      <label>Nombres</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.nombres}
                      />
                      <label>Apellidos</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.apellidos}
                      />
                      <label>Número de Documento</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.numero_documento}
                      />
                      <label>Empresa</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.empresa}
                      />
                      <label>Valor Prestamo</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.monto_prestamo}
                      />
                      <label>Estado Desembolso</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.estado_desembolso}
                      />
                      <label>Valor a Normalizar</label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          "$" +
                          new Intl.NumberFormat().format(
                            this.state.Cliente_edit.valor_normalizado
                          )
                        }
                      />
                      <label>Pago Liquidacion</label>
                      <select id="estado_desembolso" className="form-control">
                        <option value="Pendiente">Pendiente</option>
                        <option value="Finalizado">Pagado</option>
                      </select>
                    </div>
                  </form>
                }
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#confirmationdesembolso"
                >
                  Guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          id="confirmationdesembolso"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Confirmar cambios
                </h5>
              </div>
              <div class="modal-body" style={{ marginLeft: "50px" }}>
                ¿Estas seguro de confirmar cambios realizados?
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    this.updateUser(e);
                  }}
                >
                  Sí, guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#editadesembolso"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Liquidacion;
