//PENDIENTE POR DESEMBOLSO
import React, { Component } from 'react';
import UserService from '../../services/UserService';
import { BsPencilSquare } from 'react-icons/bs';
import { BsFillCaretLeftFill } from 'react-icons/bs';
import { BsFillCaretRightFill } from 'react-icons/bs';
import { BsFillSkipBackwardFill } from 'react-icons/bs';
import { BsFillSkipForwardFill } from 'react-icons/bs';
import Header from './Header';
import Footer from './Footer';
import Menu from './Menu';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { BsFillFileEarmarkExcelFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import '../../css/ListUserComponent2.css';
import axios from 'axios';

class ListUserComponent2 extends Component {
    hoy = null;
    constructor(props) {
        super(props);
        const Fecha = new Date();
        this.hoy = Fecha.toDateString();
        this.dataLocal = JSON.parse(localStorage.getItem('documento'));
        this.state = {
            numero_documento: this.dataLocal,
            estado_prestamo: '',
            Clientes: [],
            paginacion: -1,
            Cliente: [],
            Cliente_edit: false,
            pageNow: 1,
            id: '',
            clientesTabla: [],
            fecha_desembolso: '',
            email: '',
        };
        this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
        this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
        this.updateUser = this.updateUser.bind(this);
    }

    updateUser = async (e) => {
        e.preventDefault();
        let date = new Date();
        let fecha_desembolso =
            String(date.getDate()).padStart(2, '0') +
            '/' +
            String(date.getMonth() + 1).padStart(2, '0') +
            '/' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes() +
            ':' +
            date.getSeconds();

        let fechaDes = fecha_desembolso;
        let concat = document.getElementById('estado_desembolso').value;
        console.log(concat);

        let estadoDemsembolso =
            document.getElementById('estado_desembolso').value;
        const inputRechazado2 = document.getElementById(
            'rechazados_ob_button'
        ).value;
        const inputRechazado3 = document.getElementById('cancel-info1');
        if (estadoDemsembolso === 'Rechazado' && inputRechazado2 === '') {
            document.getElementById('rechazados_ob_phara').style.display =
                'block';
            inputRechazado3.click();
        } else {
            if (concat === 'Rechazado') {
                let email = `michael.pena@bongoanalytics.com`;
                let emailUser = `${this.state.email}`;
                let info = JSON.stringify({
                    email: emailUser,
                    id: this.state.id,
                    emailcco: email,
                });

                axios
                    .post(
                        //PRODUCCION
                        'https://apiemail.tubongo.com/email-denied-user',
                        //info,
                        //PRE PRODUCCIÓN
                        // `http://190.109.16.229:1092/email-denied-user`,
                         info
                    )
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
            if (concat === 'Desembolsado') {
                let email = `michael.pena@bongoanalytics.com`;
                let emailUser = `${this.state.email}`;
                let info = JSON.stringify({
                    email: emailUser,
                    id: this.state.id,
                    emailcco: email,
                });

               

                axios
                    .post(
                        //PRODUCCION
                        'https://apiemail.tubongo.com/send-pdf', info
                        // PRE PRODUCCION
                        //  'http://190.109.16.229:1092/send-pdf', info
                        )
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }

             //logica para calcular en horas de lo que se demore entre el estado de aprobado a desembolsado

                let userData = await UserService.getUserByi(this.state.id);

                let fecha_aprobado = userData.data.Cliente[0].fecha_aprobado;
                
                let fecha_actual = new Date();

                let fecha_aprobado1 = new Date(
                  fecha_aprobado.replace(
                        /(\d{2})\/(\d{2})\/(\d{4})/,
                        '$2/$1/$3'
                    )
                );

                let diferenciaEnTiempo =
                    fecha_actual.getTime() - fecha_aprobado1.getTime();

                  

                let diferenciaEnHoras = diferenciaEnTiempo / (1000 * 60 * 60);

                

            document.getElementById('rechazados_ob_phara').style.display =
                'none';
            let inputRechazado = document.getElementById(
                'rechazados_ob_button'
            ).value;
            let user = {
                estado_desembolso: concat,
                fecha_desembolso: fechaDes,
                observaciones_rechazado: inputRechazado,
                diferencia_horas_ad:diferenciaEnHoras
            };
            console.log('user => ' + JSON.stringify(user));
            console.log('_id => ' + JSON.stringify(this.state.id));
            UserService.updateUserId(user, this.state.id).then((res) => {
                window.location = '/PendienteDesembolso';
            });
        }

        //consumir el servicio para el envio de contrato
    };

    changeFirstNameHandler = (e) => {
        this.setState({ estado_desembolso: e.target.value });
    };

    changeLastNameHandler = (e) => {
        this.setState({ apellidos: e.target.apellidos });
    };

    changeEmailHandler = (e) => {
        this.setState({ empresa: e.target.empresa });
    };

    deleteUser(numero_documento) {
        UserService.deleteUser(numero_documento).then((res) => {
            this.setState(res.data);
        });
    }

    viewUser(numero_documento) {
        window.location = `/view-user/${numero_documento}`;
    }

    editUser(objectCliente) {
        this.setState({
            Cliente_edit: objectCliente,
        });
    }

    componentDidMount() {
        UserService.getUsers().then((res) => {
            this.state.clientesTabla = res.data.Clientes;
            this.setState(res.data);
        });
        UserService.getUserById(this.state.numero_documento).then((res) => {
            this.setState(res.data);
        });
    }

    reportePendientes = () => {
        const XLSX = require('xlsx');

        // Obtener los datos de la tabla HTML
        const table = document.getElementById('tablaexcel1');

        // Convertir la tabla HTML a una matriz de datos
        const data = Array.from(table.rows).map((row) =>
            Array.from(row.cells).map((cell) => cell.innerText)
        );

        // Crear un nuevo libro de Excel
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(data);

        // Añadir la hoja de cálculo al libro de Excel
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Descargar el archivo de Excel
        XLSX.writeFile(wb, 'ReportePendientes.xlsx');
    };

    update_id(nid, correo) {
        this.setState({ id: nid, email: correo });
    }

    addUser() {
        this.props.history.push('/add-user/_add');
    }

    despues = () => {
        if (
            this.state.clientesTabla.filter(
                (cliente) =>
                    cliente.estado_desembolso === 'Pendiente' &&
                    cliente.estado_prestamo === 'Aprobado'
            ).length >=
                this.state.paginacion + 4 &&
            this.state.paginacion + 4 <
                this.state.clientesTabla.filter(
                    (cliente) =>
                        cliente.estado_desembolso === 'Pendiente' &&
                        cliente.estado_prestamo === 'Aprobado'
                ).length -
                    1
        ) {
            this.setState({
                ...this.state,
                paginacion: this.state.paginacion + 4,
            });
        }
    };

    antes = () => {
        if (this.state.paginacion - 4 >= -1) {
            this.setState({
                ...this.state,
                paginacion: this.state.paginacion - 4,
            });
        }
        if (this.state.paginacion - 4 <= 2) {
            this.setState({
                ...this.state,
                paginacion: (this.state.paginacion = -1),
            });
        }
    };

    ultima = () => {
        if (
            this.state.clientesTabla.filter(
                (cliente) =>
                    cliente.estado_desembolso === 'Pendiente' &&
                    cliente.estado_prestamo === 'Aprobado'
            ).length >= this.state.paginacion &&
            this.state.clientesTabla.filter(
                (cliente) =>
                    cliente.estado_desembolso === 'Pendiente' &&
                    cliente.estado_prestamo === 'Aprobado'
            ).length > 8
        ) {
            this.setState({
                ...this.state,
                paginacion:
                    this.state.clientesTabla.filter(
                        (cliente) =>
                            cliente.estado_desembolso === 'Pendiente' &&
                            cliente.estado_prestamo === 'Aprobado'
                    ).length - 4,
            });
        }
    };

    primera = () => {
        if (this.state.paginacion - 4 >= -1) {
            this.setState({
                ...this.state,
                paginacion: (this.state.paginacion = -1),
            });
        }
    };

    inputRechazado = () => {
        let estadoDemsembolso =
            document.getElementById('estado_desembolso').value;
        if (estadoDemsembolso === 'Rechazado') {
            document.getElementById('rechazados_ob_label').style.display =
                'block';
            document.getElementById('rechazados_ob_button').style.display =
                'block';
        } else {
            document.getElementById('rechazados_ob_label').style.display =
                'none';
            document.getElementById('rechazados_ob_button').style.display =
                'none';
            document.getElementById('rechazados_ob_phara').style.display =
                'none';
        }
    };
    inputempty() {}

    // filtrarEmpre = () => {
    //   let clientesTemp = [];
    //   this.state.Clientes.forEach(e =>{
    //     // let tempName = e.nombres.toUpperCase() + '';
    //     let tempEmp = e.empresa+ '';
    //     let tempDoc = e.numero_documento + '';
    //     if(tempEmp.search(this.state.filtroEmpresa.toUpperCase()) > -1 && (tempDoc.search(this.state.filtroDocumento.toUpperCase()) > -1)){
    //       clientesTemp.push(e);
    //     }
    //   })
    //   this.setState({
    //     clientesTabla: clientesTemp,
    //   })
    //   this.primera()

    // }

    render() {
        return (
            <div>
                <Header />
                <Menu />
                <div className="content-wrapper">
                    <br></br>
                    <div className="row">
                        <div className="col-6">
                            <h2 className="text-center2">
                                Pendientes Desembolso
                            </h2>
                            <br></br>
                        </div>
                        <div className="col-6 text-center2"></div>
                    </div>

                    <div className="reportes">
                        <button
                            className="btn btn-danger esp"
                            onClick={this.reportePendientes}
                        >
                            Descargar Reporte
                        </button>
                    </div>
                    <div className="row">
                        <table
                            className="table table-striped table-bordered"
                            id="tablaexcel"
                        >
                            <thead>
                                <tr className="titulos-tabla2">
                                    <th> Editar</th>
                                    <th> ID</th>
                                    <th> Nombres</th>
                                    <th> Apellidos</th>
                                    <th> Número de Documento</th>
                                    <th> Empresa</th>
                                    <th> Celular</th>
                                    <th> Tipo Billetera</th>
                                    <th> Número Billetera</th>
                                    <th> Estado Prestamo</th>
                                    <th> Estado Desembolso</th>
                                    <th> Valor Prestamo</th>
                                    <th> # de Cuotas</th>
                                    <th> Fecha de Solicitud</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.clientesTabla
                                    .filter(
                                        (cliente) =>
                                            cliente.estado_desembolso ==
                                                'Pendiente' &&
                                            cliente.estado_prestamo !=
                                                'Preaprobado'
                                    )
                                    .map((cliente, index) => {
                                        return (
                                            index > this.state.paginacion &&
                                            index <=
                                                this.state.paginacion + 4 && (
                                                <tr
                                                    key={
                                                        cliente.numero_documento
                                                    }
                                                >
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        // <button onClick={ () => {this.editUser(cliente.numero_documento)}}><BsPencilSquare/></button>
                                                        <button
                                                            onClick={() => {
                                                                this.editUser(
                                                                    cliente
                                                                );
                                                                this.update_id(
                                                                    cliente._id,
                                                                    cliente.correo
                                                                );
                                                            }}
                                                            className="btn btn-primary"
                                                            data-toggle="modal"
                                                            data-target="#editadesembolso"
                                                        >
                                                            <BsPencilSquare />
                                                        </button>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>#{cliente._id}</td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {cliente.nombres}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {cliente.apellidos}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.numero_documento
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {cliente.empresa}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {cliente.celular}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.metodo_desembolso
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.numero_billetera
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.estado_prestamo
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.estado_desembolso
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {' '}
                                                            $
                                                            {new Intl.NumberFormat().format(
                                                                cliente.monto_prestamo
                                                            )}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {' '}
                                                            {cliente.cuotas}
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {cliente.estado_desembolso ==
                                                    'Pendiente' ? (
                                                        <td>
                                                            {
                                                                cliente.fecha_solicitud
                                                            }
                                                        </td>
                                                    ) : (
                                                        <td
                                                            style={{
                                                                display: `none`,
                                                            }}
                                                        ></td>
                                                    )}
                                                    {/* <button style={{marginLeft: "10px"}} onClick={ () => this.deleteUser(cliente.numero_documento)} className="btn btn-danger">Delete </button> */}
                                                    {/* <button style={{marginLeft: "10px"}} onClick={ () => this.viewUser(cliente.numero_documento)} className="btn btn-info">View </button> */}
                                                </tr>
                                            )
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                    <table
                        className="table table-striped table-bordered"
                        id="tablaexcel1"
                        style={{ display: `none` }}
                    >
                        <thead>
                            <tr className="titulos-tabla2">
                                <th> ID</th>
                                <th> Nombres</th>
                                <th> Apellidos</th>
                                <th> Número de Documento</th>
                                <th> Empresa</th>
                                <th> Celular</th>
                                <th> Tipo Billetera</th>
                                <th> Numero Billetera</th>
                                <th> Estado Prestamo</th>
                                <th> Estado Desembolso</th>
                                <th> Valor Prestamo</th>
                                <th> # de Cuotas</th>
                                <th> Fecha de Solicitud</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.Clientes.map((cliente) =>
                                cliente.estado_desembolso === 'Pendiente' &&
                                cliente.estado_prestamo !== 'Preaprobado' ? (
                                    <tr key={cliente.numero_documento}>
                                        <td>{cliente._id}</td>
                                        <td>{cliente.nombres}</td>
                                        <td>{cliente.apellidos}</td>
                                        <td>{cliente.numero_documento}</td>
                                        <td>{cliente.empresa}</td>
                                        <td>{cliente.celular}</td>
                                        <td>{cliente.metodo_desembolso}</td>
                                        <td>{cliente.numero_billetera}</td>
                                        <td>{cliente.estado_prestamo}</td>
                                        <td>{cliente.estado_desembolso}</td>
                                        <td>{cliente.monto_prestamo}</td>
                                        <td>{cliente.cuotas}</td>
                                        <td>{cliente.fecha_solicitud}</td>
                                    </tr>
                                ) : null
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="row col-12 ">
                    <div className="col-1 offset-md-4">
                        <button className="Bback" onClick={this.primera}>
                            <BsFillSkipBackwardFill />
                        </button>
                    </div>
                    <div className="col-1">
                        <button className="Bback" onClick={this.antes}>
                            <BsFillCaretLeftFill />
                        </button>
                    </div>
                    <div className="col-1">
                        <span>{Math.ceil(this.state.paginacion / 4 + 1)}</span>{' '}
                        de{' '}
                        <span>
                            {' '}
                            {Math.ceil(
                                this.state.clientesTabla.filter(
                                    (cliente) =>
                                        cliente.estado_desembolso ===
                                            'Pendiente' &&
                                        cliente.estado_prestamo === 'Aprobado'
                                ).length / 4
                            )}
                        </span>
                    </div>
                    <div className="col-1">
                        <button className="Bnext" onClick={this.despues}>
                            <BsFillCaretRightFill />
                        </button>
                    </div>
                    <div className="col-1">
                        <button className="Bnext" onClick={this.ultima}>
                            <BsFillSkipForwardFill />
                        </button>
                    </div>
                </div>
                <div
                    class="modal fade"
                    id="editadesembolso"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-centered modal-lg"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title1" id="exampleModalLabel">
                                    Editar estado desembolso
                                </h5>
                                {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button> */}
                            </div>
                            <div class="modal-body">
                                {
                                    <form
                                        key={
                                            this.state.Cliente_edit
                                                .numero_documento
                                        }
                                        class="d-inline-block mr-3 form-width"
                                    >
                                        <div className="form-group">
                                            <label>ID</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit._id
                                                }
                                            />
                                            <label>Nombres</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .nombres
                                                }
                                            />
                                            <label>Apellidos</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .apellidos
                                                }
                                            />
                                            <label>Número de Documento</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .numero_documento
                                                }
                                            />
                                            <label>Empresa</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .empresa
                                                }
                                            />
                                            <label>Billetera</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .metodo_desembolso
                                                }
                                            />
                                            <label>Numero Billetera</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .numero_billetera
                                                }
                                            />
                                            <label>Valor Prestamo</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    '$' +
                                                    new Intl.NumberFormat().format(
                                                        this.state.Cliente_edit
                                                            .monto_prestamo
                                                    )
                                                }
                                            />
                                        </div>
                                    </form>
                                }

                                {
                                    <form
                                        key={
                                            this.state.Cliente_edit
                                                .numero_documento
                                        }
                                        class="d-inline-block form-width"
                                    >
                                        <div className="form-group">
                                            <label>Descuento de nomina</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .descuento_nomina
                                                }
                                            />
                                            <label>Intereses</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    '$' +
                                                    new Intl.NumberFormat().format(
                                                        this.state.Cliente_edit
                                                            .valor_interes
                                                    )
                                                }
                                            />
                                            <label>Tarifa Administrativa</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    '$' +
                                                    new Intl.NumberFormat().format(
                                                        this.state.Cliente_edit
                                                            .tarifa_administrativa
                                                    )
                                                }
                                            />
                                            <label>
                                                Tarifa Consulta Perfil
                                            </label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    '$' +
                                                    new Intl.NumberFormat().format(
                                                        this.state.Cliente_edit
                                                            .consulta_perfil
                                                    )
                                                }
                                            />
                                            <label>Valor de la cuota</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    '$' +
                                                    new Intl.NumberFormat().format(
                                                        this.state.Cliente_edit
                                                            .monto_cuotas
                                                    )
                                                }
                                            />
                                            <label>Fecha cuota 1</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .fecha_cuota
                                                }
                                            />
                                            <label>Fecha cuota 2</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .fechaestimada_cuota2
                                                }
                                            />
                                            <label>Fecha cuota 3</label>
                                            <input
                                                className="form-control"
                                                disabled
                                                value={
                                                    this.state.Cliente_edit
                                                        .fechaestimada_cuota3
                                                }
                                            />
                                        </div>
                                    </form>
                                }

                                {
                                    <form
                                        key={
                                            this.state.Cliente_edit
                                                .numero_documento
                                        }
                                    >
                                        <div className="form-group">
                                            <label>Estado Desembolso</label>
                                            <select
                                                id="estado_desembolso"
                                                className="form-control prueba"
                                                onChange={this.inputRechazado}
                                            >
                                                <option value="Pendiente">
                                                    Pendiente
                                                </option>
                                                <option value="Desembolsado">
                                                    Desembolsado
                                                </option>
                                                <option value="Rechazado">
                                                    Rechazado
                                                </option>
                                            </select>

                                            {/* Observaciones para los creditos que se rechazes */}
                                            <label
                                                id="rechazados_ob_label"
                                                style={{ display: `none` }}
                                            >
                                                Observaciones
                                            </label>
                                            <input
                                                id="rechazados_ob_button"
                                                className="form-control"
                                                style={{ display: `none` }}
                                                required
                                            />
                                            <p
                                                id="rechazados_ob_phara"
                                                style={{
                                                    display: `none`,
                                                    color: `red`,
                                                }}
                                            >
                                                Por favor diligenciar el campo
                                                observaciones
                                            </p>
                                        </div>
                                    </form>
                                }
                            </div>
                            <div class="modal-footer">
                                <button
                                    id="guardar_info"
                                    className="btn btn-success"
                                    data-toggle="modal"
                                    data-dismiss="modal"
                                    data-target="#confirmationdesembolso"
                                    onClick={this.inputempty}
                                >
                                    Guardar
                                </button>
                                <button
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="modal fade"
                    id="confirmationdesembolso"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div
                        class="modal-dialog modal-dialog-centered"
                        role="document"
                    >
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title1" id="exampleModalLabel">
                                    Confirmar cambios
                                </h5>
                            </div>
                            <div
                                class="modal-body"
                                style={{ marginLeft: '50px' }}
                            >
                                ¿Estas seguro de confirmar cambios realizados?
                            </div>
                            <div class="modal-footer">
                                <button
                                    id="confirm-info1"
                                    className="btn btn-success"
                                    onClick={(e) => {
                                        this.updateUser(e);
                                    }}
                                >
                                    Sí, guardar
                                </button>
                                <button
                                    id="cancel-info1"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    data-toggle="modal"
                                    data-target="#editadesembolso"
                                    style={{ marginLeft: '10px' }}
                                >
                                    Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ListUserComponent2;
