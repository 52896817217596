// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_navbar__MLP6c {\r\n\twidth: 100%;\r\n\theight: 70px;\r\n\tbackground-color: #3bb19b;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n}\r\n\r\n.styles_navbar__MLP6c h1 {\r\n\tcolor: white;\r\n\tfont-size: 25px;\r\n\tmargin-left: 20px;\r\n}\r\n\r\n.styles_white_btn__CTYZN {\r\n\tborder: none;\r\n\toutline: none;\r\n\tpadding: 12px 0;\r\n\tbackground-color: white;\r\n\tborder-radius: 20px;\r\n\twidth: 120px;\r\n\tfont-weight: bold;\r\n\tfont-size: 14px;\r\n\tcursor: pointer;\r\n\tmargin-right: 20px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Main/styles.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,yBAAyB;CACzB,aAAa;CACb,mBAAmB;CACnB,8BAA8B;AAC/B;;AAEA;CACC,YAAY;CACZ,eAAe;CACf,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,aAAa;CACb,eAAe;CACf,uBAAuB;CACvB,mBAAmB;CACnB,YAAY;CACZ,iBAAiB;CACjB,eAAe;CACf,eAAe;CACf,kBAAkB;AACnB","sourcesContent":[".navbar {\r\n\twidth: 100%;\r\n\theight: 70px;\r\n\tbackground-color: #3bb19b;\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: space-between;\r\n}\r\n\r\n.navbar h1 {\r\n\tcolor: white;\r\n\tfont-size: 25px;\r\n\tmargin-left: 20px;\r\n}\r\n\r\n.white_btn {\r\n\tborder: none;\r\n\toutline: none;\r\n\tpadding: 12px 0;\r\n\tbackground-color: white;\r\n\tborder-radius: 20px;\r\n\twidth: 120px;\r\n\tfont-weight: bold;\r\n\tfont-size: 14px;\r\n\tcursor: pointer;\r\n\tmargin-right: 20px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": "styles_navbar__MLP6c",
	"white_btn": "styles_white_btn__CTYZN"
};
export default ___CSS_LOADER_EXPORT___;
