//CONTROL DE PAGOS
import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Menu from "./Menu";
import UserService from "../../services/UserService";
import CalculosLiquidacion from "./CalculosLiquidacion";
import CargaExcelPagos from "../CargaExcelPagos/CargaExcelPagos";
import { BsPencilSquare } from "react-icons/bs";
import { BsFillCaretLeftFill } from "react-icons/bs";
import { BsFillCaretRightFill } from "react-icons/bs";
import { BsFillSkipBackwardFill } from "react-icons/bs";
import { BsFillSkipForwardFill } from "react-icons/bs";
import { BsSearch } from "react-icons/bs";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BsFillFileEarmarkExcelFill } from "react-icons/bs";
import "../../css/ListUserComponent3.css";

class ControlPagos extends Component {
  hoy = null;
  constructor(props) {
    super(props);
    const Fecha = new Date();
    this.hoy = Fecha.toDateString();
    this.dataLocal = JSON.parse(localStorage.getItem("documento1"));
    this.state = {
      clientesTabla: [],
      id: "",
      numero_documento: this.dataLocal,
      estado_prestamo: "",
      cuota1: "",
      cuota2: "",
      cuota3: "",
      Clientes: [],
      paginacion: -1,
      Cliente: [],
      Cliente_edit: false,
      pageNow: 1,
      filtroDocumento: "",
      filtroNumeroId: "",
      cuotasTotal: "",
      filtroEmpresa: "",
      fecha_cuota1: "",
      fecha_cuota2: "",
      fecha_cuota3: "",
      fechapago1: "",
      fechapago2: "",
      fechapago3: "",
      saldofecha2: "",
      saldofecha3: "",
      fecha_finalizado: "",
      fecha_desembolso: "",
      tasa_interes: 0,
      monto_prestamo: 0,
      tarifa_administrativa: 0,
      consulta_perfil: 0,
      valor_cuota: 0,
      fecha_solicitud: " ",
    };
    this.addUser = this.addUser.bind(this);
    this.editUser = this.editUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);

    this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
    this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
    this.updateUser = this.updateUser.bind(this);
  }

  componentDidMount() {
    UserService.getUsers().then((res) => {
      this.state.clientesTabla = res.data.Clientes;
      this.setState(res.data);
    });
  }

  changeFirstNameHandler = (e) => {
    this.setState({ estado_prestamo: e.target.value });
  };

  changeLastNameHandler = (e) => {
    this.setState({ apellidos: e.target.apellidos });
  };

  changeEmailHandler = (e) => {
    this.setState({ empresa: e.target.empresa });
  };

  imprimirFechaFormateada = (Fecha) => {
    if (!Fecha || Fecha === "null") {
      return "No Aplica"; // Retorna una cadena vacía si no se proporciona una fecha válida
    }
    var partes = Fecha.split("/");
    var dia = partes[0];
    var mes = partes[1];
    var anio = partes[2];

    // Obtener el nombre del mes correspondiente
    var meses = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];
    var mesNombre = meses[parseInt(mes, 10) - 1];

    // Construir la fecha formateada
    var fechaFormateada = dia + " " + mesNombre + " " + anio;

    // Retornar la fecha formateada
    return fechaFormateada;
  };

  updateUser = (e) => {
    e.preventDefault();

    let date = new Date();
    let fecha_opc =
      String(date.getDate()).padStart(2, "0") +
      "/" +
      String(date.getMonth() + 1).padStart(2, "0") +
      "/" +
      date.getFullYear() +
      " " +
      date.getHours() +
      ":" +
      date.getMinutes() +
      ":" +
      date.getSeconds();

    //Validacion fechas de cuotas
    let fecha_cuo1 = fecha_opc;
    let fecha_cuo2 = fecha_opc;
    let fecha_cuo3 = fecha_opc;
    let fecha_final = fecha_opc;

    let concat = document.getElementById("estado_prestamo").value;
    let concat1 = document.getElementById("cuota1").value;
    let concat2 = document.getElementById("cuota2").value;
    let concat3 = document.getElementById("cuota3").value;

    let valor = document.getElementById("cuota1").value;
    let valor1 = document.getElementById("cuota2").value;
    let valor2 = document.getElementById("cuota3").value;

    //Validacion bloque estado

    let valorcuota = document.getElementById("valorc").value;

    switch (valorcuota) {
      case "1":
        if (
          valor === "Pendiente" &&
          valor1 === "No Aplica" &&
          valor2 === "No Aplica"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
        } else {
          concat = "Finalizado";
          fecha_cuo1 = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota1: fecha_cuo1,
            fecha_finalizado: fecha_final,
            fecha_cuota2: "No Aplica",
            fecha_cuota3: "No Aplica",
          };
        }
        break;
      case "2":
        if (
          valor === "Pendiente" &&
          valor1 === "Pendiente" &&
          valor2 === "No Aplica"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
        } else if (
          valor === "Pagada" &&
          valor1 === "Pendiente" &&
          valor2 === "No Aplica"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
          fecha_cuo2 = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota1: fecha_cuo1,
          };
        } else {
          concat = "Finalizado";
          fecha_cuo2 = fecha_opc;
          fecha_final = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota2: fecha_cuo2,
            fecha_finalizado: fecha_final,
            fecha_cuota3: "No Aplica",
          };
        }
        break;
      case "3":
        if (
          valor === "Pendiente" &&
          valor1 === "Pendiente" &&
          valor2 === "Pendiente"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
        } else if (
          valor === "Pagada" &&
          valor1 === "Pendiente" &&
          valor2 === "Pendiente"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
          fecha_cuo1 = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota1: fecha_cuo1,
          };
        } else if (
          valor === "Pagada" &&
          valor1 === "Pagada" &&
          valor2 === "Pendiente"
        ) {
          document.getElementById("estado_prestamo").disabled = true;
          fecha_cuo2 = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota2: fecha_cuo2,
          };
        } else {
          concat = "Finalizado";
          fecha_cuo3 = fecha_opc;
          fecha_final = fecha_opc;
          var user = {
            estado_prestamo: concat,
            cuota1: concat1,
            cuota2: concat2,
            cuota3: concat3,
            fecha_cuota3: fecha_cuo3,
            fecha_finalizado: fecha_final,
          };
        }
        break;
      default:
        break;
    }

    UserService.updateUserId(user, this.state.id).then((res) => {
      window.location = "/CreditosDesembolsados";
    });
  };

  actualizarLiquidacion = (e) => {
    e.preventDefault();

    let fecha_actual = new Date();
    let fecha_liquidacion =
      fecha_actual.getDate().toString().padStart(2, "0") +
      "/" +
      (fecha_actual.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      fecha_actual.getFullYear().toString() +
      " " +
      fecha_actual.getHours().toString().padStart(2, "0") +
      ":" +
      fecha_actual.getMinutes().toString().padStart(2, "0") +
      ":" +
      fecha_actual.getSeconds().toString().padStart(2, "0");

    // let fecha_liquidacion = "05/07/2023"

    // console.log(fecha_liquidacion.substring(0,10))
    // console.log(this.state.fecha_solicitud.substring(0,10))
    // console.log(this.state.monto_prestamo)
    // console.log((this.state.consulta_perfil+this.state.tarifa_administrativa)/this.state.cuotasTotal)
    // console.log(this.state.cuotasTotal)
    // console.log(this.state.fechapago1)
    // console.log(this.state.fechapago2)
    // console.log(this.state.fechapago3)
    // console.log(this.state.saldofecha2)
    // console.log(this.state.saldofecha3)
    // console.log(fecha_liquidacion.substring(0,2))
    // console.log(this.state.monto_prestamo)
    // console.log(this.state.tasa_interes)
    // console.log(this.state.fecha_solicitud.substring(0,10))

    //FECHA LIQUIDACION
    var partesFecha1 = fecha_liquidacion.substring(0, 10).split("/");
    var dia1 = partesFecha1[0];
    var mes1 = partesFecha1[1];
    var anio1 = partesFecha1[2];

    var nuevaFecha1 = anio1 + "-" + mes1 + "-" + dia1;
    console.log(nuevaFecha1);
    //FECHA SOLICITUD
    var partesFecha = this.state.fecha_solicitud.substring(0, 10).split("/");
    var dia = partesFecha[0];
    var mes = partesFecha[1];
    var anio = partesFecha[2];

    var nuevaFecha = anio + "-" + mes + "-" + dia;
    console.log(nuevaFecha);

    //FECHA FECHAPAGO1
    var partesFecha2 = this.state.fechapago1.split("/");
    var dia2 = 31;
    var mes2 = partesFecha2[1];
    var anio2 = partesFecha2[2];

    var nuevaFecha2 = anio2 + "-" + mes2 + "-" + dia2;
    console.log(nuevaFecha2);

    //FECHA FECHAPAGO2
    var partesFecha3 = this.state.fechapago2.split("/");
    var dia3 = 31;
    var mes3 = partesFecha3[1];
    var anio3 = partesFecha3[2];

    var nuevaFecha3 = anio3 + "-" + mes3 + "-" + dia3;
    console.log(nuevaFecha3);

    //FECHA FECHAPAGO3
    var partesFecha4 = this.state.fechapago3.split("/");
    var dia4 = 31;
    var mes4 = partesFecha4[1];
    var anio4 = partesFecha4[2];

    var nuevaFecha4 = anio4 + "-" + mes4 + "-" + dia4;
    console.log(nuevaFecha4);

    //DIFERENCIA EN DIAS
    var diferenciaMs = new Date(nuevaFecha1) - new Date(nuevaFecha);
    var diferenciaDias = Math.floor(diferenciaMs / (1000 * 60 * 60 * 24));

    console.log(diferenciaDias);

    let valoresADM =
      (this.state.consulta_perfil + this.state.tarifa_administrativa) /
      this.state.cuotasTotal;

    let fecha_desembolso = new Date(
      this.state.fecha_desembolso.replace(
        /(\d{2})\/(\d{2})\/(\d{4})/,
        "$2/$1/$3"
      )
    );

    let diferenciaEnTiempo =
      fecha_actual.getTime() - fecha_desembolso.getTime();
    let diferenciaEnDias = Math.round(
      diferenciaEnTiempo / (1000 * 60 * 60 * 24)
    );

    let intereses_causado =
      diferenciaEnDias * this.state.tasa_interes * this.state.monto_prestamo;
    let valor_causado =
      this.state.monto_prestamo +
      this.state.tarifa_administrativa +
      this.state.consulta_perfil +
      intereses_causado;
    let valor_normalizado = 0;

    let numero_cuotas = document.getElementById("valorc").value;
    let valor = document.getElementById("cuota1").value;
    let valor1 = document.getElementById("cuota2").value;
    let valor2 = document.getElementById("cuota3").value;

    const resultado = CalculosLiquidacion(
      nuevaFecha1,
      nuevaFecha,
      this.state.monto_prestamo,
      valoresADM,
      this.state.cuotasTotal,
      nuevaFecha2,
      nuevaFecha3,
      nuevaFecha4,
      this.state.saldofecha2,
      this.state.saldofecha3,
      fecha_liquidacion.substring(0, 2),
      this.state.monto_prestamo,
      this.state.tasa_interes,
      nuevaFecha
    );

    console.log(resultado.result.toFixed(0)); // Valor de "result"
    console.log(resultado.result1.toFixed(0)); // Valor de "result1"
    console.log(resultado.resultTotal.toFixed(0)); // Valor de "result + result1"

    switch (numero_cuotas) {
      case "1":
        if (
          valor === "Pendiente" &&
          valor1 === "No Aplica" &&
          valor2 === "No Aplica"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 0;
        }
        break;
      case "2":
        if (
          valor === "Pendiente" &&
          valor1 === "Pendiente" &&
          valor2 === "No Aplica"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 0;
        } else if (
          valor === "Pagada" &&
          valor1 === "Pendiente" &&
          valor2 === "No Aplica"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 1;
        }
        break;
      case "3":
        if (
          valor === "Pendiente" &&
          valor1 === "Pendiente" &&
          valor2 === "Pendiente"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 0;
        } else if (
          valor === "Pagada" &&
          valor1 === "Pendiente" &&
          valor2 === "Pendiente"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 1;
        } else if (
          valor === "Pagada" &&
          valor1 === "Pagada" &&
          valor2 === "Pendiente"
        ) {
          valor_normalizado = valor_causado - this.state.valor_cuota * 2;
        }
        break;
      default:
        break;
    }

    let datos_liquidacion = {
      fecha_liquidacion: fecha_liquidacion,
      estado_prestamo: "En Liquidacion",
      saldo_fecha_liquidacion: resultado.result.toFixed(0),
      consulta_gastos_admin: resultado.result1.toFixed(0),
      valor_normalizado: resultado.resultTotal.toFixed(0),
    };

    UserService.updateUserId(datos_liquidacion, this.state.id).then((res) => {
      window.location = "/Liquidacion";
    });
  };

  deleteUser(numero_documento) {
    UserService.deleteUser(numero_documento).then((res) => {
      this.setState(res.data);
    });
  }

  update_id(
    nid,
    fd,
    ti,
    mp,
    ta,
    cp,
    vc,
    c1,
    c2,
    c3,
    fs,
    ct,
    fp1,
    fp2,
    fp3,
    sf2,
    sf3
  ) {
    this.setState({
      id: nid,
      fecha_desembolso: fd,
      tasa_interes: ti,
      monto_prestamo: mp,
      cuotasTotal: ct,
      fecha_solicitud: fs,
      tarifa_administrativa: ta,
      consulta_perfil: cp,
      valor_cuota: vc,
      fechapago1: fp1,
      fechapago2: fp2,
      fechapago3: fp3,
      saldofecha2: sf2,
      saldofecha3: sf3,
      cuota1: c1,
      cuota2: c2,
      cuota3: c3,
    });
  }

  viewUser(numero_documento) {
    window.location = `/view-user/${numero_documento}`;
  }

  editUser(objectCliente) {
    this.setState({
      Cliente_edit: objectCliente,
    });

    UserService.getUserById(this.state.numero_documento).then((res) => {
      this.setState(res.data);

      //Validacion bloqueo de campos cuotas
      let valor = document.getElementById("cuota1").value;
      let valor1 = document.getElementById("cuota2").value;
      let valor2 = document.getElementById("cuota3").value;

      if (valor1 === "Pagada") {
        document.getElementById("cuota2").disabled = true;
      }
      if (valor === "Pagada") {
        document.getElementById("cuota1").disabled = true;
      }
      if (valor2 === "Pagada") {
        document.getElementById("cuota3").disabled = true;
      }

      //Validacion bloque estado

      let valorcuota = document.getElementById("valorc").value;


      switch (valorcuota) {
        case "1":
          if (
            valor === "Pendiente" &&
            valor1 === "No Aplica" &&
            valor2 === "No Aplica"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
          } else {
            document.getElementById("estado_prestamo").disabled = false;
          }
          break;
        case "2":
          if (
            valor === "Pendiente" &&
            valor1 === "Pendiente" &&
            valor2 === "No Aplica"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
            document.getElementById("cuota2").disabled = true;
          } else if (
            valor === "Pagada" &&
            valor1 === "Pendiente" &&
            valor2 === "No Aplica"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
          } else {
            document.getElementById("estado_prestamo").disabled = false;
            document.getElementById("cuota2").disabled = true;
          }
          break;
        case "3":
          if (
            valor === "Pendiente" &&
            valor1 === "Pendiente" &&
            valor2 === "Pendiente"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
            document.getElementById("cuota2").disabled = true;
            document.getElementById("cuota3").disabled = true;
          } else if (
            valor === "Pagada" &&
            valor1 === "Pendiente" &&
            valor2 === "Pendiente"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
            document.getElementById("cuota3").disabled = true;
          } else if (
            valor === "Pagada" &&
            valor1 === "Pagada" &&
            valor2 === "Pendiente"
          ) {
            document.getElementById("estado_prestamo").disabled = true;
          } else {
            document.getElementById("estado_prestamo").disabled = false;
          }
          break;
        default:
          break;
      }
    });
  }

  addUser() {
    this.props.history.push("/add-user/_add");
  }

  despues = () => {
    if (
      this.state.clientesTabla.filter(
        (cliente) =>
          cliente.estado_prestamo === "Aprobado" &&
          cliente.estado_desembolso === "Desembolsado"
      ).length >=
        this.state.paginacion + 4 &&
      this.state.paginacion + 4 <
        this.state.clientesTabla.filter(
          (cliente) =>
            cliente.estado_prestamo === "Aprobado" &&
            cliente.estado_desembolso === "Desembolsado"
        ).length -
          1
    ) {
      this.setState({ ...this.state, paginacion: this.state.paginacion + 4 });
    }
  };

  antes = () => {
    if (this.state.paginacion - 4 >= -1) {
      this.setState({ ...this.state, paginacion: this.state.paginacion - 4 });
    }
    if (this.state.paginacion - 4 <= 2) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };

  ultima = () => {
    if (
      this.state.clientesTabla.filter(
        (cliente) =>
          cliente.estado_prestamo === "Aprobado" &&
          cliente.estado_desembolso === "Desembolsado"
      ).length >= this.state.paginacion &&
      this.state.clientesTabla.filter(
        (cliente) =>
          cliente.estado_prestamo === "Aprobado" &&
          cliente.estado_desembolso === "Desembolsado"
      ).length > 8
    ) {
      this.setState({
        ...this.state,
        paginacion:
          this.state.clientesTabla.filter(
            (cliente) =>
              cliente.estado_prestamo === "Aprobado" &&
              cliente.estado_desembolso === "Desembolsado"
          ).length - 4,
      });
    }
  };

  primera = () => {
    if (this.state.paginacion - 4 >= 0) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };

  empezar = () => {
    if (this.state.paginacion > -1) {
      this.setState({
        ...this.state,
        paginacion: (this.state.paginacion = -1),
      });
    }
  };

  filtrarEmpre = () => {
    let clientesTemp = [];
    this.state.Clientes.forEach((e) => {
      // let tempName = e.nombres.toUpperCase() + '';
      let tempEmp = e.empresa + "";
      let tempDoc = e.numero_documento + "";
      let tempId = e._id + "";
      if (
        tempEmp.search(this.state.filtroEmpresa.toUpperCase()) > -1 &&
        tempDoc.search(this.state.filtroDocumento.toUpperCase()) > -1 &&
        tempId.search(this.state.filtroNumeroId.toUpperCase()) > -1
      
      ) {
        clientesTemp.push(e);
      }
    });
    this.setState({
      clientesTabla: clientesTemp,
    });
    setTimeout(() => {
      this.empezar();
    }, 1000);
  };

  cancela = () => {
    this.setState([(this.state.clientesTabla = this.state.Clientes)]);
  };

  render() {
    let valor = this.state.Clientes.map(function (cliente) {
      return cliente;
    });

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <br></br>
          <div className="row">
            <div className="col-6">
              <h2 className="text-center3">Control de pagos</h2>
              <br></br>
            </div>
            <div className="col-6 text-center3"></div>
          </div>

          <CargaExcelPagos />

          <div className="row titulosecundario">
            <div>
              <h2 className="text-center3">Filtros individuales de pagos</h2>
              <br></br>
            </div>
          </div>

          <div className="reportes">
          <div className="">
              <form className="col">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Filtrar ID"
                  onChange={(e) => {
                    this.state.filtroNumeroId = e.target.value;
                  }}
                  onKeyUp={(e) => this.filtrarEmpre()}
                />
              </form>
            </div>
            <div className="">
              <form className="col">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Filtrar número documento"
                  onChange={(e) => {
                    this.state.filtroDocumento = e.target.value;
                  }}
                  onKeyUp={(e) => this.filtrarEmpre()}
                />
              </form>
            </div>
            <select
              id="fempre"
              className=" col-3 imput-select form-control"
              required
              onChange={(e) => {
                this.state.filtroEmpresa = e.target.value;
              }}
              onClick={(e) => this.filtrarEmpre()}
            >
              <option value="">Filtrar por empresa</option>
              <option value="INTEVO">INTEVO</option>
              <option value="BONGO ANALYTICS">BONGO ANALYTICS</option>
              <option value="UNION TEMPORAL">UNIÓN TEMPORAL</option>
              <option value="SMARTEC">SMARTEC</option>
              <option value="MEGAFAST">MEGAFAST</option>
              <option value="PEAR SOLUTIONS">PEAR SOLUTION</option>
              <option value="TERUNGAMES">TERUNGAMES</option>
            </select>
          </div>
          <br></br>

          <div className="row">
            <table
              className="table table-striped table-bordered"
              id="tablaexcel"
            >
              <thead>
                <tr className="titulos-tabla3">
                  <th> Editar</th>
                  <th> ID</th>
                  <th> Nombres</th>
                  <th> Apellidos</th>
                  <th> Número de Documento</th>
                  <th> Empresa</th>
                  <th> Ingresos</th>
                  <th> Correo</th>
                  <th> Celular</th>
                  <th> Estado Prestamo</th>
                  <th> Estado Desembolso</th>
                  <th> Fecha Desembolso</th>
                  <th> Valor Prestamo</th>
                  <th> Valor Cuota</th>
                  <th> Valor Deuda</th>
                  <th> # de Cuotas</th>
                  <th> Cuota 1</th>
                  <th> Cuota 2</th>
                  <th> Cuota 3</th>
                  <th> Fecha de Solicitud</th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientesTabla
                  .filter(
                    (cliente) =>
                      cliente.estado_prestamo === "Aprobado" &&
                      cliente.estado_desembolso === "Desembolsado"
                  )
                  .map((cliente, index) => {
                    return (
                      index > this.state.paginacion &&
                      index <= this.state.paginacion + 4 && (
                        <tr key={cliente.numero_documento}>
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            // <button onClick={ () => this.editUser(cliente.numero_documento)} className="btn btn-primary"><BsPencilSquare/></button>
                            <button
                              onClick={() => {
                                this.editUser(cliente);
                                this.update_id(
                                  cliente._id,
                                  cliente.fecha_desembolso,
                                  cliente.tasa_interes,
                                  cliente.monto_prestamo,
                                  cliente.tarifa_administrativa,
                                  cliente.consulta_perfil,
                                  cliente.monto_cuotas,
                                  cliente.cuota1,
                                  cliente.cuota2,
                                  cliente.cuota3,
                                  cliente.fecha_solicitud,
                                  cliente.cuotas,
                                  cliente.fecha_cuota,
                                  cliente.fechaestimada_cuota2,
                                  cliente.fechaestimada_cuota3,
                                  cliente.saldoc1,
                                  cliente.saldoc2
                                );
                              }}
                              className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#editapagos"
                            >
                              <BsPencilSquare />
                            </button>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}

                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>#{cliente._id}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.nombres}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.apellidos}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.numero_documento}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.empresa}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.ingresos} SMMLV</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.correo}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.celular}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.estado_prestamo}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.estado_desembolso}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.fecha_desembolso}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>
                              {" "}
                              $
                              {new Intl.NumberFormat().format(
                                cliente.monto_prestamo
                              )}
                            </td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>
                              {" "}
                              $
                              {new Intl.NumberFormat().format(
                                cliente.monto_cuotas
                              )}
                            </td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>
                              {" "}
                              $
                              {new Intl.NumberFormat().format(
                                cliente.valor_deuda
                              )}
                            </td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td> {cliente.cuotas}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td> {cliente.cuota1}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.cuota2}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.cuota3}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}
                          {cliente.estado_prestamo === "Aprobado" &&
                          cliente.estado_desembolso === "Desembolsado" ? (
                            <td>{cliente.fecha_solicitud}</td>
                          ) : (
                            <td style={{ display: `none` }}></td>
                          )}

                          {/* <button style={{marginLeft: "10px"}} onClick={ () => this.deleteUser(cliente.numero_documento)} className="btn btn-danger">Delete </button> */}
                          {/* <button style={{marginLeft: "10px"}} onClick={ () => this.viewUser(cliente.numero_documento)} className="btn btn-info">View </button> */}
                        </tr>
                      )
                    );
                  })}
              </tbody>
            </table>
          </div>
          <table
            className="table table-striped table-bordered"
            id="tablaexcel1"
            style={{ display: `none` }}
          >
            <thead>
              <tr className="titulos-tabla3">
                <th> Editar</th>
                <th> ID</th>
                <th> Nombres</th>
                <th> Apellidos</th>
                <th> Número de Documento</th>
                <th> Empresa</th>
                <th> Ingresos</th>
                <th> Correo</th>
                <th> Celular</th>
                <th> Estado Prestamo</th>
                <th> Estado Desembolso</th>
                <th> Valor Prestamo</th>
                <th> Valor Cuota</th>
                <th> Valor Deuda</th>
                <th> # de Cuotas</th>
                <th> Cuota 1</th>
                <th> Cuota 2</th>
                <th> Cuota 3</th>
                <th> Fecha de Solicitud</th>
              </tr>
            </thead>
            <tbody>
              {this.state.Clientes.map((cliente) => (
                <tr key={cliente.numero_documento}>
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    // <button onClick={ () => this.editUser(cliente.numero_documento)} className="btn btn-primary"><BsPencilSquare/></button>
                    <button
                      onClick={() => {
                        this.editUser(cliente);
                        this.update_id(
                          cliente._id,
                          cliente.fecha_desembolso,
                          cliente.tasa_interes,
                          cliente.monto_prestamo,
                          cliente.tarifa_administrativa,
                          cliente.consulta_perfil,
                          cliente.monto_cuotas,
                          cliente.cuota1,
                          cliente.cuota2,
                          cliente.cuota3,
                          cliente.fecha_solicitud,
                          cliente.cuotas,
                          cliente.fecha_cuota,
                          cliente.fechaestimada_cuota2,
                          cliente.fechaestimada_cuota3
                        );
                      }}
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#editapagos"
                    >
                      <BsPencilSquare />
                    </button>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}

                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>#{cliente._id}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.nombres}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.apellidos}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.numero_documento}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.empresa}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.ingresos} SMMLV</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.correo}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.celular}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.estado_desembolso}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.estado_prestamo}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>
                      {" "}
                      ${new Intl.NumberFormat().format(cliente.monto_prestamo)}
                    </td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>
                      {" "}
                      ${new Intl.NumberFormat().format(cliente.monto_cuotas)}
                    </td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>
                      {" "}
                      ${new Intl.NumberFormat().format(cliente.valor_deuda)}
                    </td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td> {cliente.cuotas}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td> {cliente.cuota1}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.cuota2}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.cuota3}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}
                  {cliente.estado_prestamo === "Desembolsado" &&
                  cliente.estado_desembolso === "Aprobado" ? (
                    <td>{cliente.fecha_solicitud}</td>
                  ) : (
                    <td style={{ display: `none` }}></td>
                  )}

                  {/* <button style={{marginLeft: "10px"}} onClick={ () => this.deleteUser(cliente.numero_documento)} className="btn btn-danger">Delete </button> */}
                  {/* <button style={{marginLeft: "10px"}} onClick={ () => this.viewUser(cliente.numero_documento)} className="btn btn-info">View </button> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="row col-12 ">
          <div className="col-1 offset-md-4">
            <button className="Bback" onClick={this.primera}>
              <BsFillSkipBackwardFill />
            </button>
          </div>
          <div className="col-1 ">
            <button className="Bback" onClick={this.antes}>
              <BsFillCaretLeftFill />
            </button>
          </div>
          <div className="col-1">
            <span>{Math.ceil(this.state.paginacion / 4 + 1)}</span> de{" "}
            <span>
              {" "}
              {Math.ceil(
                this.state.clientesTabla.filter(
                  (cliente) =>
                    cliente.estado_prestamo === "Aprobado" &&
                    cliente.estado_desembolso === "Desembolsado"
                ).length / 4
              )}
            </span>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.despues}>
              <BsFillCaretRightFill />
            </button>
          </div>
          <div className="col-1">
            <button className="Bnext" onClick={this.ultima}>
              <BsFillSkipForwardFill />
            </button>
          </div>
        </div>
        <div
          class="modal fade"
          id="editapagos"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
          >
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Actualizar pagos
                </h5>
              </div>
              <div class="modal-body">
                {
                  <form key={this.state.Cliente_edit.numero_documento}>
                    <div className="form-group">
                      <label>ID</label>
                      <input
                        className="form-control"
                        id="modal_editar_id"
                        disabled
                        value={this.state.Cliente_edit._id}
                      />
                      <label>Nombres</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.nombres}
                      />
                      <label>Apellidos</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.apellidos}
                      />
                      <label>Número de Documento</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.numero_documento}
                      />
                      <label>Empresa</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.empresa}
                      />
                      <label>Valor Prestamo</label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          "$" +
                          new Intl.NumberFormat().format(
                            this.state.Cliente_edit.monto_prestamo
                          )
                        }
                      />
                      <label>Número de cuotas</label>
                      <input
                        id="valorc"
                        className="form-control"
                        disabled
                        value={this.state.Cliente_edit.cuotas}
                      />
                      <label>Valor Cuota</label>
                      <input
                        className="form-control"
                        disabled
                        value={
                          "$" +
                          new Intl.NumberFormat().format(
                            this.state.Cliente_edit.monto_cuotas
                          )
                        }
                      />
                      <label>Estado Prestamo</label>
                      <select
                        id="estado_prestamo"
                        className="form-control"
                        placeholder={this.state.Cliente_edit.estado_prestamo}
                      >
                        <option value="Aprobado">Aprobado</option>
                        <option value="Finalizado">Finalizado</option>
                      </select>
                    </div>
                  </form>
                }
                <div className="d-flex">
                  {
                    <form className="d-inline-block mr-3 form-width">
                      <label>Cuota 1</label>

                      <select id="cuota1" className="form-control">
                        {this.state.Cliente_edit.cuota1 === "Pendiente" ? (
                          <option value="Pendiente">Pendiente</option>
                        ) : (
                          <option value="Pagada">Pagada</option>
                        )}
                        {this.state.Cliente_edit.cuota1 === "Pagada" ? (
                          <option value="Pendiente">Pendiente</option>
                        ) : (
                          <option value="Pagada">Pagada</option>
                        )}
                      </select>

                      {this.state.Cliente_edit.cuotas === 1 ? (
                        <label style={{ display: `none` }}>Cuota 2</label>
                      ) : (
                        <label>Cuota 2</label>
                      )}

                      {this.state.Cliente_edit.cuotas === 1 ? (
                        <select
                          id="cuota2"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota2}
                          style={{ display: `none` }}
                        >
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      ) : (
                        <select
                          id="cuota2"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota2}
                        >
                          {this.state.Cliente_edit.cuota2 === "Pendiente" ? (
                            <option value="Pendiente">Pendiente</option>
                          ) : (
                            <option value="Pagada">Pagada</option>
                          )}
                          {this.state.Cliente_edit.cuota2 === "Pagada" ? (
                            <option value="Pendiente">Pendiente</option>
                          ) : (
                            <option value="Pagada">Pagada</option>
                          )}
                        </select>
                      )}

                      {this.state.Cliente_edit.cuotas !== 3 ? (
                        <label style={{ display: `none` }}>Cuota 3</label>
                      ) : (
                        <label>Cuota 3</label>
                      )}

                      {this.state.Cliente_edit.cuotas !== 3 ? (
                        <select
                          id="cuota3"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota3}
                          style={{ display: `none` }}
                        >
                          <option value="No Aplica">No Aplica</option>
                        </select>
                      ) : (
                        <select
                          id="cuota3"
                          className="form-control"
                          placeholder={this.state.Cliente_edit.cuota3}
                        >
                          {this.state.Cliente_edit.cuota3 === "Pendiente" ? (
                            <option value="Pendiente">Pendiente</option>
                          ) : (
                            <option value="Pagada">Pagada</option>
                          )}
                          {this.state.Cliente_edit.cuota3 === "Pagada" ? (
                            <option value="Pendiente">Pendiente</option>
                          ) : (
                            <option value="Pagada">Pagada</option>
                          )}
                        </select>
                      )}
                    </form>
                  }

                  {
                    <form className="d-inline-block mr-3 form-width">
                      <label>Fecha Cuota 1</label>
                      <input
                        className="form-control"
                        disabled
                        value={this.imprimirFechaFormateada(
                          this.state.Cliente_edit.fecha_cuota
                        )}
                      />

                      {this.state.Cliente_edit.cuotas === 1 ? (
                        <label style={{ display: `none` }}>Fecha Cuota 2</label>
                      ) : (
                        <label>Fecha Cuota 2</label>
                      )}

                      {this.state.Cliente_edit.cuotas === 1 ? (
                        <input
                          className="form-control"
                          disabled
                          value={this.imprimirFechaFormateada(
                            this.state.Cliente_edit.fechaestimada_cuota2
                          )}
                          style={{ display: `none` }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          disabled
                          value={this.imprimirFechaFormateada(
                            this.state.Cliente_edit.fechaestimada_cuota2
                          )}
                        />
                      )}

                      {this.state.Cliente_edit.cuotas !== 3 ? (
                        <label style={{ display: `none` }}>Fecha Cuota 3</label>
                      ) : (
                        <label>Fecha Cuota 3</label>
                      )}

                      {this.state.Cliente_edit.cuotas !== 3 ? (
                        <input
                          className="form-control"
                          disabled
                          value={this.imprimirFechaFormateada(
                            this.state.Cliente_edit.fechaestimada_cuota3
                          )}
                          style={{ display: `none` }}
                        />
                      ) : (
                        <input
                          className="form-control"
                          disabled
                          value={this.imprimirFechaFormateada(
                            this.state.Cliente_edit.fechaestimada_cuota3
                          )}
                        />
                      )}
                    </form>
                  }
                </div>
              </div>

              <div class="modal-footer" style={{ flexWrap: "nowrap" }}>
                {/* <button className="btn btn-success" onClick={(e)=>{this.updateUser(e);this.validacionS(e)}}>Guardar</button> */}
                <button
                  className="btn btn-danger"
                  data-toggle="modal"
                  data-dismiss="modal"
                  data-target="#confirmationcontrolLiquidacion"
                >
                  Liquidar
                </button>
                <button
                  className="btn btn-success"
                  data-toggle="modal"
                  data-dismiss="modal"
                  style={{ marginLeft: "64%" }}
                  data-target="#confirmationcontrol"
                >
                  Guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="confirmationcontrol"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Confirmar cambios
                </h5>
              </div>
              <div class="modal-body" style={{ marginLeft: "50px" }}>
                ¿Estas seguro de confirmar cambios realizados?
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    this.updateUser(e);
                    this.validacionS(e);
                  }}
                >
                  Sí, guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#editapagos"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          class="modal fade"
          id="confirmationcontrolLiquidacion"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title1" id="exampleModalLabel">
                  Confirmar cambios
                </h5>
              </div>
              <div class="modal-body" style={{ marginLeft: "50px" }}>
                ¿Estas seguro de confirmar cambios realizados?
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    this.actualizarLiquidacion(e);
                  }}
                >
                  Sí, guardar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#editapagos"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* MODAL FILTRO */}
        <div
          class="modal fade"
          id="filtro"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content modalf">
              <div class="modal-header">
                <h5 class="modal-titlef" id="exampleModalLabel">
                  Filtrar
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <h5 class="modal-titlet">Buscar:</h5>
                <div className="input-group mb-3">
                  <input
                    id="doc"
                    type="number"
                    className="form-control imput-select1"
                    placeholder="Numero de documento"
                    required
                    onKeyUp={(e) => {
                      this.state.filtroDocumento = e.target.value;
                    }}
                  />
                </div>
                {/* <div className="input-group mb-3">
                  <input id='nom' type="text" 
                    className="form-control imput-select1" 
                    placeholder='Nombre' required
                    onKeyUp={e => {this.state.filtroNombre = e.target.value;}}
                    />
                </div>  */}
              </div>
              <div class="modal-footer">
                <button
                  className="btn btn-success"
                  data-dismiss="modal"
                  onClick={(e) => this.filtrar()}
                >
                  Buscar
                </button>
                <button
                  className="btn btn-danger"
                  data-dismiss="modal"
                  style={{ marginLeft: "10px" }}
                  onClick={(e) => this.cancela()}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ControlPagos;
