import React, { Component } from 'react'
import UserService from '../../services/UserService';
import '../../css/Update.css'
import Header from './Header'
import Footer from './Footer'
import Menu from './Menu'
import { Link } from "react-router-dom";


class UpdateUserComponent extends Component {
    constructor(props) {
        super(props)
        this.dataLocal = JSON.parse(localStorage.getItem('documento'))
        this.state = {
            numero_documento:this.dataLocal,
            estado_prestamo:'',
            Cliente:[]
            

        }
       
        this.changeFirstNameHandler = this.changeFirstNameHandler.bind(this);
        this.changeLastNameHandler = this.changeLastNameHandler.bind(this);
        this.updateUser = this.updateUser.bind(this);


    }

    

    componentDidMount(){
        UserService.getUserById(this.state.numero_documento).then( (res) =>{
            this.setState(res.data);
        });
    }

    updateUser = (e) => {
        e.preventDefault();
        let concat =document.getElementById("estado_prestamo").value;
        console.log(concat)
        let user = {estado_prestamo:concat};
        console.log('user => ' + JSON.stringify(user));
        console.log('_id => ' + JSON.stringify(this.state.numero_documento));
        UserService.updateUser(user, this.state.numero_documento).then( res => {
            window.location='/PendienteDesembolso';
        });
    }
    
    changeFirstNameHandler= (e) => {
        this.setState({estado_prestamo: e.target.value});
    }

    changeLastNameHandler= (e) => {
        this.setState({apellidos: e.target.apellidos});
    }

    changeEmailHandler= (e) => {
        this.setState({empresa: e.target.empresa});
    }

   
    render() {
        return (
            <div>
            <Header/>
                <Menu/>
                <br></br>
                <div className="content-wrapper">
                        <div className = "row">
                            <div className = "card col-md-6 offset-md-3 offset-md-3">
                                <h3 className="text-center titulo-centro">Actualizar Estado desembolso</h3>
                                <div className = "card-body">
                                {
                                        this.state.Cliente.map(
                                        cliente =>
                                    <form key = {cliente.numero_documento}>
                                    <div className = "form-group" >
                                    <label>ID</label>
                                    <input className='form-control' disabled value={cliente._id}/>
                                    <label>Nombres</label>
                                    <input className='form-control' disabled value={cliente.nombres}/>
                                    <label>Apellidos</label>
                                    <input className='form-control' disabled value={cliente.apellidos}/>
                                    <label>Número de Documento</label>
                                    <input className='form-control' disabled value={cliente.numero_documento}/>
                                    <label>Empresa</label>
                                    <input className='form-control' disabled value={cliente.empresa}/>
                                    <label>Billetera</label>
                                    <input className='form-control' disabled value={cliente.metodo_desembolso}/>
                                    <label>Celular</label>
                                    <input className='form-control' disabled value={cliente.celular}/>
                                    <label>Valor Prestamo</label>
                                    <input className='form-control' disabled value={"$"+new Intl.NumberFormat().format(cliente.monto_prestamo)}/>
                                    <label>Estado Desembolso</label>
                                        <select id="estado_prestamo" className="form-control" placeholder={cliente.estado_prestamo}>
                                            <option value="Desembolsado">Desembolsado</option>
                                            <option value="Pendiente">Pendiente</option>
                                        </select>
                                        </div>
                                        
                                        <button className="btn btn-success" onClick={this.updateUser}>Guardar</button>
                                        <Link to="/PendienteDesembolso"><button className="btn btn-danger"  style={{marginLeft: "10px"}}>Cancelar</button></Link>
                                      
                                    </form>
                                    )
                                    }
                                </div>
                            </div>
                        </div>

                   </div>
            </div>
        )
    }
}

export default UpdateUserComponent
