import { useEffect, useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import UserService from "../../services/UserService";
export default function CargaExcelPagos() {
  const [isDisabled, setIsDisabled] = useState(true);
  const [fecha, setFecha] = useState("");
  const [empleados, setEmpleados] = useState([]);

  const meses = [
    "ene",
    "feb",
    "mar",
    "abr",
    "may",
    "jun",
    "jul",
    "ago",
    "sep",
    "oct",
    "nov",
    "dic",
  ];

  const getMesAnioFormato = (fecha) => {
    const [, mes, anio] = fecha.split("/");
    const nombreMes = meses[parseInt(mes, 10) - 1];
    return `${nombreMes}-${anio.slice(-2)}`;
  };

  const validacionFecha = (e) => {
    let fecha = e.target.value;
    if (fecha !== "") {
      const inputFecha = e.target.value;

      const year = inputFecha.slice(2, 4);
      const month = inputFecha.slice(5, 7);
      const meses = [
        "ene",
        "feb",
        "mar",
        "abr",
        "may",
        "jun",
        "jul",
        "ago",
        "sep",
        "oct",
        "nov",
        "dic",
      ];
      const monthName = meses[parseInt(month, 10) - 1];

      const fechaFormateada = `${monthName}-${year}`;
      // console.log(fechaFormateada);
      setFecha(fechaFormateada);
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  useEffect(() => {
    UserService.getUsers().then((res) => {
      setEmpleados(res.data.Clientes);
    });
  }, []);

  const readReportePagos = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    let datosExcel = [];
    let creditosActualizados = [];
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const firstSheet = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheet];
      const json = XLSX.utils.sheet_to_json(worksheet);
      datosExcel.push(json);
      // console.log(json);
      let idsExcel = datosExcel[0].map((item) => ({
        id: item["ID PRESTAMO"],
        valorCuota: item[fecha],
      }));
      let filtro = empleados.filter((item) =>
        idsExcel.map((obj) => obj.id).includes(item._id)
      );
      // console.log(filtro);

      filtro.forEach((itemFiltro) => {
        idsExcel.forEach((itemExcel) => {
          if (
            itemFiltro._id === itemExcel.id &&
            itemFiltro.monto_cuotas === itemExcel.valorCuota
          ) {
            // console.log(`Coincide para el elemento con _id: ${itemFiltro._id}`);
            // console.log(itemExcel.valorCuota);
            const cuotas = [
              "fecha_cuota",
              "fechaestimada_cuota2",
              "fechaestimada_cuota3",
            ];

            cuotas.forEach((nombreCuota, index) => {
              const fechaCuota =
                itemFiltro[nombreCuota] !== "No Aplica"
                  ? getMesAnioFormato(itemFiltro[nombreCuota])
                  : "No Aplica";
              if (fechaCuota === fecha) {
                // console.log(
                //   `Se va a actualizar el estado de la cuota ${index + 1}`
                // );
                let user = {
                  cuota1: itemFiltro.cuota1,
                  cuota2: itemFiltro.cuota2,
                  cuota3: itemFiltro.cuota3,
                  fecha_cuota1: itemFiltro.fecha_cuota1,
                  fecha_cuota2: itemFiltro.fecha_cuota2,
                  fecha_cuota3: itemFiltro.fecha_cuota3,
                  fecha_finalizado: itemFiltro.fecha_finalizado,
                  estado_prestamo: itemFiltro.estado_prestamo,
                };

                let date = new Date();
                let fecha_opc =
                  String(date.getDate()).padStart(2, "0") +
                  "/" +
                  String(date.getMonth() + 1).padStart(2, "0") +
                  "/" +
                  date.getFullYear() +
                  " " +
                  date.getHours() +
                  ":" +
                  date.getMinutes() +
                  ":" +
                  date.getSeconds();

                const nameCuota = `cuota${index + 1}`;
                const nameFechCuota = `fecha_cuota${index + 1}`;
                const numCuotas = itemFiltro.cuotas;
                // console.log(index + 1);

                if (numCuotas === index + 1) {
                  user[nameCuota] = "Pagada";
                  user[nameFechCuota] = fecha_opc;
                  user.fecha_finalizado = fecha_opc;
                  user.estado_prestamo = "Finalizado";
                } else {
                  user[nameCuota] = "Pagada";
                  user[nameFechCuota] = fecha_opc;
                }

                // console.log(user);
                creditosActualizados.push(itemFiltro._id);
                UserService.updateUserId(user, itemFiltro._id).then((res) => {
                });
              }
            });
          }
        });
      });

      if (creditosActualizados.length === 0) {
        document.getElementById("errorCargaExcelPagos").style.display = "block";
      } else {
        document.getElementById("CargaExcelPagos").style.display = "block";
      }
    };
    reader.readAsBinaryString(file);
  };

  const reload = () => {
    window.location.reload();
  };

  let buttonClass = "custom-file-button-pagos-disabled";
  !isDisabled
    ? (buttonClass = "custom-file-button-pagos ml-3")
    : (buttonClass = "custom-file-button-pagos-disabled ml-3");
  return (
    <div>
      <div className="row titulosecundario">
        <div>
          <h2 className="text-center3">Subir reporte de pagos masivos</h2>
          <br></br>
        </div>
      </div>

      <div className="reportes">
        <div className="filter_row_fechas_pagos">
          <label>
            Selecciona la fecha
          </label>
          <input
            className="form-control w-50"
            type="month"
            placeholder="Fecha inicio"
            id="fecha_reporte"
            onChange={(e) => {
              validacionFecha(e);
            }}
          />
        </div>

        <input
          type="file"
          id="myFileInputPagos"
          onChange={(e) => readReportePagos(e)}
          accept=".xlsx"
          className="custom-file-input-pagos"
          disabled={isDisabled}
        ></input>
        <label for="myFileInputPagos" className={buttonClass}>
          Seleccionar archivo
        </label>
      </div>

      <div
        className="back-modal"
        id="errorCargaExcelPagos"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content curva">
            <div className="modal-body">
              <h4 className="h5S">
                <b>
                  No se han actualizado ninguno de los préstamos. Por favor,
                  verifica que la información esté correcta y que la plantilla
                  sea la adecuada.
                </b>
              </h4>
              <button
                type="button"
                onClick={reload}
                className="btn-enunciado-paga9"
                data-bs-dismiss="modal"
                data-toggle="modal"
              >
                {" "}
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="back-modal"
        id="CargaExcelPagos"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content curva">
            <div className="modal-body">
              <h4 className="h5S">
                <b>Los préstamos se han actualizado de manera exitosa.</b>
              </h4>
              <button
                type="button"
                onClick={reload}
                className="btn-enunciado-paga9"
                data-bs-dismiss="modal"
                data-toggle="modal"
              >
                {" "}
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
